import React from 'react';

type PropsType = {
  size?: string;
};

export const MapLocation: React.FC<PropsType> = ({ size = '20' }) => {
  return (
    <svg width={size} height={size} viewBox={'0 0 20 21'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M3.33301 9.17719C3.33301 5.42934 6.31778 2.39111 9.99967 2.39111C13.6816 2.39111 16.6663 5.42934 16.6663 9.17719C16.6663 12.8957 14.5386 17.2348 11.2188 18.7865C10.4449 19.1482 9.55446 19.1482 8.78056 18.7865C5.46078 17.2348 3.33301 12.8957 3.33301 9.17719Z'
        }
        stroke={'#707893'}
        strokeWidth={'1.25'}
      />
      <circle cx={'10'} cy={'9.05811'} r={'2.5'} stroke={'#707893'} strokeWidth={'1.25'} />
    </svg>
  );
};
