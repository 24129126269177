import React from 'react';

type PropsType = {
  size?: string;
};

export const EmailIcon: React.FC<PropsType> = ({ size = '20' }) => {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} width={size} height={size} viewBox={'0 0 20 20'} fill={'none'}>
      <path
        d={
          'M1.66602 10.0002C1.66602 6.85747 1.66602 5.28612 2.64233 4.30981C3.61864 3.3335 5.18999 3.3335 8.33268 3.3335H11.666C14.8087 3.3335 16.3801 3.3335 17.3564 4.30981C18.3327 5.28612 18.3327 6.85747 18.3327 10.0002C18.3327 13.1429 18.3327 14.7142 17.3564 15.6905C16.3801 16.6668 14.8087 16.6668 11.666 16.6668H8.33268C5.18999 16.6668 3.61864 16.6668 2.64233 15.6905C1.66602 14.7142 1.66602 13.1429 1.66602 10.0002Z'
        }
        stroke={'url(#paint0_linear_3717_23141)'}
        strokeWidth={'1.25'}
      />
      <path
        d={
          'M5 6.6665L6.79908 8.16574C8.32961 9.44118 9.09488 10.0789 10 10.0789C10.9051 10.0789 11.6704 9.44118 13.2009 8.16574L15 6.6665'
        }
        stroke={'url(#paint1_linear_3717_23141)'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
      />
      <defs>
        <linearGradient
          id={'paint0_linear_3717_23141'}
          x1={'1.66602'}
          y1={'10.0002'}
          x2={'18.3327'}
          y2={'10.0002'}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop stopColor={'#30D664'} />
          <stop offset={'1'} stopColor={'#2EC4CE'} />
        </linearGradient>
        <linearGradient
          id={'paint1_linear_3717_23141'}
          x1={'5'}
          y1={'8.3727'}
          x2={'15'}
          y2={'8.3727'}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop stopColor={'#30D664'} />
          <stop offset={'1'} stopColor={'#2EC4CE'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
