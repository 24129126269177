import React from 'react';
import { Grid, Skeleton } from '@mui/material';

export const TicketItemSkeleton = () => {
  return (
    <Grid item xs>
      <Skeleton variant={'rounded'} height={120} width={'100%'} />
    </Grid>
  );
};
