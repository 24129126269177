export enum StripePaymentStatus {
  Canceled = 'canceled',
  Requires_action = 'requires_action',
  Requires_confirmation = 'requires_confirmation',
  Succeeded = 'succeeded',
  Processing = 'processing',
  Requires_payment_method = 'requires_payment_method',
}

export enum StripePaymentParams {
  RedirectStatus = 'redirect_status',
}
