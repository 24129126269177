import { DEFAULT_DISCOUNT_COUNT, DEFAULT_TICKET_COUNT } from '../constants/constants.ts';
import { OrderTicketGroupType, OrderTicketsType, OrderTicketType } from '../types/commonTypes.ts';

export const useCalculateTickets = (orderTickets: OrderTicketType[], orderTicketGroups: OrderTicketGroupType[]) => {
  const concatTickets: OrderTicketsType[] = ([...orderTicketGroups, ...orderTickets] as OrderTicketsType[]).map(
    (ticket) => {
      return {
        price: ticket.price,
        count: ticket?.count ?? DEFAULT_TICKET_COUNT,
        coins: ticket.coins ?? 0,
        id: ticket?.ticketGroupId ? ticket?.ticketGroupId : ticket.id,
        uId: ticket.uId,
        sector: ticket.sector,
        title: ticket.title,
        discount: ticket.discount ?? DEFAULT_DISCOUNT_COUNT,
        row: ticket?.row ? ticket?.row : null,
        seat: ticket?.seat ? ticket?.seat : null,
      };
    }
  );

  const totalCostWithoutFee = concatTickets.reduce(
    (acc, current) => acc + (current.price - current.discount * current.price) * current.count,
    0
  );
  const totalCoins = concatTickets.reduce(
    (acc, current) => (current?.coins ? acc + current.coins * current.count : 0),
    0
  );
  const totalDiscount = concatTickets.reduce((acc, current) => acc + current.discount * current.price, 0);

  return {
    totalCoins,
    tickets: concatTickets,
    totalDiscount,
    totalCostWithoutFee,
  };
};
