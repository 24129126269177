export enum TicketStatus {
  Free = 1,
  Reserved = 2,
  Booked = 4,
  Paid = 8,
  Returned = 16,
  Blocked = 32,
}

export enum PaymentProvider {
  None = 0,
  Stripe = 1,
  Revolut = 2,
  Free = 5,
}

export enum SeatClasses {
  RESERVED = 'isReserved',
  AVAILABLE = 'isAvailable',
  RESERVED_NOW = 'isReserveRightNow',
}

export enum Steps {
  TicketSelection = 0,
  Buyer = 1,
  Payment = 2,
}
