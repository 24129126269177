import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { AppSettings } from './settings/appSettings';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'ru',
      preload: ['ru'],
      ns: ['translation'],
      defaultNS: 'translation',
      supportedLngs: ['ru'],
      debug: Boolean(AppSettings.IS_DEV),
      backend: {
        loadPath: `${import.meta.env.VITE_API_URL_V1}/base/translations/{{lng}}/{{ns}}.json`,
      },
      react: {
        useSuspense: true,
      },
    },
    (err) => {
      if (err && AppSettings.IS_DEV) {
        return console.error(err);
      }
    }
  );

export default i18n;
