import React from 'react';

type PropsType = {
  size?: string;
};

export const BitcoinIcon: React.FC<PropsType> = ({ size = '20' }) => {
  return (
    <svg width={size} height={size} viewBox={'0 0 20 20'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M20 9.99979C20 15.5232 15.5229 20 10 20C4.47713 20 0 15.5232 0 9.99979C0 4.47701 4.47713 0.000167447 10 0.000167447C15.5229 0.000167447 20 4.47701 20 9.99979Z'
        }
        fill={'#FEE475'}
      />
      <path
        d={
          'M19.7447 9.99967C19.7447 15.3817 15.382 19.7441 10.0003 19.7441C4.61857 19.7441 0.255859 15.3817 0.255859 9.99967C0.255859 4.61826 4.61857 0.255211 10.0003 0.255211C15.382 0.255211 19.7447 4.61826 19.7447 9.99967Z'
        }
        fill={'#FED425'}
      />
      <path
        d={
          'M6.3893 19.0508C5.15952 18.56 4.05226 17.8278 3.12891 16.9095L5.03435 15.0044C5.93587 15.9676 7.10462 16.6785 8.42198 17.0181L6.3893 19.0508ZM17.0283 8.4121C16.6884 7.09434 15.9776 5.92599 15.0142 5.0243L16.8952 3.14333C17.0603 3.3008 17.22 3.46343 17.3741 3.63182C18.0762 4.44501 18.6452 5.37601 19.0501 6.39034L17.0283 8.4121Z'
        }
        fill={'#FEE960'}
      />
      <path d={'M12.1689 19.498L19.4986 12.1684C18.6708 15.8074 15.8079 18.6705 12.1689 19.498Z'} fill={'#FEE960'} />
      <path
        d={
          'M0.570312 7.53516C0.784442 6.71392 1.10443 5.93579 1.51229 5.21398L5.01262 1.71354C5.74615 1.29056 6.53865 0.957816 7.3746 0.730813L0.570312 7.53516Z'
        }
        fill={'#FEE960'}
      />
      <path
        d={
          'M2.54691 16.2754C2.37083 16.0662 2.20417 15.8495 2.0459 15.626L4.0337 13.6381C4.17024 13.8812 4.32058 14.1146 4.48385 14.3387L2.54691 16.2754ZM14.3488 4.47353C14.125 4.31032 13.8913 4.15975 13.6487 4.02355L15.5841 2.08799C15.8116 2.24144 16.0328 2.40465 16.2465 2.57591L14.3488 4.47353Z'
        }
        fill={'#FEE960'}
      />
      <path
        d={
          'M10.1921 17.2422C9.5812 17.2422 8.98857 17.1646 8.42319 17.0186L17.0295 8.41264C17.1754 8.97813 17.2529 9.57063 17.2529 10.1815C17.2529 14.0808 14.0917 17.2422 10.1921 17.2422ZM5.03556 15.0049C4.83867 14.7946 4.65454 14.5716 4.48438 14.3388L14.3493 4.4737C14.5825 4.64381 14.805 4.82829 15.0154 5.02483L5.03556 15.0049Z'
        }
        fill={'#FEE960'}
      />
      <path
        d={
          'M8.42279 17.0176C7.10542 16.6779 5.93667 15.967 5.03516 15.0039L15.015 5.02377C15.9784 5.92546 16.6892 7.09381 17.0291 8.41158L8.42279 17.0176Z'
        }
        fill={'#FEF49C'}
      />
      <path
        d={
          'M4.03358 13.6367C3.45872 12.6149 3.13086 11.4356 3.13086 10.1799C3.13086 6.28009 6.29201 3.11929 10.1915 3.11929C11.4475 3.11929 12.6268 3.44686 13.6486 4.02213L4.03358 13.6367Z'
        }
        fill={'#FEE960'}
      />
      <path
        d={
          'M4.48336 14.3379C4.32009 14.1138 4.16975 13.8804 4.0332 13.6373L13.6482 4.02276C13.8908 4.15896 14.1245 4.30953 14.3483 4.47274L4.48336 14.3379Z'
        }
        fill={'#FEF49C'}
      />
      <path
        d={
          'M17.0609 9.99931C17.0609 13.8992 13.8997 17.0605 10.0002 17.0605C6.10066 17.0605 2.93945 13.8992 2.93945 9.99931C2.93945 6.10003 6.10066 2.93866 10.0002 2.93866C13.8997 2.93866 17.0609 6.10003 17.0609 9.99931Z'
        }
        fill={'#FEB724'}
      />
      <path
        d={
          'M3.32283 10.3827C3.32283 6.48347 6.48403 3.32209 10.3835 3.32209C12.2358 3.32209 13.9207 4.03643 15.1801 5.20305C13.8904 3.81115 12.0475 2.93877 10.0002 2.93877C6.10066 2.93877 2.93945 6.10014 2.93945 9.99942C2.93945 12.047 3.81172 13.8901 5.20385 15.1797C4.037 13.92 3.32283 12.2355 3.32283 10.3827Z'
        }
        fill={'#FB9D12'}
      />
      <path
        d={
          'M19.9952 10.1914C19.9477 7.67426 18.9708 5.38469 17.3933 3.65142C15.6062 1.57219 12.9569 0.254997 10 0.254997C7.04313 0.254997 4.39357 1.57276 2.60651 3.65199C1.02904 5.38469 0.052411 7.67426 0.00488403 10.1914C0.00361971 10.1276 0 10.0638 0 9.99946C0 4.47668 4.47713 -0.000168459 10 -0.000168459C10.1912 -0.000168459 10.3809 0.0050105 10.5694 0.0153549C10.6043 0.0176537 10.6393 0.019948 10.6741 0.0222467C13.1544 0.187183 15.3874 1.25669 17.0461 2.90375C17.0545 2.9118 17.0627 2.92042 17.0711 2.92847C17.0852 2.94283 17.0993 2.95662 17.1133 2.97098H17.1134C17.1188 2.97673 17.1244 2.98248 17.1299 2.98765C17.1349 2.99283 17.1399 2.998 17.1449 3.00318C17.1544 3.01295 17.1639 3.02271 17.1733 3.03248C18.7766 4.68242 19.8152 6.88406 19.9776 9.32535C19.9803 9.36615 19.9828 9.40638 19.985 9.44718C19.9866 9.47534 19.988 9.50465 19.9893 9.53338C19.9929 9.61154 19.9956 9.69085 19.9974 9.76958C19.9991 9.84601 20 9.92302 20 9.99946C20 10.0638 19.9964 10.1276 19.9952 10.1914Z'
        }
        fill={'#FEF1AD'}
      />
      <path
        d={
          'M2.60645 3.65234C4.3935 1.57311 7.04306 0.255347 9.99996 0.255347C12.9569 0.255347 15.6061 1.57254 17.3932 3.65177C17.387 3.64545 17.3811 3.63855 17.3748 3.63166C17.2256 3.45925 17.0703 3.29145 16.9094 3.12996L16.8959 3.14317C16.6879 2.9449 16.471 2.75526 16.2465 2.57538L16.2747 2.54722C16.0658 2.37079 15.8489 2.20412 15.6256 2.04608L15.5841 2.08747C13.9898 1.01164 12.0686 0.383507 9.99996 0.383507C9.09143 0.383507 8.21129 0.504765 7.37477 0.731768L7.5358 0.570854C6.71457 0.78464 5.93655 1.10474 5.21462 1.51278L5.01279 1.71449C4.11283 2.23344 3.30136 2.88915 2.60645 3.65234Z'
        }
        fill={'#FEE960'}
      />
      <path
        d={
          'M17.3744 3.63086C17.2203 3.46247 17.0606 3.29984 16.8955 3.14237L16.909 3.12916C17.0699 3.29065 17.2252 3.45845 17.3744 3.63086Z'
        }
        fill={'#FEF49C'}
      />
      <path
        d={
          'M5.0127 1.71484L5.21453 1.51313C5.93645 1.1051 6.71447 0.784993 7.5357 0.571208L7.37468 0.732122C6.53873 0.959125 5.74623 1.29187 5.0127 1.71484Z'
        }
        fill={'#FEF49C'}
      />
      <path
        d={
          'M16.2464 2.57422C16.0327 2.40296 15.8114 2.23975 15.584 2.0863L15.6255 2.04492C15.8488 2.20296 16.0657 2.36963 16.2746 2.54606L16.2464 2.57422Z'
        }
        fill={'#FEF49C'}
      />
      <path
        d={
          'M6.99512 14.1361V5.49609H10.4211C11.0051 5.49609 11.4831 5.61409 11.8551 5.85009C12.2311 6.08209 12.5091 6.38009 12.6891 6.74409C12.8731 7.10809 12.9651 7.48409 12.9651 7.87209C12.9651 8.34809 12.8511 8.75209 12.6231 9.08409C12.3991 9.41609 12.0931 9.64009 11.7051 9.75609V9.45609C12.2491 9.58009 12.6611 9.83809 12.9411 10.2301C13.2251 10.6221 13.3671 11.0681 13.3671 11.5681C13.3671 12.0801 13.2691 12.5281 13.0731 12.9121C12.8771 13.2961 12.5851 13.5961 12.1971 13.8121C11.8131 14.0281 11.3371 14.1361 10.7691 14.1361H6.99512ZM8.45912 12.7741H10.5891C10.8411 12.7741 11.0671 12.7241 11.2671 12.6241C11.4671 12.5201 11.6231 12.3761 11.7351 12.1921C11.8511 12.0041 11.9091 11.7821 11.9091 11.5261C11.9091 11.2941 11.8591 11.0861 11.7591 10.9021C11.6591 10.7181 11.5131 10.5721 11.3211 10.4641C11.1291 10.3521 10.8991 10.2961 10.6311 10.2961H8.45912V12.7741ZM8.45912 8.94609H10.4031C10.6111 8.94609 10.7971 8.90609 10.9611 8.82609C11.1251 8.74609 11.2551 8.62809 11.3511 8.47209C11.4471 8.31609 11.4951 8.12409 11.4951 7.89609C11.4951 7.59609 11.3991 7.34609 11.2071 7.14609C11.0151 6.94609 10.7471 6.84609 10.4031 6.84609H8.45912V8.94609Z'
        }
        fill={'#FEE960'}
      />
      <path d={'M10 4.5V15'} stroke={'#FEE960'} />
    </svg>
  );
};
