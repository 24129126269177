import { Grid, IconButton } from '@mui/material';
import { TextWithGradient } from '@components/common/TextWithGradient';
import { theme } from '@core/theme/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'CounterForTicketsGroup' })((theme) => ({
  counterWrapper: {
    borderRadius: '10px',
    background: theme.palette.bg.dark,
    overflow: 'hidden',
    maxHeight: '40px',
  },
  counter: {
    background: '#1C274C',
    padding: '9px 10px',
    minWidth: '30px',
    textAlign: 'center',
  },
  counterControll: {
    padding: '0 10px',
    position: 'relative',
    transition: '0.3s ease',
    ':hover': {
      background: 'gray',
    },
    ':after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '2px',
      background: theme.palette.common.white,
    },
  },
  increment: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(90deg)',
      width: '50%',
      height: '2px',
      background: theme.palette.common.white,
    },
  },
}));

type CounterForTicketsGroupType = {
  counterValue: number;
  handleChangeTicketCount: (behavior: string) => void;
  disabledIncrement: boolean;
};

const TicketBehavior = {
  INCREMENT: 'INCREMENT',
  DICREMENT: 'DICREMENT',
} as const;

export const CounterForTicketsGroup = ({
  counterValue,
  handleChangeTicketCount,
  disabledIncrement,
}: CounterForTicketsGroupType) => {
  const { classes } = useStyles();
  return (
    <Grid item xs={'auto'} className={classes.counterWrapper} container direction={'row'}>
      <IconButton
        sx={{ borderRadius: 0, opacity: !counterValue ? 0.5 : 1 }}
        disabled={!counterValue}
        onClick={() => handleChangeTicketCount(TicketBehavior.DICREMENT)}
      >
        <Grid item className={classes.counterControll} />
      </IconButton>
      <Grid item className={classes.counter} paddingX={2.5}>
        <TextWithGradient fontSize={'18px'} fontWeight={600} gradientColor={theme.palette.common.white}>
          {counterValue}
        </TextWithGradient>
      </Grid>
      <IconButton
        sx={{ borderRadius: 0, opacity: disabledIncrement ? 0.5 : 1 }}
        onClick={() => handleChangeTicketCount(TicketBehavior.INCREMENT)}
        disabled={disabledIncrement}
      >
        <Grid item className={`${classes.counterControll} ${classes.increment}`} />
      </IconButton>
    </Grid>
  );
};
