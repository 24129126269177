export enum EventType {
  None = 0,
  Stadium = 1,
  Concert_Hall = 2,
  Conference_Hall = 3,
  Auditorium = 4,
  Cinema = 5,
  Theatre = 6,
  Club = 7,
  Museum = 8,
  Circu = 9,
  Zoo = 10,
  Restaurant = 11,
  Cafe = 12,
}

export const EventName: Record<EventType, string> = {
  [EventType.None]: 'Stadium',
  [EventType.Stadium]: 'Stadium',
  [EventType.Concert_Hall]: 'Concert hall',
  [EventType.Conference_Hall]: 'Conference hall',
  [EventType.Auditorium]: 'Auditorium',
  [EventType.Cinema]: 'Cinema',
  [EventType.Theatre]: 'Theatre',
  [EventType.Club]: 'Club',
  [EventType.Museum]: 'Museum',
  [EventType.Circu]: 'Circu',
  [EventType.Zoo]: 'Zoo',
  [EventType.Restaurant]: 'Restaurant',
  [EventType.Cafe]: 'Cafe',
};

export enum EventStatus {
  None = 0,
  Planned = 1,
  InProgress = 2,
  Completed = 3,
  Canceled = 4,
  Soldout = 5,
}

export enum PriceTypeEnum {
  None = 0,
  From = 1,
  Fix = 2,
  Free = 5,
}

export enum DashboardCardType {
  None = 0,
  Event = 1,
  Tour = 2,
  Info = 3,
}
