import { Grid, Skeleton, useTheme } from '@mui/material';
import React from 'react';

export const SkeletonCommissions = () => {
  const { palette } = useTheme();
  return (
    <Grid item container flexDirection={'column'} wrap={'nowrap'} gap={'15px'}>
      {Array.from(new Array(2)).map((_, index) => (
        <Grid key={index} item container justifyContent={'space-between'}>
          <Skeleton width={200} height={19.2} sx={{ backgroundColor: palette.bgSecond.light }} />
          <Skeleton width={90} height={19.2} sx={{ backgroundColor: palette.bgSecond.light }} />
        </Grid>
      ))}
    </Grid>
  );
};
