import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { authorize, deAuthorize } from '@store/auth/actions.ts';
import { AuthStateType } from '@store/auth/types.ts';
import { GlobalStateType } from 'src/configureStore.ts';
import { isAfter, isValid, parseISO } from 'date-fns';
import { clearUser } from '@store/user/actions.ts';
import { AppDeAuthorize } from '@store/app/actions.ts';
import { Auth } from '../constants/constants';

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL_V1,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as GlobalStateType).auth;
    headers.set('Authorization', `Bearer ${accessToken}`);
    return headers;
  },
});
// ToDo Use after the endpoint is ready
const isExpiredToken = (expiredDate: string | null) => {
  if (!expiredDate) return false;
  const parseToDate = parseISO(expiredDate);
  const isValidExpiredDate = isValid(parseToDate);
  return isValidExpiredDate && isAfter(new Date(), parseToDate);
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  const { dispatch, getState } = api;
  const { accessToken, refreshToken, expiration } = (getState() as GlobalStateType).auth;

  if (isExpiredToken(expiration)) {
    dispatch(deAuthorize());
    dispatch(clearUser());
    dispatch(AppDeAuthorize());
  }
  if (result.error && result?.error?.status === 401) {
    const body = {
      accessToken,
      refreshToken,
    };
    const reauthUrl = import.meta.env.VITE_API_URL_V1 + Auth.Token;
    const reauthOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(reauthUrl, reauthOptions);
    const refreshResult = await response.json();
    if (refreshResult) {
      api.dispatch(authorize(refreshResult as AuthStateType));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(deAuthorize());
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  tagTypes: ['EventDetails'],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
