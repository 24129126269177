import React, { FC, memo, useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Grid } from '@mui/material';
import { theme } from '@core/theme/theme.ts';
import { PaymentProvider } from '@pages/Order/constants/enums.ts';
import { CheckoutForm } from './components/CheckoutForm.tsx';
import { SkeletonForm } from './components/SkeletonForm.tsx';

const token = import.meta.env.VITE_STRIPE_TOKEN;

export const StripePaymentForm: FC<StripePaymentFormPropsType> = memo(({ createPayment, paymentToken }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    createPayment(PaymentProvider.Stripe);
  }, []);

  useEffect(() => {
    loadStripe(token).then((res) => {
      setStripe(res);
      setIsLoading(false);
    });
  }, []);

  const appearance = {
    variables: {
      fontFamily: `'Manrope',sans-serif`,
      fontLineHeight: '21.5',
      borderRadius: '8px',
      colorBackground: theme.palette.bg.dark,
      colorText: theme.palette.bg.main,
    },
    rules: {
      '.Input': {
        padding: '12px',
        border: '1px solid #FFFFFF',
      },
    },
  };

  return (
    <Grid container direction={'column'} rowGap={2} item xs>
      {!isLoading && stripe && paymentToken ? (
        <Elements
          options={{
            loader: 'auto',
            clientSecret: paymentToken,
            appearance: { ...appearance, theme: 'flat' },
          }}
          stripe={stripe}
        >
          <CheckoutForm />
        </Elements>
      ) : (
        <SkeletonForm />
      )}
    </Grid>
  );
});

type StripePaymentFormPropsType = {
  createPayment: (provider: PaymentProvider) => void;
  paymentToken?: string;
};
