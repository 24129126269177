import { useCallback, useState } from 'react';
import { TicketItemType } from '../../../types/apiTypes';
import { SeatClasses } from '../../../constants/enums.ts';

interface PopperPropsType {
  isOpen: boolean;
  sector: null | string;
  row: null | string;
  seat: null | string;
  price: null | number;
  area: string;
  coins: null | number;
  anchorEl: null | HTMLDivElement;
  discount: null | number;
}

export const usePopper = () => {
  const [popper, setPopper] = useState<PopperPropsType>({
    isOpen: false,
    sector: null,
    row: null,
    seat: null,
    price: null,
    area: '',
    coins: null,
    anchorEl: null,
    discount: null,
  });

  const togglePopper = useCallback((event: MouseEvent, ticket: TicketItemType) => {
    if ((event.target as Element).classList.contains(SeatClasses.RESERVED_NOW)) return;

    setPopper((prevPopper) => ({
      ...prevPopper,
      isOpen: true,
      sector: ticket.sector,
      row: ticket.row,
      seat: ticket.seat,
      price: ticket.price,
      area: ticket.sector,
      coins: 200,
      anchorEl: event.target as HTMLDivElement,
      discount: ticket.discount,
    }));
  }, []);

  const closePopper = useCallback(() => {
    setPopper(popper);
  }, []);

  return {
    popper,
    togglePopper,
    closePopper,
  };
};
