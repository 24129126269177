import React, { FC, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { makeStyles } from 'tss-react/mui';
import { LoadingButton } from '@mui/lab';
import { setAlertData } from '@store/app/actions.ts';
import { AlertType } from '@store/app/constants.ts';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { Pages } from '@store/routes.ts';
import { useDispatch } from 'src/configureStore.ts';

const useStyles = makeStyles({ name: 'CheckoutForm' })(() => ({
  form: {
    minHeight: '285px',
  },
}));

export const CheckoutForm: FC = () => {
  const { eventSlug = '' } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { classes } = useStyles();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${Pages.Event}/${eventSlug}/${Pages.Order}/${Pages.Stripe}`,
      },
    });

    if (error) {
      dispatch(setAlertData({ type: AlertType.Error, message: error?.message ?? 'Something went wrong' }));
    }
    setIsLoading(false);
  };

  return (
    <form id={'payment-form'} onSubmit={handleSubmit} className={classes.form}>
      <Grid container direction={'column'} justifyContent={'space-between'} sx={{ minHeight: '100%' }} gap={'20px'}>
        <Grid item xs={12}>
          <PaymentElement id={'payment-element'} options={{ layout: 'tabs' }} />
        </Grid>
        <Grid item alignSelf={'end'} xs={12} sx={{ width: '100%' }}>
          <LoadingButton
            onClick={handleSubmit}
            loading={isLoading}
            variant={'contained'}
            disabled={isLoading || !stripe || !elements}
            fullWidth
          >
            Pay now
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
