export enum AppSettings {
  APP_NAME = 'Babimbo',
  IS_DEV = 1,
  PURCHASE_SESSION_TIME = 300,
  SELECT_TICKETS_SESSION_TIME = 180,
  MAX_PURCHASE_COUNT = 10,
  RESEND_CODE_TIME = 240,
}

export enum AnalyticsSettings {
  GOOGLE_TRACKING_ID = '',
  APP_INSIGHTS_ID = '',
}

export enum LanguageSetting {
  Ru = 'ru',
  En = 'en',
}
