import React from 'react';

type PropsType = {
  size?: string;
};

export const RampIcon: React.FC<PropsType> = ({ size = '24' }) => {
  return (
    <svg width={size} height={size} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M19.8676 17.4356C19.7777 17.0309 19.3748 16.7746 18.9719 16.8655L17.7951 17.1268L16.1288 12.9174C16.0155 12.6308 15.7387 12.4429 15.4307 12.4429H10.4095L10.249 10.7005H14.0793C14.494 10.7005 14.8301 10.3644 14.8301 9.94973C14.8301 9.53501 14.494 9.19895 14.0793 9.19895H10.1107L9.93108 7.24847C10.6546 7.04105 11.1846 6.37589 11.1846 5.58575C11.1846 4.62977 10.4096 3.85475 9.4536 3.85475C8.49762 3.85475 7.72266 4.62977 7.72266 5.58575C7.72266 6.14303 7.98708 6.63749 8.39616 6.95405L8.97714 13.2626C9.0126 13.6489 9.33672 13.9445 9.72468 13.9445H14.9204L16.6346 18.275C16.7498 18.5662 17.0301 18.7495 17.3324 18.7495C17.3864 18.7495 17.441 18.7436 17.4955 18.7316L19.2974 18.3313C19.7023 18.2413 19.9574 17.8403 19.8676 17.4356Z'
        }
        fill={'#707893'}
      />
      <path
        d={
          'M10.8356 18.6562C8.54487 18.6562 6.68127 16.7926 6.68127 14.5019C6.68127 13.3262 7.17429 12.2654 7.96173 11.509L7.80315 9.73559C6.22869 10.7408 5.17969 12.4993 5.17969 14.5019C5.17969 17.6206 7.71681 20.1577 10.8356 20.1577C12.7253 20.1577 14.3973 19.2227 15.4249 17.7947L14.7332 15.9259C14.1501 17.5162 12.6255 18.6562 10.8356 18.6562Z'
        }
        fill={'#707893'}
      />
    </svg>
  );
};
