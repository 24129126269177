import { Price } from '@core/constants/constants';
import { CurrencyType } from '@core/api/EventsSlice/types/types.ts';
import { PriceFrequencyType } from '@core/api/PriceSlice/types/types.ts';
import { TicketStatus } from '@pages/Order/constants/enums.ts';
import { apiSlice } from '../apiSlice';

export const priceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrency: builder.query<CurrencyType, string>({
      query: (domain) => ({ url: `${Price.Prices}/${Price.Currency}/${domain}` }),
    }),
    getPriceFrequency: builder.query<PriceFrequencyType[], void>({
      query: () => ({
        url: `${Price.Prices}/${Price.Ranges}`,
        params: { ticketStatus: TicketStatus.Free },
      }),
    }),
  }),
});

export const { useGetCurrencyQuery, useGetPriceFrequencyQuery, reducerPath: priceReducerPath } = priceApi;
