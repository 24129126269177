export enum QueryParam {
  Date = 'date',
  SpecificDay = 'specificDay',
  Price = 'price',
}

export enum SpecificDayType {
  None,
  AllDays,
  Weekdays,
  Weekend,
}

export enum SpecificDay {
  AllDays = 'Все дни',
  Weekdays = 'Будние',
  Weekend = 'Выходные',
}

export const QUERY_DATE_FORMAT = 'yyyy-MM-dd';
export const UI_DATE_FORMAT = 'd MMMM';

export const days = [SpecificDay.AllDays, SpecificDay.Weekdays, SpecificDay.Weekend];

export const START_PAGE_NUMBER = 1;
export const START_EVENTS_COUNT = 5;
