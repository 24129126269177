import 'normalize.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ruLocale from 'date-fns/locale/ru';
import { I18nextProvider } from 'react-i18next';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import { PersistGate } from 'redux-persist/integration/react';
import { ru } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { App } from './App.tsx';
import 'dayjs/locale/ru';
import i18n from './i18n';
import configureStore from './configureStore';
import { AnalyticsSettings } from './settings/appSettings';

dayjs.extend(LocalizedFormat);
setDefaultOptions({ locale: ru });

if (AnalyticsSettings.APP_INSIGHTS_ID) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: AnalyticsSettings.APP_INSIGHTS_ID,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

export const { persistor, store } = configureStore();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <I18nextProvider i18n={i18n}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CacheProvider value={muiCache}>
            <App />
          </CacheProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  </I18nextProvider>
);
