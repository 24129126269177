import { FC, memo, useEffect } from 'react';
import { Grid } from '@mui/material';
import { PaymentProvider } from '@pages/Order/constants/enums.ts';
import { CheckoutForm } from './components/CheckoutForm.tsx';

export const RevolutPaymentForm: FC<RevolutPaymentFormPropsType> = memo(({ createPayment, paymentToken }) => {
  useEffect(() => {
    createPayment(PaymentProvider.Revolut);
  }, []);

  return (
    <Grid container direction={'column'} rowGap={2} item xs sx={{ minHeight: '270px' }}>
      <CheckoutForm paymentToken={paymentToken} />
    </Grid>
  );
});

type RevolutPaymentFormPropsType = {
  createPayment: (provider: PaymentProvider) => void;
  paymentToken?: string;
};
