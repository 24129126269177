import jwt_decode from 'jwt-decode';
import { AppAuthorize } from '@store/app/actions.ts';
import { authorize } from '@store/auth/actions.ts';
import { UserType } from '@store/user/types.ts';
import { AuthStateType } from '@store/auth/types.ts';
import { userInitiate } from '@store/user/actions.ts';
import { AppDispatch, GlobalStateType } from 'src/configureStore.ts';
import { Auth } from '@core/constants/constants';
import { POST_METHOD } from '@core/constants/http-method-constants';
import {
  RequestResetRequestType,
  RequestResetResponseType,
  DecodeJWTType,
  ResetPasswordRequestType,
  SignInRequestType,
  SignUpRequestType,
  SignUpResponseType,
} from './authType';

import { apiSlice } from '../apiSlice';

const handleAuthQueryFulfilled = (
  dispatch: AppDispatch,
  data: AuthStateType & {
    rememberMe: boolean;
    acceptCookies: boolean;
  }
) => {
  if (!data?.accessToken) return;
  const { accessToken, refreshToken, expiration, rememberMe, userId, acceptCookies } = data;
  const decodeToken: DecodeJWTType = jwt_decode(accessToken);

  const user: UserType = {
    userId: decodeToken.userId,
    email: decodeToken.email,
    role: decodeToken.role,
    firstName: null,
    secondName: null,
    isRememberMe: rememberMe,
    acceptCookies,
  };

  dispatch(authorize({ accessToken, refreshToken, userId, expiration }));
  dispatch(AppAuthorize());
  dispatch(userInitiate(user));
};

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthStateType, SignInRequestType>({
      query: ({ email, password }) => ({
        url: Auth.SignIn,
        method: POST_METHOD,
        body: { email, password },
      }),
      onQueryStarted: async ({ rememberMe }, { dispatch, queryFulfilled, getState }) => {
        const { data } = await queryFulfilled;
        const { acceptCookies } = (getState() as GlobalStateType).user;
        handleAuthQueryFulfilled(dispatch, { ...data, rememberMe, acceptCookies });
      },
    }),
    signUp: builder.mutation<SignUpResponseType, SignUpRequestType>({
      query: (body) => ({
        url: Auth.SignUp,
        method: POST_METHOD,
        body,
      }),
    }),
    requestReset: builder.mutation<RequestResetResponseType, RequestResetRequestType>({
      query: (body) => ({
        url: `${Auth.RequestReset}?${new URLSearchParams(Object.entries(body)).toString()}`,
        method: POST_METHOD,
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequestType>({
      query: (body) => ({ url: Auth.ResetPassword, method: POST_METHOD, body }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useRequestResetMutation,
  useResetPasswordMutation,
  reducerPath: authReducerPath,
} = authApi;
