import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, TypographyProps } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

interface TextWithGradientProps extends TypographyProps {
  gradientColor?: string;
  isUnderline?: boolean;
  to?: LinkProps['to'];
}

const useStyles = makeStyles<{ gradientColor?: string }>({ name: 'WithGradient' })((theme, { gradientColor }) => ({
  gradient: {
    display: 'flex',
    alignItems: 'center',
    background: gradientColor,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  underline: {
    display: 'inline-block',
    position: 'relative',
    textWrap: 'nowrap',
    '&::before': {
      position: 'absolute',
      content: '""',
      background: gradientColor,
      width: '100%',
      height: '1px',
      bottom: '0px',
    },
  },
}));

export const TextWithGradient: React.FC<TextWithGradientProps> = ({
  gradientColor,
  isUnderline = false,
  children,
  to,
  ...typographyProps
}) => {
  const { classes } = useStyles({ gradientColor });

  return to ? (
    <Link to={to}>
      <Typography {...typographyProps} className={`${classes.gradient} ${isUnderline ? classes.underline : ''}`}>
        {children}
      </Typography>
    </Link>
  ) : (
    <Typography {...typographyProps} className={`${classes.gradient} ${isUnderline ? classes.underline : ''}`}>
      {children}
    </Typography>
  );
};
