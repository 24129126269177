import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const AppLoader = () => {
  const loader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {}, loader);

    const letters = ['#b1', '#a', '#b2', '#i', '#m', '#b3', '#o'];

    const tl = gsap.timeline({ repeat: -1, yoyo: true });

    tl.fromTo('#logo', { y: 500 }, { y: 0, duration: 0.2 });

    tl.fromTo(letters, { x: 300 }, { x: 0, duration: 0.2, stagger: 0.2 });

    tl.to(['#logo', ...letters], {
      x: -300,
      delay: 0.5,
      duration: 0.2,
      stagger: 0.2,
    });

    return () => ctx.revert();
  }, []);

  return (
    <div ref={loader}>
      <svg width={'250'} height={'65'} viewBox={'0 0 177 38'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
        <mask
          id={'mask0_5417_13703'}
          style={{ maskType: 'alpha' }}
          maskUnits={'userSpaceOnUse'}
          x={'0'}
          y={'0'}
          width={'39'}
          height={'38'}
        >
          <ellipse cx={'19.5'} cy={'18.8906'} rx={'19.5'} ry={'18.8906'} fill={'#D9D9D9'} />
        </mask>
        <g id={'logo'} mask={'url(#mask0_5417_13703)'}>
          <path
            d={
              'M-0.0800781 0.605823C-0.0800781 -1.07442 1.28203 -2.43652 2.96227 -2.43652H9.80755H10.1744C11.1263 -2.43652 12.0195 -1.9894 12.6641 -1.289C14.0976 0.268722 16.9026 2.8606 19.6952 2.8606C22.4878 2.8606 25.2927 0.268721 26.7263 -1.289C27.3709 -1.9894 28.2641 -2.43652 29.2159 -2.43652H29.5828H36.4281C38.1083 -2.43652 39.4704 -1.07442 39.4704 0.605824V37.114C39.4704 38.7942 38.1083 40.1563 36.4281 40.1563H29.5828H29.2159C28.2641 40.1563 27.3709 39.7092 26.7263 39.0088C25.2927 37.4511 22.4878 34.8592 19.6952 34.8592C16.9026 34.8592 14.0976 37.4511 12.6641 39.0088C12.0195 39.7092 11.1263 40.1563 10.1744 40.1563H9.80755H2.96227C1.28203 40.1563 -0.0800781 38.7942 -0.0800781 37.114V0.605823Z'
            }
            fill={'url(#paint0_linear_5417_13703)'}
          />
          <path d={'M12.0879 10.0371H27.1475'} stroke={'#F8CADB'} strokeWidth={'4.875'} strokeLinecap={'round'} />
          <path d={'M12.0879 28.291H27.1475'} stroke={'#F8CADB'} strokeWidth={'4.875'} strokeLinecap={'round'} />
          <path d={'M7.5293 19.1641H11.1855'} stroke={'white'} strokeWidth={'3.65082'} strokeLinecap={'round'} />
          <path d={'M17.2578 19.1641H31.8828'} stroke={'white'} strokeWidth={'3.65082'} strokeLinecap={'round'} />
        </g>
        <path
          id={'b1'}
          d={
            'M49 29.1147V10.6781C49 10.1456 49.1917 9.68759 49.5751 9.30416C49.9799 8.89943 50.4485 8.69706 50.9811 8.69706H57.4035C59.5124 8.69706 61.1739 9.21895 62.3881 10.2627C63.6236 11.2852 64.2413 12.6698 64.2413 14.4165C64.2413 16.4828 63.3999 18.0272 61.7171 19.0497C64.2733 20.0508 65.5514 21.936 65.5514 24.7052C65.5514 26.665 64.8804 28.22 63.5384 29.3703C62.2177 30.4993 60.407 31.0638 58.1064 31.0638H50.9811C50.4485 31.0638 49.9799 30.8721 49.5751 30.4886C49.1917 30.1052 49 29.6472 49 29.1147ZM52.8982 21.0627V27.3253H58.1384C59.2674 27.3253 60.1301 27.0591 60.7266 26.5265C61.3443 25.994 61.6532 25.2165 61.6532 24.194C61.6532 23.1715 61.3443 22.394 60.7266 21.8615C60.1301 21.3289 59.2674 21.0627 58.1384 21.0627H52.8982ZM52.8982 12.4355V17.452H57.4354C59.3739 17.4094 60.3431 16.568 60.3431 14.9278C60.3431 14.1396 60.0875 13.5325 59.5763 13.1065C59.0863 12.6592 58.3727 12.4355 57.4354 12.4355H52.8982Z'
          }
          fill={'white'}
        />
        <path
          id={'a'}
          d={
            'M67.3884 22.7561C67.3458 20.3704 68.1126 18.3467 69.689 16.6852C71.2866 15.0023 73.225 14.1822 75.5043 14.2248C77.2936 14.2248 78.7528 14.6828 79.8818 15.5988C80.2013 14.7893 80.8191 14.3846 81.735 14.3846C82.2889 14.3846 82.7469 14.5763 83.109 14.9597C83.4924 15.3219 83.6841 15.7799 83.6841 16.3337V29.3064C83.6841 29.8602 83.4924 30.3289 83.109 30.7123C82.7469 31.0744 82.2889 31.2555 81.735 31.2555C80.8191 31.2555 80.2013 30.8401 79.8818 30.0093C78.7528 30.9253 77.2936 31.3833 75.5043 31.3833C73.2037 31.3833 71.2653 30.5632 69.689 28.923C68.1552 27.2827 67.3884 25.2271 67.3884 22.7561ZM76.1114 27.549C77.6025 27.549 78.8167 27.0697 79.754 26.1111V19.497C78.8167 18.5597 77.6025 18.0911 76.1114 18.0911C74.6629 18.0911 73.502 18.5278 72.6286 19.4011C71.7552 20.2532 71.3185 21.3928 71.3185 22.82C71.3185 24.226 71.7552 25.3656 72.6286 26.239C73.502 27.1123 74.6629 27.549 76.1114 27.549Z'
          }
          fill={'white'}
        />
        <path
          id={'b2'}
          d={
            'M87.474 29.3064V8.88878C87.474 8.33493 87.6657 7.8663 88.0491 7.48287C88.4326 7.09944 88.8906 6.90772 89.4231 6.90772C89.9769 6.90772 90.4456 7.09944 90.829 7.48287C91.2124 7.8663 91.4042 8.33493 91.4042 8.88878V15.5029C92.5757 14.6509 93.9923 14.2248 95.6538 14.2248C97.9757 14.2248 99.9035 15.0449 101.437 16.6852C102.992 18.3041 103.77 20.3278 103.77 22.7561C103.77 25.2484 102.992 27.304 101.437 28.923C99.9035 30.5632 97.9757 31.3833 95.6538 31.3833C93.9071 31.3833 92.4479 30.9253 91.2763 30.0093C90.9568 30.8401 90.3497 31.2555 89.4551 31.2555C88.9012 31.2555 88.4326 31.0744 88.0491 30.7123C87.6657 30.3289 87.474 29.8602 87.474 29.3064ZM91.4042 19.497V26.1111C92.3201 27.0697 93.545 27.549 95.0787 27.549C96.5059 27.549 97.6562 27.1123 98.5295 26.239C99.4029 25.3656 99.8396 24.226 99.8396 22.82C99.8396 21.3928 99.4029 20.2532 98.5295 19.4011C97.6562 18.5278 96.4952 18.0911 95.0467 18.0911C93.5556 18.0911 92.3414 18.5597 91.4042 19.497Z'
          }
          fill={'white'}
        />
        <path
          id={'i'}
          d={
            'M108.474 11.7645C107.707 11.7645 107.068 11.5089 106.557 10.9976C106.045 10.4864 105.79 9.84735 105.79 9.08049C105.79 8.27103 106.045 7.63198 106.557 7.16334C107.068 6.6521 107.707 6.39648 108.474 6.39648C109.262 6.39648 109.912 6.6521 110.423 7.16334C110.955 7.63198 111.222 8.27103 111.222 9.08049C111.222 9.84735 110.955 10.4864 110.423 10.9976C109.912 11.5089 109.262 11.7645 108.474 11.7645ZM106.525 29.2744V16.3337C106.525 15.7799 106.716 15.3112 107.1 14.9278C107.483 14.5444 107.941 14.3526 108.474 14.3526C109.028 14.3526 109.496 14.5444 109.88 14.9278C110.263 15.3112 110.455 15.7799 110.455 16.3337V29.2744C110.455 29.8283 110.263 30.2969 109.88 30.6803C109.496 31.0638 109.028 31.2555 108.474 31.2555C107.941 31.2555 107.483 31.0638 107.1 30.6803C106.716 30.2969 106.525 29.8283 106.525 29.2744Z'
          }
          fill={'white'}
        />
        <path
          id={'m'}
          d={
            'M114.121 29.3064V16.3337C114.121 15.7799 114.302 15.3219 114.664 14.9597C115.048 14.5763 115.516 14.3846 116.07 14.3846C116.581 14.3846 117.018 14.555 117.38 14.8958C117.764 15.2154 117.977 15.6307 118.019 16.142C118.978 14.8639 120.362 14.2248 122.173 14.2248C124.197 14.2248 125.709 15.1195 126.71 16.9088C127.754 15.1195 129.426 14.2248 131.727 14.2248C133.495 14.2248 134.89 14.8745 135.913 16.1739C136.956 17.4733 137.478 19.2414 137.478 21.478V29.2425C137.478 29.8389 137.287 30.3289 136.903 30.7123C136.541 31.0744 136.072 31.2555 135.497 31.2555C134.922 31.2555 134.443 31.0744 134.059 30.7123C133.697 30.3289 133.516 29.8389 133.516 29.2425V21.7656C133.516 20.6579 133.293 19.7846 132.845 19.1455C132.419 18.5065 131.801 18.1869 130.992 18.1869C129.906 18.1869 129.085 18.613 128.532 19.465C127.999 20.2958 127.733 21.6911 127.733 23.6508V29.2425C127.733 29.8389 127.552 30.3289 127.19 30.7123C126.828 31.0744 126.359 31.2555 125.784 31.2555C125.209 31.2555 124.729 31.0744 124.346 30.7123C123.984 30.3289 123.803 29.8389 123.803 29.2425V21.7656C123.803 20.6366 123.579 19.7633 123.132 19.1455C122.706 18.5065 122.088 18.1869 121.278 18.1869C120.192 18.1869 119.383 18.613 118.85 19.465C118.318 20.2958 118.051 21.6911 118.051 23.6508V29.3064C118.051 29.8602 117.849 30.3289 117.444 30.7123C117.061 31.0744 116.592 31.2555 116.038 31.2555C115.506 31.2555 115.048 31.0744 114.664 30.7123C114.302 30.3289 114.121 29.8602 114.121 29.3064Z'
          }
          fill={'white'}
        />
        <path
          id={'b3'}
          d={
            'M141.082 29.3064V8.88878C141.082 8.33493 141.273 7.8663 141.657 7.48287C142.04 7.09944 142.498 6.90772 143.031 6.90772C143.585 6.90772 144.053 7.09944 144.437 7.48287C144.82 7.8663 145.012 8.33493 145.012 8.88878V15.5029C146.183 14.6509 147.6 14.2248 149.262 14.2248C151.583 14.2248 153.511 15.0449 155.045 16.6852C156.6 18.3041 157.377 20.3278 157.377 22.7561C157.377 25.2484 156.6 27.304 155.045 28.923C153.511 30.5632 151.583 31.3833 149.262 31.3833C147.515 31.3833 146.056 30.9253 144.884 30.0093C144.565 30.8401 143.957 31.2555 143.063 31.2555C142.509 31.2555 142.04 31.0744 141.657 30.7123C141.273 30.3289 141.082 29.8602 141.082 29.3064ZM145.012 19.497V26.1111C145.928 27.0697 147.153 27.549 148.686 27.549C150.114 27.549 151.264 27.1123 152.137 26.239C153.011 25.3656 153.447 24.226 153.447 22.82C153.447 21.3928 153.011 20.2532 152.137 19.4011C151.264 18.5278 150.103 18.0911 148.654 18.0911C147.163 18.0911 145.949 18.5597 145.012 19.497Z'
          }
          fill={'white'}
        />
        <path
          id={'o'}
          d={
            'M159.002 22.82C158.959 20.413 159.79 18.368 161.494 16.6852C163.22 15.0023 165.318 14.1822 167.789 14.2248C170.26 14.2248 172.337 15.0449 174.02 16.6852C175.724 18.3254 176.576 20.3704 176.576 22.82C176.619 25.2058 175.777 27.2401 174.052 28.923C172.326 30.6058 170.239 31.4259 167.789 31.3833C165.297 31.3833 163.198 30.5632 161.494 28.923C159.833 27.2827 159.002 25.2484 159.002 22.82ZM167.757 18.123C166.394 18.123 165.254 18.5704 164.338 19.465C163.422 20.3597 162.964 21.478 162.964 22.82C162.964 24.162 163.422 25.2804 164.338 26.1751C165.275 27.0484 166.415 27.4851 167.757 27.4851C169.12 27.4851 170.271 27.0484 171.208 26.1751C172.124 25.2804 172.582 24.162 172.582 22.82C172.582 21.478 172.124 20.3597 171.208 19.465C170.292 18.5704 169.142 18.123 167.757 18.123Z'
          }
          fill={'white'}
        />
        <defs>
          <linearGradient
            id={'paint0_linear_5417_13703'}
            x1={'-0.0800781'}
            y1={'18.8599'}
            x2={'41.0175'}
            y2={'18.8599'}
            gradientUnits={'userSpaceOnUse'}
          >
            <stop stopColor={'#E5427D'} />
            <stop offset={'1'} stopColor={'#9F4AE1'} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default AppLoader;
