import { Popper, Fade, Grid, Typography } from '@mui/material';
import React from 'react';
import { PlaceSelectorIcon } from '@icons/PlaceSelectorIcon';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router';
import { PriceView } from '@components/common/PriceView.tsx';
import { PaymentContextType } from '../../../types/commonTypes.ts';

type PopperPropsType = {
  isOpen: boolean;
  sector: null | string;
  row: null | string;
  seat: null | string;
  price: null | number;
  area: string;
  coins: null | number;
  anchorEl: null | HTMLElement;
  discount: null | number;
};

const useStyles = makeStyles({ name: 'Tooltip' })(() => ({
  tooltip: {
    background: '#4A62AC',
    borderRadius: '20px',
    padding: '15px 20px',
    position: 'relative',
    minHeight: '80px',
    '& :after': {
      content: "''",
      position: 'absolute',
      bottom: '1px',
      left: '50%',
      height: '22px',
      width: '36px',
      transform: 'rotate(180deg) translate(50%, -100%)',
      clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
      background: '#4A62AC',
    },
  },
  devidor: {
    height: '20px',
    width: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    display: 'inline-block',
  },
}));

export const Tooltip = React.forwardRef<HTMLDivElement, PopperPropsType>(
  ({ isOpen, sector, row, seat, price, anchorEl, discount }, ref) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { currency } = useOutletContext<PaymentContextType>();
    const canBeOpen = isOpen && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
      <Popper
        sx={{ zIndex: 1000 }}
        ref={ref}
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        transition
        placement={'top'}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 30],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <Grid container className={classes.tooltip}>
              <Grid container item columnGap={3} alignItems={'center'}>
                <Grid xs item container direction={'column'} rowGap={1.5} justifyContent={'center'}>
                  <Grid item container alignItems={'center'} columnGap={1.5}>
                    <PlaceSelectorIcon />
                    <Typography fontWeight={500} variant={'body2'} fontSize={'18px'}>
                      {`${sector || ''}`}
                    </Typography>
                    <div className={classes.devidor} />
                    <Typography fontWeight={500} variant={'body2'} fontSize={'18px'}>
                      {`${row ? `${row} ${t('Payment.OrderDescription.Row')}` : ''}`}
                    </Typography>
                    <div className={classes.devidor} />
                    <Typography fontWeight={500} variant={'body2'} fontSize={'18px'}>
                      {`${seat ? `${seat} ${t('Payment.OrderDescription.Seat')}` : ''}`}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item style={{ maxWidth: '110px' }}>
                  <PriceView
                    price={{ value: price || 0, discount }}
                    currency={currency}
                    element={'ticketGroupItemSlider'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Fade>
        )}
      </Popper>
    );
  }
);
