import { FC, useEffect, useState } from 'react';
import { ModalWindow } from '@components/ModalWindow/ModalWindow.tsx';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { TicketsLimitIcon } from '@icons/TicketsLimitIcon.tsx';
import { useTranslation } from 'react-i18next';

export const TicketLimitModal: FC<TicketLimitModalPropsType> = ({ open, limitTickets, resetTickets }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!open) return;

    setIsOpen(true);
  }, [open]);

  const handleContinueClick = () => {
    setIsOpen(false);
  };

  const handleResetAllClick = () => {
    resetTickets();
    setIsOpen(false);
  };
  return (
    <ModalWindow open={isOpen} width={'auto'}>
      <Grid container wrap={'nowrap'} gap={'15px'}>
        <Grid item container wrap={'nowrap'} flexDirection={'column'} gap={'15px'} xs>
          <Grid item container gap={'10px'} wrap={'nowrap'} alignItems={'center'}>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <TicketsLimitIcon />
            </Grid>
            <Grid item xs>
              <Typography variant={'body2'} fontWeight={600}>
                {t('Booking. TicketLimitHasBeen')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography color={'primary.contrastText'} variant={'caption'} fontWeight={300}>
              {`* ${t('Booking.MaxPurchaseTickets', { count: limitTickets })}`}
            </Typography>
          </Grid>
        </Grid>
        <Divider flexItem orientation={'vertical'} />
        <Grid item container rowGap={'15px'} flexDirection={'column'} wrap={'nowrap'} xs={4}>
          <Grid item xs>
            <Button variant={'contained'} onClick={handleContinueClick} fullWidth>
              <Typography variant={'caption'} fontWeight={600}>
                {t('Booking.Continue')}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs>
            <Button variant={'outlined'} onClick={handleResetAllClick} fullWidth>
              <Typography variant={'caption'} fontWeight={600}>
                {t('Booking.ResetAll')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalWindow>
  );
};

type TicketLimitModalPropsType = {
  open: boolean;
  limitTickets: number;
  resetTickets: () => void;
};
