import { FC } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { theme } from '@core/theme/theme.ts';
import { ShareIcon } from '@icons/ShareIcon.tsx';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { TextWithGradient } from '@components/common/TextWithGradient.tsx';
import { EmailIcon } from '@components/icons/EmailIcon.tsx';

import { useDispatch, useSelector } from 'src/configureStore.ts';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { setAlertData } from '@store/app/actions.ts';
import { AlertType } from '@store/app/constants.ts';
import { useEmailOrderMutation } from '../api/bookingApi.ts';
import { OrderLink } from './OrderLink.tsx';

type StylePropsType = {
  isProcessed: boolean;
};

const useStyles = makeStyles<StylePropsType>({ name: 'Header' })((theme, { isProcessed }) => ({
  quantity: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  circle: {
    position: 'relative',
    width: '33px',
    height: '33px',
    borderRadius: '50%',
    background: theme.palette.bgSecond.dark,
  },
  container: {
    backgroundColor: theme.palette.bg.dark,
    padding: '15px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      color: isProcessed ? theme.palette.primary.contrastText : 'inherit',
    },
  },
}));

export const Header: FC<HeaderPropsType> = ({ count, isProcessed, orderId }) => {
  const { classes } = useStyles({ isProcessed });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const [emailOrder] = useEmailOrderMutation();

  const onSendTickets = async (orderId: string) => {
    const response = (await emailOrder(orderId)) as { error?: FetchBaseQueryError };
    if (!response.error) {
      dispatch(setAlertData({ type: AlertType.Success, message: t('Booking.Sended') }));
    }
  };

  const backgroundIcon = isProcessed
    ? theme.palette.bgIconGradient.disabled
    : theme.palette.bgIconGradient.mainGradient;

  return (
    <Grid className={classes.container} item container rowGap={'10px'} wrap={'wrap'}>
      <Grid item container alignItems={'center'} flexGrow={{ xs: 0 }} xs>
        <Grid item display={'flex'} alignItems={'center'} gap={'10px'}>
          <Box className={classes.circle}>
            <Typography className={classes.quantity} align={'center'} variant={'body2'}>
              {count}
            </Typography>
          </Box>
          <Typography variant={'body2'} noWrap>
            {t('Booking.TotalTickets')}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container alignItems={'center'} gap={'20px'} justifyContent={'flex-end'} xs>
        {isAuthorized ? (
          <Button onClick={() => onSendTickets(orderId)} startIcon={<EmailIcon />} variant={'text'}>
            <TextWithGradient gradientColor={theme.palette.bgGradient.lightGradient} variant={'body1'} isUnderline>
              {t('Booking.MailTicket')}
            </TextWithGradient>
          </Button>
        ) : null}
        {null && (
          <OrderLink
            to={''}
            title={t('Booking.ShareAllTickets')}
            icon={<ShareIcon gradient={backgroundIcon} />}
            disabled
          />
        )}
      </Grid>
    </Grid>
  );
};

type HeaderPropsType = {
  count: number;
  isProcessed: boolean;
  orderId: string;
};
