export enum Pages {
  Event = 'event',
  Profile = 'profile',
  Events = 'events',
  Order = 'order',
  OrderResult = 'order-result',
  Stripe = 'stripe',
  Failed = 'failed',
  Tour = 'tour',
  OrderDetails = 'order-details',
}

export enum PagesRoutes {
  Main = '/',
  Events = '/events',
  EventDetails = `/${Pages.Event}/:eventSlug`,
  EventTour = `/${Pages.Event}/:eventSlug/${Pages.Tour}`,
  Order = `/${Pages.Event}/:eventSlug/${Pages.Order}`,
  InformationError = `/${Pages.Failed}/:keyFailed`,
  Registration = `/user/registration`,
  Login = `/user/login`,
  ForgotPassword = '/user/forgot-password',
  NewPassword = '/user/new-password',
  Profile = `/profile`,
  ServerError = `/${Pages.Failed}/server-error`,
  NotFound = '*',
  Stripe = `stripe`,
  OrderDetails = `${Pages.OrderDetails}/:orderId`,
}
