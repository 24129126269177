import React, { Dispatch, FC, MouseEvent, SetStateAction, useEffect, useState } from 'react';
import { useDebounce } from '@core/hooks/useDebounce';
import { useOutletContext } from 'react-router';
import { SeatClasses, TicketStatus } from '@pages/Order/constants/enums';
import { HOVERED_SEAT_COLOR } from '@pages/Order/constants/constants';
import PanAndZoomImage from '@pages/Order/helpers/PanAndZoomImage/PanAndZoomImage';
import { TicketItemType } from '@pages/Order/types/apiTypes';
import { OrderTicketType, PaymentContextType } from '@pages/Order/types/commonTypes';
import { Tooltip } from './components/Tooltip';
import { SectionWrapper } from '../common/SectionWrapper';
import './index.css';
import { useGetColorCollectionByPrice } from './hooks/useGetColorCollectionByPrice';
import { addClassesAndStyles, removeClassesAndStyles } from './helpers/helpers';
import { usePopper } from './hooks/usePopper';

type PlaceSelectionPropsType = {
  scheme: string;
  tickets: TicketItemType[];
  orderTickets: OrderTicketType[];
  handleTicketClick: (
    id: string,
    uId: string,
    price: number,
    sector: string,
    discount: number | null,
    coins: number | null,
    title: string,
    row: string,
    seat: string
  ) => void;
  reservedTickets: string[];
  handleReserve: (id: string) => void;
  handleRelease: (id: string) => void;
  hoveredGroupTicket: string;
  setReservedTickets: Dispatch<SetStateAction<string[]>>;
  orderTicketsCount: number;
  isExpiredTime: boolean;
  isLargeScreen: boolean;
};

export const PlaceSelection: FC<PlaceSelectionPropsType> = React.memo(
  ({
    scheme,
    handleReserve,
    handleRelease,
    tickets,
    handleTicketClick,
    orderTickets,
    hoveredGroupTicket,
    reservedTickets,
    setReservedTickets,
    orderTicketsCount,
    isExpiredTime,
    isLargeScreen,
  }) => {
    const { colorCollection, setColorCollection } = useGetColorCollectionByPrice();
    const { isFullScreen, maxPurchaseCount } = useOutletContext<PaymentContextType>();
    const { popper, togglePopper, closePopper } = usePopper();
    const [isMaxPurchaseCount, setIsMaxPurchaseCount] = useState(false);

    const debouncedTogglePopper = useDebounce(togglePopper, 300);

    const handleBook = (ticket: TicketItemType) => {
      if (!handleReserve) return;
      const { id, uId, price, sector, discount, row, seat, coins } = ticket;
      handleTicketClick(id, uId, price, sector, discount, coins, seat, row, seat);
      handleReserve(ticket.id);
    };

    const handleUnBook = (ticket: TicketItemType) => {
      if (!handleRelease) return;
      const { id, uId, price, sector, discount, row, seat, coins } = ticket;

      handleTicketClick(id, uId, price, sector, discount, coins, `${row} ${seat}`, row, seat);
      handleRelease(ticket.id);
    };

    const isReserveByYou = (id: string): boolean => {
      return orderTickets.some((ticket) => ticket.id === id);
    };

    const isFreeTicket = (ticketStatus: TicketStatus) =>
      ticketStatus === TicketStatus.Free || ticketStatus === TicketStatus.Reserved;

    const isTicketFreeAndReservedByYou = (ticket: TicketItemType) =>
      isFreeTicket(ticket.status) && isReserveByYou(ticket.id);

    const isTicketFreeAndNotReservedByYou = (ticket: TicketItemType) =>
      isFreeTicket(ticket.status) && !isReserveByYou(ticket.id);

    const isTicketReservedNow = (ticket: TicketItemType) => reservedTickets.includes(ticket.id);

    const removeListeners = () => {
      tickets.forEach((ticket) => {
        const seat = document.getElementById(ticket.uId);
        if (!seat) return;
        seat.removeEventListener('mouseenter', (e) => debouncedTogglePopper(e, ticket));
        seat.removeEventListener('mouseleave', closePopper);
      });
    };

    const removeAvailableSeat = (ticket: TicketItemType, seat: HTMLElement) => {
      removeClassesAndStyles(seat, [SeatClasses.AVAILABLE, 'pointer'], {
        stroke: colorCollection.get(ticket.price),
      });
      addClassesAndStyles(seat, [SeatClasses.RESERVED_NOW], {});
    };

    const addAvailableSeats = (ticket: TicketItemType, seat: HTMLElement) => {
      addClassesAndStyles(seat, ['pointer'], {});
      addClassesAndStyles(seat, [SeatClasses.AVAILABLE], { stroke: colorCollection.get(ticket.price) });
      removeClassesAndStyles(seat, [SeatClasses.RESERVED], { fill: colorCollection.get(ticket.price) });

      seat.addEventListener('mouseenter', (e) => debouncedTogglePopper(e, ticket));
      seat.addEventListener('mouseleave', closePopper);
    };

    useEffect(() => {
      if (!scheme || !tickets.length) return;

      tickets.forEach((ticket) => {
        const seat = document.getElementById(ticket.uId);
        if (!seat) return;

        setColorCollection(ticket);
        if (ticket.status === TicketStatus.Free) {
          addAvailableSeats(ticket, seat);
        } else if (ticket.status === TicketStatus.Reserved) {
          removeAvailableSeat(ticket, seat);
          setReservedTickets((prevState) => [...prevState, ticket.id]);
        } else {
          removeAvailableSeat(ticket, seat);
        }
      });

      return () => {
        removeListeners();
      };
    }, [scheme, tickets]);

    useEffect(() => {
      if (!orderTickets.length) return;

      orderTickets.forEach((ticket) => {
        const seat = document.getElementById(ticket.uId);
        if (!seat) return;
        seat.addEventListener('mouseenter', (e) => debouncedTogglePopper(e, ticket));
        seat.addEventListener('mouseleave', closePopper);
        handleReserve(ticket.id);
        addClassesAndStyles(seat, [SeatClasses.RESERVED], { fill: colorCollection.get(ticket.price) });
        removeClassesAndStyles(seat, [SeatClasses.AVAILABLE], { stroke: colorCollection.get(ticket.price) });
      });
    }, []);

    useEffect(() => {
      if (!tickets.length) return;
      tickets.forEach((ticket) => {
        const seat = document.getElementById(ticket.uId);
        if (!seat) return;

        if (ticket.uId === hoveredGroupTicket) {
          addClassesAndStyles(seat, [], { stroke: HOVERED_SEAT_COLOR });
        }
      });
    }, [hoveredGroupTicket, tickets]);

    useEffect(() => {
      if (!tickets.length) return;

      tickets.forEach((ticket) => {
        const seat = document.getElementById(ticket.uId);
        if (!seat) return;

        if (isTicketReservedNow(ticket)) {
          removeClassesAndStyles(seat, [SeatClasses.AVAILABLE, 'pointer'], {
            stroke: colorCollection.get(ticket.price),
          });
          addClassesAndStyles(seat, [SeatClasses.RESERVED_NOW], {});
        } else if (
          !isReserveByYou(ticket.id) &&
          isFreeTicket(ticket.status) &&
          !isTicketReservedNow(ticket) &&
          !isMaxPurchaseCount
        ) {
          removeClassesAndStyles(seat, [SeatClasses.RESERVED], { fill: colorCollection.get(ticket.price) });
          removeClassesAndStyles(seat, [SeatClasses.RESERVED_NOW], {});

          addClassesAndStyles(seat, [SeatClasses.AVAILABLE, 'pointer'], { stroke: colorCollection.get(ticket.price) });
        }
      });
    }, [reservedTickets, isExpiredTime, orderTickets]);

    useEffect(() => {
      if (orderTicketsCount !== maxPurchaseCount) return;

      tickets.forEach((ticket) => {
        const seat = document.getElementById(ticket.uId);
        if (!seat || isReserveByYou(ticket.id) || isTicketReservedNow(ticket)) return;
        removeClassesAndStyles(seat, [SeatClasses.AVAILABLE, 'pointer'], {
          stroke: colorCollection.get(ticket.price),
        });
        addClassesAndStyles(seat, [SeatClasses.RESERVED_NOW], {});
      });
      setIsMaxPurchaseCount(true);
    }, [orderTicketsCount, maxPurchaseCount]);

    useEffect(() => {
      if (!isMaxPurchaseCount || orderTicketsCount) return;
      tickets.forEach((ticket) => {
        const seat = document.getElementById(ticket.uId);
        if (!seat) return;
        if (ticket.status === TicketStatus.Free) {
          addClassesAndStyles(seat, [SeatClasses.AVAILABLE, 'pointer'], {
            stroke: colorCollection.get(ticket.price),
          });
          removeClassesAndStyles(seat, [SeatClasses.RESERVED], { fill: colorCollection.get(ticket.price) });
          removeClassesAndStyles(seat, [SeatClasses.RESERVED_NOW], {});
        }
      });
      setIsMaxPurchaseCount(false);
    }, [isMaxPurchaseCount, orderTicketsCount]);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      const element = event.target as Element;
      if (!element || element.nodeName !== 'rect') return;
      const ticket = tickets.find((ticket) => ticket.uId === element.id);
      if (!ticket || isTicketReservedNow(ticket)) return;

      if (isTicketFreeAndNotReservedByYou(ticket)) {
        if (maxPurchaseCount <= orderTicketsCount) return;
        handleBook(ticket);
        addClassesAndStyles(element, [SeatClasses.RESERVED], { fill: colorCollection.get(ticket.price) });
        removeClassesAndStyles(element, [SeatClasses.AVAILABLE], { stroke: colorCollection.get(ticket.price) });
      } else if (isTicketFreeAndReservedByYou(ticket)) {
        handleUnBook(ticket);

        addClassesAndStyles(element, [SeatClasses.AVAILABLE, 'pointer'], { stroke: colorCollection.get(ticket.price) });
        removeClassesAndStyles(element, [SeatClasses.RESERVED], { fill: colorCollection.get(ticket.price) });
      }
    };

    return (
      <SectionWrapper borderRadius={isFullScreen ? 'none' : isLargeScreen ? '0 0 20px 20px' : '0px'}>
        <PanAndZoomImage>
          <Tooltip
            seat={popper.seat}
            sector={popper.sector}
            row={popper.row}
            area={popper.area}
            coins={popper.coins}
            price={popper.price}
            isOpen={popper.isOpen}
            anchorEl={popper.anchorEl}
            discount={popper.discount}
          />
          <div role={'presentation'} onClick={handleClick} dangerouslySetInnerHTML={{ __html: scheme }} />
        </PanAndZoomImage>
      </SectionWrapper>
    );
  }
);
