import { useSwiper } from 'swiper/react';
import { IconButton } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

type SwiperNavButtonsTypeProps = {
  isBeginning: boolean;
  isEnd: boolean;
};

const useStyles = makeStyles({ name: 'SwiperNavButtons' })((theme) => ({
  button: {
    width: '40px',
    height: '40px',
    background: theme.palette.bgSecond.light,
    color: 'white',
  },
  buttonPrev: {
    borderRadius: '10px 0 0 10px',
    marginRight: '2px',
  },
  buttonNext: {
    borderRadius: '0 10px 10px 0',
  },
}));

export const SwiperNavButtons = ({ isBeginning, isEnd }: SwiperNavButtonsTypeProps) => {
  const swiper = useSwiper();
  const { classes } = useStyles();

  return (
    <div className={'swiper-nav-btns'}>
      <IconButton
        className={`${classes.button} ${classes.buttonPrev}`}
        disableRipple
        disabled={isBeginning}
        onClick={() => swiper.slidePrev()}
      >
        <WestIcon />
      </IconButton>
      <IconButton
        className={`${classes.button} ${classes.buttonNext}`}
        disableRipple
        disabled={isEnd}
        onClick={() => swiper.slideNext()}
      >
        <EastIcon />
      </IconButton>
    </div>
  );
};
