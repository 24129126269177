import { FC, memo, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useCompleteFreePaymentMutation } from '@pages/Order/api/orderApi.ts';
import { useNavigate } from 'react-router';
import { setPaymentMethod } from '@pages/PaymentGateway/feature/paymentMethod.ts';
import { PaymentProvider } from '@pages/Order/constants/enums.ts';
import { StripePaymentStatus } from '@pages/PaymentGateway/constants/enums/enums.ts';
import { Pages } from '@store/routes.ts';
import { useTranslation } from 'react-i18next';
import { useDispatch } from '../../../../configureStore.ts';

export const FreePayment: FC<FreePaymentPropsType> = memo(
  ({ orderId, eventSlug, createPayment, isLoadingCreatePayment }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [completeFreePayment, { isLoading }] = useCompleteFreePaymentMutation();

    useEffect(() => {
      createPayment(PaymentProvider.Free);
    }, []);

    const handleCompletePaymentClick = () => {
      if (!orderId || !eventSlug) return;
      completeFreePayment(orderId)
        .unwrap()
        .then(() => {
          dispatch(
            setPaymentMethod({
              paymentMethod: PaymentProvider.Free,
              orderId,
              date: new Date().toISOString(),
              status: StripePaymentStatus.Succeeded,
            })
          );
          navigate(`/${Pages.Event}/${eventSlug}/${Pages.Order}/${Pages.OrderResult}`, { replace: true });
        });
    };

    return (
      <Grid item container>
        <LoadingButton
          variant={'contained'}
          fullWidth
          loading={isLoading}
          onClick={handleCompletePaymentClick}
          disabled={isLoadingCreatePayment}
        >
          {t('Payment.Buyer.FinaliseTicket')}
        </LoadingButton>
      </Grid>
    );
  }
);

type FreePaymentPropsType = {
  orderId: string | null;
  eventSlug: string | null;
  createPayment: (provider: PaymentProvider) => void;
  isLoadingCreatePayment: boolean;
};
