import { Typography, Grid } from '@mui/material';
import { TextWithGradient } from '@components/common/TextWithGradient';
import { theme } from '@core/theme/theme';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { CurrencyType } from '@core/api/EventsSlice/types/types.ts';
import { PriceTypeEnum } from '@core/api/EventsSlice/types/enums.ts';

export const useStyles = makeStyles({ name: 'priceView' })((theme) => ({
  discount: {
    background: theme.palette.bgGradient.mainGradient,
    padding: '5px',
    borderRadius: '5px',
  },
}));

type PriceViewType = {
  price: { value: number; discount: number | null; priceType?: number };
  currency: string | undefined | CurrencyType | null;
  element: 'ticketGroupItemSlider' | 'ticketGroupItem' | 'activeEventGallery';
};

const elementSettings = {
  ticketGroupItemSlider: {
    withDiscount: {
      priceWithDiscount: { fontWeight: 700, fontSize: '18px' },
      price: { fontWeight: 400, fontSize: '12px', paddingLeft: 1 },
      discount: { fontWeight: 400, fontSize: '9px', marginLeft: 1 },
    },
    withoutDiscount: { fontSize: '18px', fontWeight: 700, variant: 'body2' },
    free: { fontWeight: 500, fontSize: '18px' },
    container: { alignItems: 'center', justifyContent: 'flex-end', rowGap: '12px', wrap: 'wrap' },
  },
  ticketGroupItem: {
    withDiscount: {
      priceWithDiscount: { fontWeight: 500, fontSize: '18px' },
      price: { fontWeight: 600, fontSize: '12px', paddingLeft: 1 },
      discount: { fontWeight: 400, fontSize: '9px', marginLeft: 1 },
    },
    withoutDiscount: { fontSize: '18px', fontWeight: 600, variant: 'body2' },
    free: { fontWeight: 500, fontSize: '18px' },
    container: { alignItems: 'center', justifyContent: 'flex-end', rowGap: 'normal', wrap: 'nowrap' },
  },
  activeEventGallery: {
    withDiscount: {
      priceWithDiscount: { fontWeight: 800, fontSize: '20px', paddingLeft: 1 },
      price: { fontWeight: 600, fontSize: '12px', paddingLeft: 1 },
      discount: { fontWeight: 400, fontSize: '14px', marginLeft: '10px' },
    },
    withoutDiscount: { fontWeight: 300, variant: 'caption', fontSize: '18px', paddingLeft: 1 },
    free: { fontWeight: 400, fontSize: '18px' },
    container: { alignItems: 'center', justifyContent: 'center', rowGap: 'normal', wrap: 'wrap' },
  },
};

export const PriceView = ({ price, currency, element }: PriceViewType) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const priceWithDiscount =
    (price.discount && price.discount > 0) || (price.priceType === PriceTypeEnum.From && price.discount)
      ? price.value - Math.round(price.value * price.discount)
      : price.value;
  const { withDiscount, withoutDiscount, free, container } = elementSettings[element];
  const isoCode = currency && typeof currency === 'object' ? currency.isoCode : currency;

  if (price.priceType === PriceTypeEnum.From || (price?.discount && price.discount > 0)) {
    return (
      <Grid
        container
        item
        alignItems={container.alignItems}
        direction={'row'}
        xs
        wrap={container.wrap as 'wrap' | 'nowrap'}
        justifyContent={container.justifyContent}
        rowGap={container.rowGap}
      >
        {price?.priceType === PriceTypeEnum.From && (
          <Typography
            fontWeight={600}
            component={'span'}
            variant={'caption'}
            fontSize={'18px'}
            marginRight={1}
            textTransform={'lowercase'}
          >
            {t('Dashboard.Gallery.from')}
          </Typography>
        )}
        <TextWithGradient
          fontWeight={withDiscount.priceWithDiscount.fontWeight}
          gradientColor={theme.palette.bgTextGradient.mainGradient}
          fontSize={withDiscount.priceWithDiscount.fontSize}
        >
          {`${priceWithDiscount} ${t(`General.Currency.${isoCode}`)}`}
        </TextWithGradient>
        <Typography
          fontWeight={withDiscount.price.fontWeight}
          paddingLeft={withDiscount.price.paddingLeft}
          fontSize={withDiscount.price.fontSize}
          style={{ textDecoration: 'line-through' }}
        >
          {`${price.value} ${isoCode}`}
        </Typography>
        <Typography
          fontSize={withDiscount.discount.fontSize}
          fontWeight={withDiscount.discount.fontWeight}
          className={classes.discount}
          marginLeft={withDiscount.discount.marginLeft}
        >
          {`-${price.discount && price.discount * 100}%`}
        </Typography>
      </Grid>
    );
  }
  if (price.value > 0 || price.priceType === PriceTypeEnum.Fix) {
    return (
      <Typography variant={'body2'} fontWeight={withoutDiscount.fontWeight} fontSize={withoutDiscount.fontSize}>
        {`${priceWithDiscount} ${t(`General.Currency.${isoCode}`)}`}
      </Typography>
    );
  }
  return (
    <TextWithGradient
      fontWeight={free.fontWeight}
      gradientColor={theme.palette.bgTextGradient.mainGradient}
      fontSize={free.fontSize}
    >
      {t('Payment.OrderDescription.Free').toUpperCase()}
    </TextWithGradient>
  );
};
