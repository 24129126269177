import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TimerStateType = {
  isTimerActive: boolean;
};

const initialState: TimerStateType = {
  isTimerActive: false,
};

export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setIsTimerActive(state, action: PayloadAction<boolean>) {
      return { ...state, isTimerActive: action.payload };
    },
  },
});

export const { setIsTimerActive } = timerSlice.actions;
