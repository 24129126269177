import { Grid, Skeleton } from '@mui/material';

export const SkeletonOrderDetails = () => {
  return (
    <>
      <Skeleton height={'153px'} sx={{ borderRadius: '20px' }} />
      <Grid item container gap={'2px'} direction={'column'} wrap={'nowrap'} borderRadius={'20px'} overflow={'hidden'}>
        <Grid item>
          <Skeleton height={'100px'} sx={{ borderRadius: '0px' }} />
        </Grid>
        <Grid item>
          <Skeleton height={'100px'} sx={{ borderRadius: '0px' }} />
        </Grid>
        <Grid item>
          <Skeleton height={'54px'} sx={{ borderRadius: '0px' }} />
        </Grid>
      </Grid>
    </>
  );
};
