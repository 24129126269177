import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router';
import { Content } from '@pages/Order/components/TicketSelection/components/OrderDescription/components/Content.tsx';
import { Footer } from '@pages/Order/components/TicketSelection/components/OrderDescription/components/Footer.tsx';
import { CommissionType } from '@pages/Order/types/apiTypes.ts';
import { OrderTicketsType, PaymentContextType } from '../../../../types/commonTypes.ts';
import { SectionWrapper } from '../../../common/SectionWrapper.tsx';

const useStyles = makeStyles({ name: 'OrderDescription' })((theme) => ({
  title: {
    position: 'relative',
    '&: after': {
      position: 'absolute',
      bottom: '-5px',
      left: 0,
      content: "''",
      width: '70px',
      height: '2px',
      borderRadius: '10px',
      background: theme.palette.bgGradient.mainGradient,
    },
  },
}));

export const OrderDescription: FC<OrderDescriptionPropsType> = ({
  handleNextStep,
  isLoading,
  deleteTicket,
  tickets,
  totalDiscount,
  amount,
  coins,
  commissions,
  isLoadingPayment,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isFullScreen, currency, isExistScheme, isSelectTicketsStep } = useOutletContext<PaymentContextType>();

  return (
    <SectionWrapper
      item
      container
      direction={'column'}
      padding={0}
      borderRadius={isFullScreen ? 'none' : isExistScheme && isSelectTicketsStep ? '0 0 20px 20px' : '20px'}
    >
      <Grid item container padding={'20px 20px 27px 20px'}>
        <Typography className={classes.title} variant={'body2'}>
          {t('Payment.OrderDescription.OrderDescription')}
        </Typography>
      </Grid>
      {tickets.length > 0 ? (
        <Content
          tickets={tickets}
          deleteTicket={deleteTicket}
          totalDiscount={totalDiscount}
          isoCode={currency?.isoCode}
        />
      ) : (
        <Grid marginY={10} item textAlign={'center'}>
          <Typography variant={'h3'}>{t('Payment.OrderDescription.OrderEmpty')}</Typography>
        </Grid>
      )}
      <Footer
        handleNextStep={handleNextStep}
        isSelectedTickets={!!tickets.length}
        isLoading={isLoading}
        totalAmount={amount}
        coins={coins}
        commissions={commissions}
        isLoadingPayment={isLoadingPayment}
      />
    </SectionWrapper>
  );
};

type OrderDescriptionPropsType = {
  tickets: OrderTicketsType[];
  amount: number;
  handleNextStep?: () => void;
  isLoading?: boolean;
  deleteTicket?: (id: string, ticketGroup: boolean) => void;
  totalDiscount?: number;
  coins?: number;
  commissions?: CommissionType[];
  isLoadingPayment?: boolean;
};
