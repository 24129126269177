import { createTheme } from '@mui/material';
import { defaultTheme } from '@core/theme/themeOptions/defaultTheme.ts';
import { ThemePaletteColor } from '@core/theme/themeTypes.ts';
import { BackgroundIconGradient } from './themeTypes';
import '../../globalStyles/variables.css';
import { typography } from './themeOptions/typography';

const { sm } = defaultTheme.breakpoints.values;
const { down } = defaultTheme.breakpoints;

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    bg: ThemePaletteColor;
    bgSecond: ThemePaletteColor;
    bgTitle: ThemePaletteColor;
    bgGradient: ThemePaletteColor;
    bgGradientSecond: ThemePaletteColor;
    bgTextGradient: ThemePaletteColor;
    bgIconGradient: BackgroundIconGradient;
    bgProfiles: ThemePaletteColor;
    subtle: ThemePaletteColor;
    borders: ThemePaletteColor;
    occupiedIcon: ThemePaletteColor;
    freeIcon: ThemePaletteColor;
    sharedIcon: ThemePaletteColor;
    conferenceIcon: ThemePaletteColor;
    occupiedPlace: ThemePaletteColor;
    freePlace: ThemePaletteColor;
    reservedPlace: ThemePaletteColor;
    sharedPlace: ThemePaletteColor;
    conferencePlace: ThemePaletteColor;
    borderGradient: ThemePaletteColor;

    [index: string]: ThemePaletteColor;
  }

  interface PaletteOptions {
    bg?: ThemePaletteColor;
    bgSecond?: ThemePaletteColor;
    bgTitle?: ThemePaletteColor;
    bgGradient?: ThemePaletteColor;
    bgGradientSecond?: ThemePaletteColor;
    bgTextGradient?: ThemePaletteColor;
    bgIconGradient?: BackgroundIconGradient;
    bgProfiles?: ThemePaletteColor;
    subtle?: ThemePaletteColor;
    borders?: ThemePaletteColor;
    occupiedIcon?: ThemePaletteColor;
    freeIcon?: ThemePaletteColor;
    sharedIcon?: ThemePaletteColor;
    conferenceIcon?: ThemePaletteColor;
    occupiedPlace?: ThemePaletteColor;
    freePlace?: ThemePaletteColor;
    reservedPlace?: ThemePaletteColor;
    sharedPlace?: ThemePaletteColor;
    conferencePlace?: ThemePaletteColor;
    borderGradient?: ThemePaletteColor;
  }
}

const PRIMARY = {
  main: '#000000',
  light: '#ffffff',
  contrastText: '#707893',
};
const SECONDARY = {
  main: '#E5427D',
  contrastText: '#fff',
  light: '#465586',
  dark: '#1C274C',
};
const BACKGROUND = {
  main: '#F6F8FC',
  dark: '#25325D',
  light: '#3D496F',
};
const SECOND_BACKGROUND = {
  main: '#fff',
  dark: '#1C274C',
  light: '#2C3C71',
  contrastText: '#1294F2',
};
const SUBTLE = {
  main: '#E5427D',
  dark: '#767676',
};
const BORDERS_LINES = {
  main: '#3D496F',
  outline: '#F59E93',
  disabledOutline: '#FACFC9',
};
const ERROR = {
  main: '#d32f2f',
  light: '#ef5350',
  dark: '#c62828',
  contrastText: '#fff',
};

const BACKGROUND_GRADIENT = {
  main: '#000000',
  mainGradient: 'linear-gradient(90deg, rgb(229, 66, 125), rgb(159, 74, 225))',
  dark: '#7642E5',
  lightGradient: 'linear-gradient(90deg, #30D664 0%, #2EC4CE 100%)',
};

const SECOND_BACKGROUND_GRADIENT = {
  main: '#000000',
  mainGradient: 'linear-gradient(90deg, rgba(229, 66, 125, 0.20) 0%, rgba(159, 74, 225, 0.20) 103.91%)',
};

const BORDER_GRADIENT = {
  main: '#000000',
  mainGradient:
    'linear-gradient(90deg, #E5427D, #9F4AE1) padding-box, linear-gradient(90deg, #E5427D, #9F4AE1) border-box',
  lightGradient: 'linear-gradient(#25325D, #25325D) padding-box, linear-gradient(90deg, #E5427D, #9F4AE1) border-box',
};

const BACKGROUND_TEXT_GRADIENT = {
  main: '#000000',
  mainGradient: 'linear-gradient(90deg, #30D664 0%, #2EC4CE 100%)',
  darkGradient: 'linear-gradient(90deg, #E5427D 0%, #9F4AE1 100%)',
};

const GRADIENT_FOR_ICONS = {
  mainGradient: ['#30D664', '#2EC4CE'] as [string, string],
  darkGradient: ['#E5427D', '#E5427D'] as [string, string],
  disabled: ['#707893', '#707893'] as [string, string],
};

export const theme = createTheme({
  palette: {
    primary: PRIMARY,
    secondary: SECONDARY,
    bgGradient: BACKGROUND_GRADIENT,
    bgGradientSecond: SECOND_BACKGROUND_GRADIENT,
    bg: BACKGROUND,
    bgSecond: SECOND_BACKGROUND,
    subtle: SUBTLE,
    bgTextGradient: BACKGROUND_TEXT_GRADIENT,
    bgIconGradient: GRADIENT_FOR_ICONS,
    borders: BORDERS_LINES,
    error: ERROR,
    borderGradient: BORDER_GRADIENT,
  },
  typography,
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: '#1C1C1C',
          ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          borderRadius: '40px',
          background: '#25325D',
          textTransform: 'none',
          minHeight: '44px',
          padding: '6px 20px',
          ':hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          boxShadow: 'none',
          background:
            'linear-gradient(90deg, var(--buttonGradientColor1) 0%, var(--buttonGradientColor2) 100%) border-box',
          transition: '--buttonGradientColor1 0.3s ease, --buttonGradientColor2 0.3s ease',
          ':hover': {
            '--buttonGradientColor1': 'rgb(197, 57, 108)',
            '--buttonGradientColor2': 'rgb(128, 59, 181)',
          },
          ':disabled': {
            color: '#A4A4A4',
            background:
              'linear-gradient(0deg, rgba(28, 39, 76, 0.30) 0%, rgba(28, 39, 76, 0.30) 100%), linear-gradient(90deg, #E5427D 0%, #9F4AE1 103.91%)',
            border: 'none',
          },
        },
        outlined: {
          position: 'relative',
          color: 'white',
          height: '40px',
          borderRadius: '30px',
          cursor: 'default',
          border: 'none',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 'inherit',
            border: '2px solid transparent',
            transition: '1s',
            background: `${BACKGROUND_TEXT_GRADIENT.darkGradient} border-box`,
            WebkitMask: `${BACKGROUND_TEXT_GRADIENT.darkGradient} padding-box, ${BACKGROUND_TEXT_GRADIENT.darkGradient}`,
            WebkitMaskComposite: 'destination-out',
            maskComposite: 'exclude',
            zIndex: 0,
          },
          ':hover': {
            border: 'none',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#25325D',
          transform: 'scale(1, 1)',
        },
        rounded: {
          borderRadius: '1.5rem',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '40px',
          background: '#2C3C71',
          borderColor: 'transparent',
          color: '#707893',
          padding: '10px',
          textTransform: 'capitalize',
          '&.Mui-selected': {
            color: '#ffffff !important',
            background:
              'linear-gradient(90deg, var(--buttonGradientColor1) 0%, var(--buttonGradientColor2) 100%) border-box',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minHeight: '44px',
          boxSizing: 'border-box',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: '44px',
          boxSizing: 'border-box',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-0.5rem',
          [down(sm)]: {
            top: '-0.3rem !important',
          },
        },
        shrink: {
          top: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          minHeight: '44px',
          boxSizing: 'border-box',
          padding: '8px 16px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          right: '-8px',
          background: BACKGROUND_TEXT_GRADIENT.darkGradient,
        },
        dot: {
          minHeight: '7px',
          height: '7px',
          minWidth: '7px',
        },
      },
    },
  },
});
