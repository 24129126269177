import React, { FC } from 'react';
import { OrderTicketsType } from '@pages/Order/types/commonTypes.ts';
import { OrderItem } from '@pages/Order/components/TicketSelection/components/OrderItem.tsx';
import { Box, Collapse, Divider, Grid, Typography } from '@mui/material';
import { TextWithGradient } from '@components/common/TextWithGradient.tsx';
import { theme } from '@core/theme/theme.ts';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';

const useStyles = makeStyles({ name: 'Content' })((theme) => ({
  divider: {
    borderColor: theme.palette.bgSecond.dark,
    borderBottomWidth: '1.6px',
  },
  discountWrapper: {
    backgroundColor: theme.palette.bgSecond.light,
    minHeight: '45px',
    alignItems: 'center',
    padding: '0 20px',
  },
  discount: {
    background: theme.palette.bgGradient.mainGradient,
    padding: '5px',
    borderRadius: '5px',
  },
}));

export const Content: FC<TicketsPropsType> = ({ tickets, deleteTicket, isoCode, totalDiscount = 0 }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Grid item container direction={'column'} paddingX={'20px'}>
        <TransitionGroup>
          {tickets.map((order) => (
            <Collapse key={order.id}>
              <OrderItem
                title={order.title}
                price={order.price}
                discount={order.discount}
                sector={order.sector}
                count={order.count}
                id={order.id}
                deleteTicket={deleteTicket}
                row={order.row}
                seat={order.seat}
              />
            </Collapse>
          ))}
        </TransitionGroup>
      </Grid>
      {totalDiscount > 0 && (
        <Grid item container justifyContent={'space-between'} className={classes.discountWrapper}>
          <Typography fontWeight={400} variant={'body1'}>
            {t('Payment.OrderDescription.Discount')}
          </Typography>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <TextWithGradient
              fontWeight={500}
              variant={'body1'}
              gradientColor={theme.palette.bgTextGradient.mainGradient}
            >
              {`-${totalDiscount} ${t(`General.Currency.${isoCode}`).toUpperCase()}`}
            </TextWithGradient>
            <Typography className={classes.discount} variant={'caption'}>
              {`-${totalDiscount && totalDiscount * 100}%`}
            </Typography>
          </Box>
        </Grid>
      )}
      <Divider className={classes.divider} />
    </>
  );
};

type TicketsPropsType = {
  tickets: OrderTicketsType[];
  deleteTicket?: (id: string, ticketGroup: boolean) => void;
  totalDiscount?: number;
  isoCode?: string;
};
