import { apiSlice } from '@core/api/apiSlice';
import { DELETE_METHOD, POST_METHOD } from '@core/constants/http-method-constants';
import { ResSpecificEventType, ResEventParticipantType, ResReferralLinkType, ReqReferralLink } from '../types/apiTypes';
import { Routes } from '../constants/routes';

const EventDetailsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpecificEvent: builder.query<ResSpecificEventType, string>({
      query: (data) => {
        return {
          url: `${Routes.SpecificEvent}/${data}`,
        };
      },
      providesTags: (result, error, id) => [{ type: 'EventDetails', id }],
    }),
    getEventParticipants: builder.query<ResEventParticipantType[], string>({
      query: (data) => {
        return {
          url: `${Routes.SpecificEvent}/${data}${Routes.EventParticipants}`,
        };
      },
    }),
    addToFavourites: builder.mutation<null, string>({
      query: (data) => {
        return {
          method: POST_METHOD,
          url: `${Routes.EventFavourite}?eventSlug=${data}`,
        };
      },
      invalidatesTags: ['EventDetails'],
    }),
    removeFromFavourites: builder.mutation<null, string>({
      query: (data) => {
        return {
          method: DELETE_METHOD,
          url: `${Routes.EventFavourite}?eventSlug=${data}`,
        };
      },
      invalidatesTags: ['EventDetails'],
    }),
    getReferralLink: builder.query<ResReferralLinkType, ReqReferralLink>({
      query: ({ slug, host }) => {
        return {
          url: `${Routes.SpecificEvent}/${Routes.ReferralLink}`,
          params: { slug, host },
        };
      },
    }),
  }),
});

export const {
  useGetSpecificEventQuery,
  useGetEventParticipantsQuery,
  useAddToFavouritesMutation,
  useRemoveFromFavouritesMutation,
  useGetReferralLinkQuery,
  reducerPath: eventDetailsReducerPath,
} = EventDetailsApi;
