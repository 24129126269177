import { Suspense } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme } from '@core/theme/theme.ts';
import { Router } from '@core/router/Router.tsx';
import { Box } from '@mui/material';
import AppLoader from '@components/AppLoader/AppLoader';
import { useBeforeUnload } from 'react-router-dom';
import { deAuthorize } from '@store/auth/actions.ts';
import { clearUser } from '@store/user/actions.ts';
import { AppDeAuthorize } from '@store/app/actions.ts';
import { useDispatch, useSelector } from './configureStore.ts';

const Fallback = () => {
  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <AppLoader />
    </Box>
  );
};

export const App = () => {
  const dispatch = useDispatch();
  const { isRememberMe } = useSelector((state) => state.user);
  const { isAuthorized } = useSelector((state) => state.app);

  useBeforeUnload(() => {
    if (isAuthorized && isRememberMe) return;

    dispatch(deAuthorize());
    dispatch(clearUser());
    dispatch(AppDeAuthorize());
  });

  return (
    <Suspense fallback={<Fallback />}>
      <MuiThemeProvider theme={theme}>
        <Router />
      </MuiThemeProvider>
    </Suspense>
  );
};
