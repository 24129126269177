import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from './types';

const initialState: UserType = {
  userId: null,
  email: null,
  role: null,
  firstName: null,
  secondName: null,
  isRememberMe: false,
  acceptCookies: false,
};

export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    userInitiate: (_state, action: PayloadAction<UserType>) => {
      return action.payload;
    },
    clearUser: (state) => {
      return { ...initialState, acceptCookies: state.acceptCookies };
    },
    setAcceptCookies: (state, action: PayloadAction<boolean>) => {
      state.acceptCookies = action.payload;
    },
  },
});
