import React, { useEffect, useRef } from 'react';
import { useGesture } from '@use-gesture/react';
import { Button, Divider, Theme } from '@mui/material';
import { animated, to, useSpring } from '@react-spring/web';
import { makeStyles } from 'tss-react/mui';
import { ZoomOut } from '@icons/ZoomOut.tsx';
import { ZoomIn } from '@icons/ZoomIn.tsx';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    height: '100%',
    touchAction: 'none',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserDrag: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  wrapperContainer: {
    overflow: 'hidden',
    height: 'inherit',
    width: '100%',
    position: 'relative',
  },
  buttonGroup: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    right: 0,
    top: 0,
  },
  buttonOutZoom: {
    background: 'rgba(61, 86, 169, 0.15)',
    width: '60px',
    height: '60px',
    borderRadius: '0px 0px 50px 50px',
    transition: 'background .3s ease-out',
    '&:hover': {
      background: 'rgba(61, 86, 169, 0.25)',
    },
  },
  buttonInZoom: {
    background: 'rgba(61, 86, 169, 0.15)',
    width: '60px',
    height: '60px',
    borderRadius: '50px 50px 0px 0px',
    transition: 'background .3s ease-out',
    '&:hover': {
      background: 'rgba(61, 86, 169, 0.25)',
    },
  },
}));

const PanAndZoomImage = ({ children }) => {
  const { classes } = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  const ZOOM_FACTOR = 0.1;
  const MAX_ZOOM = 3;
  const MIN_ZOOM = 0.5;

  useEffect(() => {
    const handler = (e: any) => e.preventDefault();
    document.addEventListener('gesturestart', handler);
    document.addEventListener('gesturechange', handler);
    document.addEventListener('gestureend', handler);

    return () => {
      document.removeEventListener('gesturestart', handler);
      document.removeEventListener('gesturechange', handler);
      document.removeEventListener('gestureend', handler);
    };
  }, []);

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    config: {
      mass: 0,
      tension: 0,
      friction: 0,
      clamp: true,
      duration: 100,
      damping: 0,
    },
  }));

  const transform = to([style.x, style.y, style.scale], (x, y, scale) => `translate(${x}px, ${y}px) scale(${scale})`);

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
        if (pinching) return cancel();
        api.start({ x, y });
      },
      onPinch: ({ origin: [ox, oy], first, offset: [d], memo }) => {
        if (first) {
          if (!ref.current) return;
          const { width, height, x, y } = ref.current.getBoundingClientRect();
          const tx = ox - (x + width / 2);
          const ty = oy - (y + height / 2);
          memo = [style.x.get(), style.y.get(), tx, ty];
        }

        api.start({ scale: d });
        return memo;
      },
    },
    {
      target: ref,
      drag: {
        bounds: { top: -200, right: 200, bottom: 200, left: -200 },
      },
      pinch: {
        scaleBounds: { min: MIN_ZOOM, max: MAX_ZOOM },
        rubberband: true,
        pointer: { touch: true },
      },
    }
  );

  const handleInZoom = () => {
    if (style.scale.get() >= MAX_ZOOM) {
      return;
    }

    api.set({ scale: style.scale.get() + ZOOM_FACTOR });
  };

  const handleOutZoom = () => {
    if (style.scale.get() <= MIN_ZOOM) {
      return;
    }

    api.set({ scale: style.scale.get() - ZOOM_FACTOR });
  };

  return (
    <div className={classes.wrapperContainer}>
      <div className={classes.buttonGroup}>
        <Button className={classes.buttonInZoom} onClick={handleInZoom}>
          <ZoomIn />
        </Button>
        <Divider sx={{ backgroundColor: 'rgba(28, 39, 76, 1)' }} />
        <Button className={classes.buttonOutZoom} onClick={handleOutZoom}>
          <ZoomOut />
        </Button>
      </div>
      <animated.div className={classes.container} ref={ref} style={{ transform }}>
        {children}
      </animated.div>
    </div>
  );
};

export default PanAndZoomImage;
