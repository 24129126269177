import React, { FC } from 'react';
import { Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { DangerTriangle } from '@icons/DangerTriangle.tsx';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { BitcoinIcon } from '@icons/BitcoinIcon.tsx';
import { CommissionType } from '@pages/Order/types/apiTypes.ts';
import { Commissions } from '@pages/Order/components/TicketSelection/components/OrderDescription/components/Commissions.tsx';
import { SkeletonCommissions } from '@pages/Order/components/TicketSelection/components/OrderDescription/components/SkeletonCommissions.tsx';

export const Footer: FC<FooterPropsType> = ({
  handleNextStep,
  isSelectedTickets,
  isLoading,
  totalAmount,
  isoCode,
  coins,
  commissions = [],
  isLoadingPayment,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Grid container item flexDirection={'column'} p={'20px'}>
      {isSelectedTickets && (
        <Grid item container flexDirection={'column'} wrap={'nowrap'} gap={'20px'} paddingBottom={'20px'}>
          {commissions && commissions.length > 0 && <Commissions commissions={commissions} />}
          {isLoadingPayment && <SkeletonCommissions />}
          {!isLoadingPayment && commissions.length === 0 && totalAmount > 0 && (
            <Grid item container gap={'7px'}>
              <DangerTriangle />
              <Typography fontWeight={400} variant={'caption'} color={'primary.contrastText'}>
                {t('Payment.OrderDescription.Attention')}
              </Typography>
            </Grid>
          )}

          <Grid item container direction={'row'} alignItems={'center'} height={'44px'}>
            <Grid item xs>
              <Typography fontWeight={500} variant={'body2'}>
                {t('Payment.OrderDescription.Summary')}
              </Typography>
            </Grid>
            <Grid item xs={'auto'} container direction={'column'} alignItems={'flex-end'} rowGap={1}>
              <Grid item>
                {isLoadingPayment ? (
                  <Skeleton width={90} height={21.6} sx={{ backgroundColor: palette.bgSecond.light }} />
                ) : (
                  <Typography fontWeight={500} variant={'body2'}>
                    {totalAmount > 0 && `${totalAmount} ${t(`General.Currency.${isoCode}`)}`}
                    {totalAmount === 0 && t('EventCard.Free')}
                  </Typography>
                )}
              </Grid>
              {totalAmount > 0 && coins && coins > 0 && (
                <Grid item container direction={'row'} columnGap={1} alignItems={'center'}>
                  <BitcoinIcon />
                  <Typography variant={'caption'}>{`+${t('Payment.OrderDescription.CoinsCount', {
                    coins,
                  })}`}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {handleNextStep && (
        <Grid item xs>
          <LoadingButton
            onClick={handleNextStep}
            loading={isLoading}
            variant={'contained'}
            disabled={!isSelectedTickets || isLoading || isLoadingPayment}
            fullWidth
          >
            {t('Payment.OrderDescription.Next')}
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};

type FooterPropsType = {
  totalAmount: number;
  handleNextStep?: () => void;
  isLoading?: boolean;
  isSelectedTickets?: boolean;
  isoCode?: string;
  coins?: number;
  commissions?: CommissionType[];
  isLoadingPayment?: boolean;
};
