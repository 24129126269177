import { Box, Grid, Typography } from '@mui/material';
import { PlaceSelectorIcon } from '@components/icons/PlaceSelectorIcon';
import { RampIcon } from '@components/icons/RampIcon';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { TicketZoneType } from '@core/models/payment/enums/enums.ts';
import { TicketType } from '../types/apiTypes';

const ticketZones: Record<Exclude<TicketZoneType, TicketZoneType.None>, string> = {
  [TicketZoneType.Children]: 'Детский билет',
  [TicketZoneType.VIP]: 'VIP',
  [TicketZoneType.Standard]: 'Стандартный',
};

const useStyles = makeStyles<{ isProcessed: boolean }>({ name: 'TicketItem' })((theme, { isProcessed }) => ({
  ticket: {
    position: 'relative',
    background: theme.palette.bg.dark,
    padding: '15px 20px',
    cursor: isProcessed ? 'default' : 'pointer',
    '& p': {
      color: isProcessed ? theme.palette.primary.contrastText : 'inherit',
    },
  },
  rampAvailability: {
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.primary.contrastText,
  },
  titlePlace: {
    display: 'flex',
    gap: '15px',
  },
}));

interface TicketItemProps {
  ticket: TicketType;
  isProcessed: boolean;
  onClick?: () => void;
  className?: string;
}

export const TicketItem = ({ ticket, onClick, isProcessed, className = '' }: TicketItemProps) => {
  const { seat, row, sector, wheelchairRamp, type } = ticket;
  const { t } = useTranslation();
  const { classes } = useStyles({ isProcessed });

  return (
    <Grid container className={`${classes.ticket} ${className}`} onClick={onClick}>
      <Grid container item gap={3}>
        <Grid xs item container direction={'column'} gap={'20px'}>
          <Grid item container alignItems={'center'} gap={'15px'}>
            <PlaceSelectorIcon />
            <Box className={classes.titlePlace}>
              {sector && (
                <Typography variant={'body2'}>{`${sector} ${t('Payment.OrderDescription.Sector')}`}</Typography>
              )}
              {row && <Typography variant={'body2'}>|</Typography>}
              {row && <Typography variant={'body2'}>{`${row} ${t('Payment.OrderDescription.Row')}`}</Typography>}
              {seat && <Typography variant={'body2'}>|</Typography>}
              {seat && <Typography variant={'body2'}>{`${seat} ${t('Payment.OrderDescription.Seat')}`}</Typography>}
            </Box>
          </Grid>
          <Grid item container gap={'10px'}>
            <Grid item>
              <Typography variant={'body2'}>{ticketZones[type]}</Typography>
            </Grid>
            {wheelchairRamp && (
              <Grid item container alignItems={'center'} xs>
                <RampIcon />
                <Typography className={classes.rampAvailability} variant={'caption'}>
                  {t('Booking.RampAvailability')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
