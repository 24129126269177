import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthStateType } from './types';

const initialState: AuthStateType = {
  accessToken: null,
  expiration: null,
  userId: null,
  refreshToken: null,
};

const authReducer = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    authorize: (state, action: PayloadAction<AuthStateType>) => {
      return { ...action.payload };
    },
    deAuthorize: () => {
      return initialState;
    },
  },
});

export default authReducer;
