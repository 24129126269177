import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { PaymentContextType } from '../../../types/commonTypes.ts';

const useStyles = makeStyles({ name: 'OrderDescription' })((theme) => ({
  deletBtn: {
    borderRadius: '50%',
    minWidth: '20px',
    minHeight: '20px',
    background: theme.palette.bgSecond.light,
    boxSizing: 'border-box',
    padding: '0',
    marginLeft: '20px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: '14px',
      transform: 'rotate(45deg)',
      top: '3px',
      left: '9.5px',
      background: 'white',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: '14px',
      transform: 'rotate(-45deg)',
      top: '3px',
      left: '9.5px',
      background: 'white',
    },
  },
}));

type OrderItemPropsType = {
  title: string;
  price: number;
  discount: number;
  count: number;
  sector: string;
  row: string | null;
  seat: string | null;
  id: string;
  deleteTicket?: (id: string, ticketGroup: boolean) => void;
};

export const OrderItem = ({
  title,
  price,
  discount,
  count,
  sector,
  row,
  seat,
  id,
  deleteTicket,
}: OrderItemPropsType) => {
  const { currency } = useOutletContext<PaymentContextType>();
  const { t } = useTranslation();
  const priceWithDiscount = discount > 0 ? price - price * discount : price;
  const { classes } = useStyles();

  return (
    <Grid item xs container direction={'row'} paddingY={'16px'}>
      <Grid item xs>
        <Typography fontWeight={400} variant={'body1'}>
          {row && seat
            ? `${row} ${t('Payment.OrderDescription.Row')} | ${seat} ${t('Payment.OrderDescription.Seat')}`
            : `${title || sector}`}
        </Typography>
      </Grid>
      <Grid item xs={'auto'} container direction={'column'} alignItems={'flex-end'}>
        <Grid item>
          <Typography fontWeight={500} variant={'body1'}>
            {currency?.isoCode
              ? `${count} x ${priceWithDiscount} ${t(
                  `General.Currency.${currency?.isoCode ? currency.isoCode : ''}`
                ).toUpperCase()}`
              : priceWithDiscount}
          </Typography>
        </Grid>
      </Grid>
      {deleteTicket && <Button className={classes.deletBtn} onClick={() => deleteTicket(id, !(row && seat))} />}
    </Grid>
  );
};
