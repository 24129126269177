import React, { memo, useCallback } from 'react';
import { useOutletContext, useParams } from 'react-router';
import { useDispatch, useSelector } from 'src/configureStore.ts';
import { useTranslation } from 'react-i18next';
import { TicketSelection } from './components/TicketSelection/TicketSelection.tsx';
import { Buyer } from './components/Buyer/Buyer.tsx';
import { ChoicePaymentMethod } from './components/ChoicePaymentMethod/ChoicePaymentMethod.tsx';
import { useEventOrderQuery } from './api/orderApi.ts';
import { setPaymentStep } from './features/orderSlice.ts';
import { PaymentContextType } from './types/commonTypes.ts';
import { Steps } from './constants/enums.ts';

export const Order = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { eventSlug = '' } = useParams<{ eventSlug: string }>();
  const { data: order, isLoading } = useEventOrderQuery(eventSlug);
  const { paymentStep } = useSelector((store) => store.payment);
  const { isAuthorized } = useSelector((store) => store.app);

  const { eventId } = useOutletContext<PaymentContextType>();

  const steps = [
    `${t('Payment.Stepper.TicketSelection')}`,
    `${t('Payment.Stepper.Byuer')}`,
    `${t('Payment.Stepper.Order')}`,
  ];

  const handleNextStep = useCallback(() => {
    if (paymentStep === steps.length - 1) return;
    if (isAuthorized) {
      dispatch(setPaymentStep(Steps.Payment));
      return;
    }
    dispatch(setPaymentStep(paymentStep + 1));
  }, [paymentStep]);

  return (
    <>
      {
        {
          [Steps.TicketSelection]: (
            <TicketSelection
              isLoading={isLoading}
              tickets={order?.tickets ?? []}
              ticketGroups={order?.ticketGroups ?? []}
              slug={eventSlug}
              handleNextStep={handleNextStep}
              scheme={order?.scheme}
            />
          ),
          [Steps.Buyer]: <Buyer handleNextStep={handleNextStep} eventId={eventId ?? ''} />,
          [Steps.Payment]: <ChoicePaymentMethod />,
        }[paymentStep]
      }
    </>
  );
});
