import { apiSlice } from '@core/api/apiSlice';
import { Events } from '@core/constants/constants';
import { ResponseGroupEventType } from '../types/apiTypes';

const EventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupEvents: builder.query<ResponseGroupEventType[], null>({
      query: () => {
        return {
          url: Events.Groups,
        };
      },
    }),
  }),
});

export const { useGetGroupEventsQuery, reducerPath: eventsGroupReducerPath } = EventsApi;
