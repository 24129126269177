import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import { combineReducers, configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { PersistedState } from 'redux-persist/es/types';
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useSelectorRedux } from 'react-redux';
import { PagesRoutes } from '@store/routes.ts';
import { AlertType } from '@store/app/constants.ts';
import { filterEventsReducer } from '@store/filterEvents/reducers.ts';
import { setIsTimerActive, timerSlice } from '@components/CountdownTimer/feature/timerSlice.ts';
import { authReducerPath } from '@core/api/AuthSlice/authSlice.ts';
import { eventsReducerPath } from '@core/api/EventsSlice/EventsSlice.ts';
import { eventsGroupReducerPath } from '@pages/Dashboard/api/Events.ts';
import { eventDetailsReducerPath } from '@pages/EventDetails/api/EventDetailsApi.ts';
import { orderReducerPath } from '@pages/Order/api/orderApi.ts';
import { orderResultReducerPath } from '@pages/OrderResult/api/bookingApi.ts';
import { usersReducerPath } from '@core/api/UserSlice/userSlice.ts';
import { profileReducerPath } from '@pages/Profile/api/profileSlice.ts';
import { priceReducerPath } from '@core/api/PriceSlice/priceSlice.ts';
import authReducer from './store/auth/reducers.ts';
import locationReducer from './store/location/reducers';
import appReducer from './store/app/reducers';
import { apiSlice } from './core/api/apiSlice.ts';
import { KeyErrors } from './store/enums';
import { setAlertData, setKeyError, setRouteForReload } from './store/app/actions';
import { userReducer } from './store/user/reducers';
import {
  clearOrder,
  isShowStepper,
  paymentSlice,
  setConnectionId,
  setCountDownTimer,
  setPaymentStep,
} from './pages/Order/features/orderSlice.ts';
import { breadcrumbsSlice } from './store/breadcrumbs/breadcrumbsSlice.tsx';
import { store } from './main.tsx';
import { paymentMethodSlice } from './pages/PaymentGateway/feature/paymentMethod.ts';

const migrations: any = {
  0: (state: PersistedState & GlobalStateType) => ({ ...state }),
};

const persistConfig: any = {
  key: 'babimbo',
  storage,
  stateReconciler: autoMergeLevel2,
  version: 3,
  migrate: createMigrate(migrations, { debug: true }),
  whitelist: ['app', 'auth', 'user', 'breadcrumbs', 'payment'],
  blacklist: [
    apiSlice.reducerPath,
    authReducerPath,
    eventsReducerPath,
    eventsGroupReducerPath,
    eventDetailsReducerPath,
    orderReducerPath,
    orderResultReducerPath,
    usersReducerPath,
    profileReducerPath,
    priceReducerPath,
  ],
};

const rootReducer = combineReducers({
  filterEvents: filterEventsReducer.reducer,
  app: appReducer.reducer,
  auth: authReducer.reducer,
  location: locationReducer.reducer,
  user: userReducer.reducer,
  payment: paymentSlice.reducer,
  breadcrumbs: breadcrumbsSlice.reducer,
  paymentMethod: paymentMethodSlice.reducer,
  timer: timerSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);

export const rtkQueryServerError: Middleware =
  ({ dispatch, getState }: MiddlewareAPI<AppDispatch, GlobalStateType>) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 500 && location.pathname !== PagesRoutes.ServerError) {
      dispatch(setRouteForReload(location.pathname));
      dispatch(setKeyError(KeyErrors.ServerError));

      if (getState().payment.order.orderId) {
        dispatch(setCountDownTimer(0));
        dispatch(setIsTimerActive(false));
        dispatch(setConnectionId(null));
        dispatch(isShowStepper(true));
        dispatch(setPaymentStep(0));
        dispatch(clearOrder());
      }
    }

    return next(action);
  };

export const rtkQueryBadRequest: Middleware =
  ({ dispatch }: MiddlewareAPI<AppDispatch>) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status >= 400 && action.payload.status < 500) {
      const message = action.payload.data?.errorMessage ?? 'Something went wrong';

      dispatch(setAlertData({ message, type: AlertType.Error }));
    }

    return next(action);
  };

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        immutableCheck: false,
      }).concat(rtkQueryServerError, rtkQueryBadRequest, apiSlice.middleware),
    devTools: process.env.NODE_ENV !== 'production',
  });

  const persistor = persistStore(store);
  return { store, persistor };
};

export type GlobalStateType = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<GlobalStateType> = useSelectorRedux;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
