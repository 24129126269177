import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { PaymentIcon } from '@icons/PaymentIcon.tsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'src/configureStore.ts';
import { useCreatePaymentMutation, useGetPaymentProvidersQuery } from '@pages/Order/api/orderApi.ts';
import { ChoicePaymentSkeleton } from '@pages/Order/components/ChoicePaymentMethod/ChoicePaymentSkeleton.tsx';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { PaymentProvider } from '@pages/Order/constants/enums.ts';
import { FreePayment } from '@pages/Order/components/FreePayment/FreePayment.tsx';
import { StripePaymentForm } from '@pages/Order/components/StripePaymentForm/StripePaymentForm.tsx';
import { useCalculateTickets } from '@pages/Order/helpers/useCalculateTickets.ts';
import { updateOrder } from '@pages/Order/features/orderSlice.ts';
import { CreatePaymentResType } from '@pages/Order/types/apiTypes.ts';
import { RevolutPaymentForm } from '../RevolutPaymentForm/RevolutPaymentForm.tsx';
import { OrderDescription } from '../TicketSelection/components/OrderDescription/OrderDescription.tsx';
import { BuyerWrapper } from '../Buyer/components/BuyerWrapper.tsx';

const useStyles = makeStyles({ name: 'ChoicePaymentMethod' })((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: 'min-content',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  item: {
    overflow: 'hidden',
  },
  short: {
    gridRow: 'span 1',
  },
  tell: {
    gridRow: 'span 2',
    [theme.breakpoints.up('lg')]: {
      gridRow: 'span 1',
    },
  },
  paymentButton: {
    position: 'relative',
    overflow: 'hidden',
    background: theme.palette.secondary.dark,
  },
  activeMethod: {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: 'inherit',
      border: '2px solid transparent',
      background: theme.palette.borderGradient.mainGradient,
      WebkitMask:
        'linear-gradient(90deg, #E5427D 0%, #9F4AE1 100%) padding-box, linear-gradient(90deg, #E5427D 0%, #9F4AE1 100%)',
      WebkitMaskComposite: 'destination-out',
      maskComposite: 'exclude',
      zIndex: 0,
    },
  },
}));

type PaymentMethodType = {
  title: string;
  icon: ReactElement;
};

const methods: Partial<{ [key: number]: PaymentMethodType }> = {
  [PaymentProvider.Stripe]: {
    title: 'Payment.Buyer.CardPay',
    icon: <PaymentIcon />,
  },
  [PaymentProvider.Revolut]: {
    title: 'Payment.Buyer.Revolut',
    icon: <PaymentIcon />,
  },
  [PaymentProvider.Free]: {
    title: 'Payment.Buyer.Free',
    icon: <MonetizationOnIcon />,
  },
};

export const ChoicePaymentMethod = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const { tickets, ticketGroups } = useSelector((state) => state.payment.order.items);
  const { slug, orderId, isoCode } = useSelector((state) => state.payment.order);

  const [selectMethod, setSelectMethod] = useState<PaymentProvider | null>(null);
  const { tickets: selectedTickets, totalCostWithoutFee } = useCalculateTickets(tickets, ticketGroups);

  const {
    data: paymentProviders = { providers: [] },
    isLoading,
    isSuccess,
  } = useGetPaymentProvidersQuery(slug as string, { skip: !slug });
  const [paymentCreate, { data, isLoading: isLoadingCreatePayment }] = useCreatePaymentMutation();

  const createPayment = useCallback(
    (provider: PaymentProvider) => {
      if (!orderId || !isoCode) return;
      paymentCreate({
        paymentMethod: provider,
        orderId,
        amount: totalCostWithoutFee,
        currency: isoCode,
        returnUrl: window.location.href,
      })
        .unwrap()
        .then(({ userEmail }) => {
          dispatch(updateOrder(userEmail));
        });
    },
    [orderId, totalCostWithoutFee, isoCode]
  );

  useEffect(() => {
    if (!paymentProviders.providers.length) return;
    const defaultSelectMethod =
      paymentProviders.providers.find((item) => item === PaymentProvider.Stripe) ?? paymentProviders.providers[0];
    setSelectMethod(defaultSelectMethod);
  }, [isSuccess]);

  const handleSelectMethodClick = (index: number) => {
    setSelectMethod(index);
  };

  return (
    <Grid container columnGap={'20px'} rowGap={'20px'} className={classes.container}>
      <Grid item className={cx([classes.item, classes.short])}>
        <BuyerWrapper
          title={`${t('Payment.Buyer.PaymentVariant')}`}
          subtitle={`${t('Payment.Buyer.PaymentVariantList')}`}
        >
          <Grid item container direction={'column'} rowGap={2.5}>
            {isLoading && <ChoicePaymentSkeleton />}
            {!isLoading &&
              paymentProviders.providers.length > 0 &&
              paymentProviders.providers.map((item, index) => {
                const method: PaymentMethodType = methods[item as unknown as keyof typeof PaymentProvider];
                return (
                  <Button
                    key={index}
                    startIcon={method.icon}
                    className={`${classes.paymentButton} ${index + 1 === selectMethod ? classes.activeMethod : ''}`}
                    fullWidth
                    onClick={() => handleSelectMethodClick(item)}
                  >
                    <Typography variant={'body2'} fontWeight={600}>
                      {t(method.title)}
                    </Typography>
                  </Button>
                );
              })}
          </Grid>
        </BuyerWrapper>
      </Grid>
      <Grid item xs className={cx([classes.item, classes.tell])}>
        <BuyerWrapper title={`${t('Payment.Buyer.PaymentDetails')}`} isLoading={isLoading}>
          {
            {
              [PaymentProvider.Stripe]: (
                <StripePaymentForm createPayment={createPayment} paymentToken={data?.paymentToken} />
              ),
              [PaymentProvider.Revolut]: (
                <RevolutPaymentForm createPayment={createPayment} paymentToken={data?.paymentToken} />
              ),
              [PaymentProvider.Free]: (
                <FreePayment
                  orderId={orderId}
                  eventSlug={slug}
                  createPayment={createPayment}
                  isLoadingCreatePayment={isLoadingCreatePayment}
                />
              ),
            }[selectMethod as PaymentProvider]
          }
        </BuyerWrapper>
      </Grid>
      <Grid item className={cx([classes.item, classes.short])}>
        <OrderDescription
          tickets={selectedTickets}
          amount={(data as CreatePaymentResType)?.totalPrice}
          commissions={(data as CreatePaymentResType)?.commissions}
          isLoadingPayment={isLoadingCreatePayment}
        />
      </Grid>
    </Grid>
  );
};
