import { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType, object, string } from 'yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'src/configureStore.ts';
import RevolutCheckout, { RevolutCheckoutCardField, ValidationError } from '@revolut/checkout';
import { Pages } from '@store/routes.ts';
import { setPaymentMethod } from 'src/pages/PaymentGateway/feature/paymentMethod.ts';
import { StripePaymentStatus } from 'src/pages/PaymentGateway/constants/enums/enums.ts';
import { useNavigate, useParams } from 'react-router';
import { PaymentProvider } from 'src/pages/Order/constants/enums.ts';
import { useTranslation } from 'react-i18next';
import { isShowStepper } from '../../../features/orderSlice.ts';
import { ReactHookFormCustomTextField } from '../../Buyer/components/ReactHookFormCustomTextField.tsx';

type CheckoutFormPropsType = {
  paymentToken?: string;
};

const useStyles = makeStyles({ name: 'CheckoutForm' })(() => ({
  form: {
    minHeight: '270px',
  },
  cardWrapper: {
    padding: '10px',
    border: '1px solid white',
    borderRadius: '8px',
    height: '41px',
  },
}));

export const CheckoutForm: FC<CheckoutFormPropsType> = ({ paymentToken }) => {
  const { eventSlug = '' } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const validationScheme = object({
    cardholderName: string().required(t('Payment.Buyer.CardholderRequired')),
  });
  const methods = useForm({
    defaultValues: {
      cardholderName: '',
    },
    mode: 'onTouched',
    resolver: yupResolver(validationScheme),
  });
  const navigate = useNavigate();
  const { email, orderId } = useSelector((state) => state.payment.order);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const rcRef = useRef<RevolutCheckoutCardField | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const handlePayment = (status: StripePaymentStatus) => {
    dispatch(
      setPaymentMethod({
        paymentMethod: PaymentProvider.Revolut,
        orderId,
        date: new Date().toISOString(),
        status,
      })
    );
    dispatch(isShowStepper(false));
    navigate(`/${Pages.Event}/${eventSlug}/${Pages.Order}/${Pages.OrderResult}`, { replace: true });
  };

  useEffect(() => {
    if (!paymentToken) return;

    RevolutCheckout(paymentToken, import.meta.env.VITE_REVOLUT_MODE).then((RC) => {
      rcRef.current = RC.createCardField({
        target: targetRef.current as HTMLElement,
        styles: { default: { color: 'white' }, invalid: { color: '#d32f2f' } },
        onValidation: setErrors,
        onSuccess: () => handlePayment(StripePaymentStatus.Succeeded),
        onError: (err) => {
          if (err.type === 'error.invalid-name') {
            methods.setError('cardholderName', { message: err.message });
          } else {
            handlePayment(StripePaymentStatus.Canceled);
          }
        },
      });

      setIsLoading(false);
    });
    return () => rcRef.current?.destroy();
  }, [paymentToken]);

  const handleSubmit: SubmitHandler<InferType<typeof validationScheme>> = async ({ cardholderName }) => {
    if (!email) return;
    if (errors.length !== 0) return;
    rcRef.current?.validate();
    setIsLoading(true);
    rcRef.current?.submit({ name: cardholderName, email });
    setIsLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form id={'payment-form'} onSubmit={methods.handleSubmit(handleSubmit)} className={classes.form}>
        <Grid container direction={'column'} justifyContent={'space-between'} sx={{ minHeight: '100%' }}>
          <div>
            <div className={classes.cardWrapper} ref={targetRef} />
            <Typography pt={'5px'} color={'#d32f2f'}>
              {errors[0] && errors[0].message}
            </Typography>
          </div>
          <ReactHookFormCustomTextField name={'cardholderName'} type={'text'} label={t('Payment.Buyer.Cardholder')} />
          <Grid item alignSelf={'end'} xs={12} sx={{ width: '100%' }}>
            <LoadingButton type={'submit'} loading={isLoading} variant={'contained'} disabled={isLoading} fullWidth>
              {t('Payment.Buyer.Pay')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
