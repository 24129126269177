import { FC } from 'react';
import { Grid } from '@mui/material';
import { CustomLink } from '@components/UI/CustomLink.tsx';
import { TextWithGradient } from '@components/common/TextWithGradient.tsx';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ disabled: boolean }>({ name: 'OrderLink' })((theme, { disabled }) => ({
  link: {
    textDecoration: 'none',
    pointerEvents: disabled ? 'none' : 'auto',
  },
}));

export const OrderLink: FC<LinkPropsType> = ({ title, icon, to, disabled }) => {
  const { classes, theme } = useStyles({ disabled });
  const background = disabled ? theme.palette.primary.contrastText : theme.palette.bgGradient.lightGradient;
  return (
    <Grid item container wrap={'nowrap'} columnGap={'10px'} alignItems={'center'} flexGrow={0} flexBasis={1}>
      {icon}
      <CustomLink className={classes.link} to={to}>
        <TextWithGradient gradientColor={background} variant={'body1'} isUnderline>
          {title}
        </TextWithGradient>
      </CustomLink>
    </Grid>
  );
};

type LinkPropsType = {
  title: string;
  icon: JSX.Element;
  to: string;
  disabled: boolean;
};
