import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterEventsType } from '@store/filterEvents/types.ts';
import { QUERY_DATE_FORMAT, QueryParam } from '@pages/Events/constants/constants.ts';
import { isBefore, isSameDay, isValid, isWithinInterval, parse } from 'date-fns';

const initialState: FilterEventsType = {
  date: { type: QueryParam.Date, value: [] },
  specificDay: { type: QueryParam.SpecificDay, value: null },
  price: { type: QueryParam.Price, value: [] },
};

export const filterEventsReducer = createSlice({
  name: 'filterEventsReducer',
  initialState,
  reducers: {
    setDates: (state, { payload }: PayloadAction<string[]>) => {
      state.date.value = payload;
    },
    addDate: (state, { payload }: PayloadAction<string>) => {
      const selectedDate = parse(payload, QUERY_DATE_FORMAT, new Date());
      const [firstDate, secondDate] = state.date.value.map((date) => parse(date, QUERY_DATE_FORMAT, new Date()));
      const isTwoSelectDate = state.date.value.length === 2;
      const isSameDays = [firstDate, secondDate].some((date) => isSameDay(date, selectedDate));
      const isValidDates = isValid(selectedDate) && isValid(firstDate) && isValid(secondDate);
      const isBetweenDays =
        isValidDates &&
        isWithinInterval(selectedDate, {
          start: firstDate,
          end: secondDate,
        });
      const isBeforeDay = isBefore(selectedDate, firstDate);

      if (isTwoSelectDate && isSameDays) {
        state.date.value.splice(0, state.date.value.length, payload);
        return;
      }
      if (isTwoSelectDate && isBetweenDays) {
        state.date.value.shift();
        state.date.value.unshift(payload);
        return;
      }
      if (isTwoSelectDate) {
        if (isBeforeDay) {
          state.date.value.splice(1, 2);
          state.date.value.unshift(payload);
        } else {
          state.date.value.splice(1, 2, payload);
        }
        return;
      }
      if (state.date.value.length === 1 && isBeforeDay) {
        state.date.value.unshift(payload);
        return;
      }
      state.date.value.push(payload);
    },
    removeFilter: (state, action: PayloadAction<QueryParam>) => {
      state[action.payload].value = initialState[action.payload].value;
    },
    addSpecificDay: (state, action: PayloadAction<string>) => {
      state.specificDay.value = action.payload;
    },
    addPrice: (state, action: PayloadAction<number[]>) => {
      state.price.value = action.payload;
    },
    clearFilters: () => {
      return initialState;
    },
  },
});
