import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type ReactHookFormCustomTextFieldTypes = {
  name: string;
  label: string;
  type?: string;
  checkPassword?: boolean;
  showHiddenPasswordIcon?: boolean;
};

const useStyles = makeStyles({ name: 'ReactHookFormCustomTextField' })(() => ({
  label: {
    color: '#fff',
  },
  textField: {
    '& input:-webkit-autofill:autofill': {
      WebkitTextFillColor: '#fff',
      transition: 'background-color 5000s ease-in-out 0s',
      WebkitBoxShadow: '0 0 0 0 0px  inset',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      color: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff !important',
      },
      '& .MuiInputBase-input:focus::placeholder': {
        opacity: 0,
      },
    },
  },
}));

export const ReactHookFormCustomTextField = ({ name, label, type = 'email' }: ReactHookFormCustomTextFieldTypes) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = errors[name]?.message?.toString();

  const { classes } = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          variant={'outlined'}
          label={label}
          fullWidth
          autoComplete={'on'}
          type={type}
          error={!!errors[name]}
          helperText={errorMessage || ''}
          className={classes.textField}
          InputLabelProps={{
            focused: false,
            error: false,
            className: classes.label,
          }}
          {...field}
        />
      )}
    />
  );
};
