import { makeStyles } from 'tss-react/mui';
import { Box, Modal, Theme } from '@mui/material';
import { FC, memo, ReactNode } from 'react';

const useStyles = makeStyles<StylePropsType>({ name: 'ModalWindow' })((theme: Theme, { padding, width }) => ({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.bg.dark,
    borderRadius: '20px',
    width,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 60px)',
    },
    padding,
    '&:focus': {
      outline: 'none',
    },
  },
}));

export const ModalWindow: FC<ModalWindowPropsType> = memo(
  ({ open, handleClose, children, padding = '20px', width = '600px' }) => {
    const { classes } = useStyles({ padding, width });

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={'modal-modal-title'}
        aria-describedby={'modal-modal-description'}
      >
        <Box className={classes.modalWrapper}>{children}</Box>
      </Modal>
    );
  }
);

type ModalWindowPropsType = {
  open: boolean;
  children: ReactNode;
  handleClose?: () => void;
  padding?: string;
  width?: string;
};

type StylePropsType = {
  width: string;
  padding: string;
};
