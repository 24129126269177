import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useOutletContext } from 'react-router';
import { TicketGroupType } from '../../../types/apiTypes';
import { TicketGroupItem } from '../../TicketSelection/components/TicketItem/TicketGroupItem';
import { TicketGroupItemSlider } from '../../TicketSelection/components/TicketItem/TicketGroupItemSlider';
import './index.css';
import { PaymentContextType } from '../../../types/commonTypes.ts';

const TicketBehavior = {
  INCREMENT: 'INCREMENT',
  DICREMENT: 'DICREMENT',
} as const;

type TicketBehaviorType = (typeof TicketBehavior)[keyof typeof TicketBehavior];

export const useStyles = makeStyles({ name: 'ticketWithStylesAndCounter' })((theme) => ({
  ticket: {
    borderRadius: '1.5rem',
    padding: '1rem 1.5rem',
    background: theme.palette.bgSecond.light,
    position: 'relative',
    width: '100%',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: '20px',
      border: '2px solid transparent',
      background: 'linear-gradient(90deg, var(--myColor1) 0%, var(--myColor2) 100%) border-box',
      WebkitMask:
        'linear-gradient(90deg, #E5427D 0%, #9F4AE1 100%) padding-box, linear-gradient(90deg, #E5427D 0%, #9F4AE1 100%)',
      WebkitMaskComposite: 'destination-out',
      maskComposite: 'exclude',
      transition: '--myColor1 0.3s ease, --myColor2 0.4s ease',
    },
  },

  activeTicket: {
    '&::before': {
      '--myColor1': '#E5427D',
      '--myColor2': '#9F4AE1',
    },
  },
}));

type TicketWithCounterPropsType = {
  group: TicketGroupType;
  startCounterValue: number;
  handleTicketClick: (
    id: string,
    uId: string,
    price: number,
    sector: string,
    discount: number | null,
    count: number,
    coins: number | null,
    title: string,
    ticketGroupId: string
  ) => void;
  orderTicketsCount: number;
  randomColor?: string;
};

const ticketGroupWithCounter = (Component) => {
  return function ({
    group,
    handleTicketClick,
    startCounterValue,
    orderTicketsCount,
    ...props
  }: TicketWithCounterPropsType) {
    const { id, count, price, sector, uId, discount, coinsCount, ticketGroupId } = group;
    const { classes } = useStyles();
    const [counterValue, setCounterValue] = useState(startCounterValue);
    const { t } = useTranslation();
    const { currency, maxPurchaseCount } = useOutletContext<PaymentContextType>();

    const handleChangeTicketCount = (behavior: TicketBehaviorType) => {
      let newCount;

      switch (behavior) {
        case TicketBehavior.INCREMENT: {
          if (counterValue === count || orderTicketsCount >= maxPurchaseCount) return;
          newCount = counterValue + 1;
          break;
        }
        case TicketBehavior.DICREMENT: {
          if (counterValue === 0) return;
          newCount = counterValue - 1;
          break;
        }
        default:
          return;
      }
      setCounterValue(newCount);
      handleTicketClick(id, uId, price, sector, discount || 0, newCount, coinsCount || 0, sector, ticketGroupId);
    };

    useEffect(() => {
      setCounterValue(startCounterValue);
    }, [startCounterValue]);

    return (
      <Grid className={`${classes.ticket} ${counterValue > 0 ? classes.activeTicket : ''}`}>
        <Component
          {...props}
          group={group}
          handleTicketClick={handleTicketClick}
          counterValue={counterValue}
          currency={currency}
          handleChangeTicketCount={handleChangeTicketCount}
          disabledIncrement={orderTicketsCount >= maxPurchaseCount}
          t={t}
        />
      </Grid>
    );
  };
};

export const TicketGroup = ticketGroupWithCounter(TicketGroupItem);
export const TicketGroupForSlide = ticketGroupWithCounter(TicketGroupItemSlider);
