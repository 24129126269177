import { FC } from 'react';

type PropsType = {
  size?: string;
};

export const FullscreenOnIcon: FC<PropsType> = ({ size = '20' }) => (
  <svg width={size} height={size} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={'M9 15L2 22M2 22H7.85714M2 22V16.1429'}
      stroke={'white'}
      strokeWidth={'1.5'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
    <path
      d={'M15 9L22 2M22 2H16.1429M22 2V7.85714'}
      stroke={'white'}
      strokeWidth={'1.5'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>
);
