export function createColorCollection(defaultColorsArray: string[] | undefined) {
  const colors = defaultColorsArray ? [...defaultColorsArray] : [];
  return () => {
    if (colors.length > 0) {
      return colors.splice(0, 1)[0];
    }
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
  };
}
