import { FC } from 'react';

type PlaceSelectorIconPropsType = {
  size?: string;
};

export const PlaceSelectorIcon: FC<PlaceSelectorIconPropsType> = ({ size = '30' }) => (
  <svg width={size} height={size} viewBox={'0 0 30 30'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <rect width={size} height={size} rx={'10'} fill={'#B19EF9'} />
    <path
      d={
        'M9.36 21.5L13.572 8.54H16.281L20.493 21.5H18.54L14.661 9.674H15.147L11.313 21.5H9.36ZM11.547 18.575V16.811H18.315V18.575H11.547Z'
      }
      fill={'white'}
    />
  </svg>
);
