import { Events } from '@core/constants/constants';
import { EventTourType } from '@pages/Tour/models/types.ts';
import { apiSlice } from '../apiSlice';
import { RequestEventsType, ResponseEventsType } from './types/types.ts';

export const EventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<ResponseEventsType, RequestEventsType>({
      query: (data) => {
        return {
          url: Events.Events,
          params: data,
        };
      },
    }),
    getTour: builder.query<EventTourType, string>({
      query: (slug) => {
        return {
          url: `${Events.Events}/${Events.Tours}/${slug}`,
        };
      },
    }),
  }),
});

export const { useLazyGetEventsQuery, useGetEventsQuery, useGetTourQuery, reducerPath: eventsReducerPath } = EventsApi;
