/* eslint-disable no-restricted-syntax */
import { CSSProperties } from 'react';

export const addClassesAndStyles = (element: HTMLElement | Element, classes: string[], styles: CSSProperties) => {
  if (element) {
    const elementCopy = element;
    for (const className of classes) {
      elementCopy.classList.add(className);
    }
    for (const [key, value] of Object.entries(styles)) {
      (elementCopy as HTMLElement).style[key] = value;
    }
  }
};

export const removeClassesAndStyles = (element: HTMLElement | Element, classes: string[], styles: CSSProperties) => {
  if (element) {
    const elementCopy = element;
    for (const className of classes) {
      elementCopy.classList.remove(className);
    }
    for (const [key, value] of Object.entries(styles)) {
      (elementCopy as HTMLElement).style[key] = '';
    }
  }
};
