import { FC, SVGProps } from 'react';

type PropsType = {
  size?: string;
  isActive?: boolean;
  gradient?: [string, string] | undefined;
};

export const PaymentTicket: FC<PropsType & SVGProps<SVGSVGElement>> = ({
  size = '24',
  isActive = true,
  gradient = [isActive ? 'white' : '#707893', isActive ? 'white' : '#707893'],
  ...rest
}) => {
  const [firstColor, secondColor] = gradient;
  return (
    <svg {...rest} width={size} height={size} viewBox={'0 0 24 25'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M17.625 21.5C18.2666 20.9297 19.2334 20.9297 19.875 21.5C20.3109 21.8875 21 21.5781 21 20.9948V4.00507C21 3.42184 20.3109 3.11239 19.875 3.49987C19.2334 4.07017 18.2666 4.07017 17.625 3.49987C16.9834 2.92957 16.0166 2.92957 15.375 3.49987C14.7334 4.07017 13.7666 4.07017 13.125 3.49987C12.4834 2.92957 11.5166 2.92957 10.875 3.49987C10.2334 4.07017 9.26659 4.07017 8.625 3.49987C7.98341 2.92957 7.01659 2.92957 6.375 3.49987C5.73341 4.07017 4.76659 4.07017 4.125 3.49987C3.68909 3.11239 3 3.42184 3 4.00507V20.9948C3 21.5781 3.68909 21.8875 4.125 21.5C4.76659 20.9297 5.73341 20.9297 6.375 21.5C7.01659 22.0703 7.98341 22.0703 8.625 21.5C9.26659 20.9297 10.2334 20.9297 10.875 21.5C11.5166 22.0703 12.4834 22.0703 13.125 21.5C13.7666 20.9297 14.7334 20.9297 15.375 21.5C16.0166 22.0703 16.9834 22.0703 17.625 21.5Z'
        }
        stroke={`url(#paymentGradient${firstColor}${secondColor})`}
        strokeWidth={'1.5'}
      />
      <path
        d={'M7.5 16H16.5'}
        stroke={`paymentGradient${firstColor}${secondColor}`}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
      />
      <path
        d={'M7.5 12.5H16.5'}
        stroke={`url(#paymentGradient${firstColor}${secondColor})`}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
      />
      <path
        d={'M7.5 9H16.5'}
        stroke={`url(#paymentGradient${firstColor}${secondColor})`}
        strokeWidth={'1.5'}
        strokeLinecap={'round'}
      />
      <defs>
        <linearGradient
          id={`paymentGradient${firstColor}${secondColor}`}
          x1={'3'}
          y1={'12.5'}
          x2={'21'}
          y2={'12.5'}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop stopColor={firstColor} />
          <stop offset={'1'} stopColor={secondColor} />
        </linearGradient>
        <linearGradient
          id={`paymentGradient${firstColor}${secondColor}`}
          x1={'7.5'}
          y1={'16.5'}
          x2={'16.5'}
          y2={'16.5'}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop stopColor={firstColor} />
          <stop offset={'1'} stopColor={secondColor} />
        </linearGradient>
        <linearGradient
          id={`paymentGradient${firstColor}${secondColor}`}
          x1={'7.5'}
          y1={'13'}
          x2={'16.5'}
          y2={'13'}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop stopColor={firstColor} />
          <stop offset={'1'} stopColor={secondColor} />
        </linearGradient>
        <linearGradient
          id={`paymentGradient${firstColor}${secondColor}`}
          x1={'7.5'}
          y1={'9.5'}
          x2={'16.5'}
          y2={'9.5'}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop stopColor={firstColor} />
          <stop offset={'1'} stopColor={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};
