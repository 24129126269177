import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILocationStoreState } from './types';

const initialState: ILocationStoreState = {
  location: 'Астана',
};

const locationReducer = createSlice({
  name: 'locationReducer',
  initialState,
  reducers: {
    initLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
  },
});

export default locationReducer;
