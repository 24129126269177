import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CurrencyType } from '@core/api/EventsSlice/types/types.ts';
import { PriceView } from '@components/common/PriceView';
import { TicketGroupType } from '../../../../types/apiTypes';
import { CounterForTicketsGroup } from './components/CounterForTicketsGroup';

type TicketGroupPropsType = {
  group: TicketGroupType;
  handleChangeTicketCount: (behavior: string) => void;
  counterValue: number;
  disabledIncrement: boolean;
  currency: CurrencyType | null | undefined;
};

const useStyles = makeStyles({ name: 'TicketGroupItemSlider' })((theme) => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    borderRadius: '10px',
    background: '#B19EF9',
    height: '30px',
    width: '30px',
  },
  discount: {
    background: theme.palette.bgGradient.mainGradient,
    padding: '5px',
    borderRadius: '5px',
  },
}));

export const TicketGroupItemSlider = ({
  group,
  currency,
  handleChangeTicketCount,
  counterValue,
  disabledIncrement,
}: TicketGroupPropsType) => {
  const { name, price, discount, count, sector } = group;
  const disabled = disabledIncrement || counterValue >= count;

  const { classes } = useStyles();
  return (
    <Grid
      sx={{ width: '400px', minHeight: '85px' }}
      container
      direction={'row'}
      columnGap={2}
      item
      alignItems={'center'}
    >
      <Grid xs item container direction={'column'} rowGap={1.5}>
        <Grid item container alignItems={'center'} wrap={'nowrap'} columnGap={1.5}>
          {sector.length === 1 ? (
            <Typography fontWeight={600} fontSize={'18px'} className={classes.iconWrapper}>
              {sector}
            </Typography>
          ) : null}
          <Typography fontWeight={300} variant={'body2'}>
            {name}
          </Typography>
        </Grid>
        <Typography fontWeight={300} color={'#707893'} variant={'caption'} fontSize={12}>
          {name?.toUpperCase() || 'NO NAME'}
        </Typography>
      </Grid>
      <Grid
        xs={'auto'}
        item
        container
        direction={'column'}
        rowGap={0.5}
        alignItems={'flex-end'}
        style={{ maxWidth: '120px' }}
      >
        <PriceView price={{ value: price, discount }} currency={currency} element={'ticketGroupItemSlider'} />
      </Grid>
      <CounterForTicketsGroup
        counterValue={counterValue}
        disabledIncrement={disabled}
        handleChangeTicketCount={handleChangeTicketCount}
      />
    </Grid>
  );
};
