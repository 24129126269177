export enum Routes {
  Event = `events`,
  EventOrder = `/order`,
  EventParticipants = `/participants`,
  CreateOrder = 'order',
  UpdateOrder = 'order',
  PaymentCreate = 'payment',
  Details = 'details',
  Tickets = 'tickets',
  Available = 'available',
  Complete = 'complete',
  PaymentProviders = 'paymentproviders',
  Commissions = 'commissions',
}
