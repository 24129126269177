import { useRef } from 'react';
import { TicketItemType } from '../../../types/apiTypes.ts';

export const numberToColor = (number: number): string => {
  const baseValue = 128;
  const variation = 127;

  const red = Math.round(baseValue + Math.sin(0.017 * number) * variation);
  const green = Math.round(baseValue + Math.sin(0.019 * number + 2) * variation);
  const blue = Math.round(baseValue + Math.sin(0.023 * number + 4) * variation);

  return `#${(16777216 + red * 65536 + green * 256 + blue).toString(16).slice(1)}`;
};

export const useGetColorCollectionByPrice = () => {
  const colorCollection = useRef<Map<number, string>>(new Map());
  const setColorCollection = (ticket: TicketItemType) => {
    if (!colorCollection.current.has(ticket.price)) {
      colorCollection.current.set(ticket.price, numberToColor(ticket.price));
    }
  };

  return {
    colorCollection: colorCollection.current,
    setColorCollection,
  };
};
