import React, { FC, memo } from 'react';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { MapLocation } from '@components/icons/MapLocation';
import { makeStyles } from 'tss-react/mui';
import { SectionWrapper } from '../common/SectionWrapper';
import { FullScreenButton } from '../TicketSelection/components/FullScreenButton/FullScreenButton';

const useStyles = makeStyles<{ posterUrl: string }>({ name: 'EventCard' })((theme, { posterUrl }) => ({
  mainWrapper: {
    overflow: 'hidden',
    minHeight: '150px',
    backgroundColor: theme.palette.bg.dark,
  },
  wrapper: {
    background: `url(${posterUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    width: '275px',
    minHeight: '100%',
  },
  date: {
    background: theme.palette.bgSecond.light,
    borderRadius: '10px',
    padding: '8.5px 10px',
    display: 'inline-block',
  },
}));

type EventDescriptionPropsType = {
  posterUrl: string;
  title: string;
  startDate: string;
  city: string;
  venueAddress: string;
  isFullScreen?: boolean;
  handleFullScreenClick?: () => void;
  isExistScheme?: boolean;
  isSelectTicketsStep?: boolean;
};

export const EventDescription: FC<EventDescriptionPropsType> = memo(
  ({
    title,
    posterUrl,
    startDate,
    venueAddress,
    city,
    isFullScreen,
    handleFullScreenClick,
    isExistScheme,
    isSelectTicketsStep,
  }) => {
    const { classes } = useStyles({ posterUrl });

    return (
      <SectionWrapper
        direction={'row'}
        overflow={'hidden'}
        padding={0}
        borderRadius={isFullScreen ? 'none' : isExistScheme && isSelectTicketsStep ? '20px 20px 0 0' : '20px'}
        className={classes.mainWrapper}
      >
        <Grid className={classes.wrapper} item />
        <Grid padding={'20px'} container direction={'column'} alignItems={'left'} gap={'15px'} item xs={6.8}>
          <Grid alignItems={'center'} item container gap={'5px'} wrap={'nowrap'}>
            <MapLocation />
            <Typography
              fontWeight={600}
              fontSize={'14px'}
              component={'span'}
              variant={'body1'}
            >{`${city} | `}</Typography>
            <Typography
              fontWeight={400}
              fontSize={'14px'}
              component={'span'}
              variant={'body1'}
            >{`${venueAddress}`}</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight={600} fontSize={'24px'} variant={'body2'}>{`${title}`}</Typography>
          </Grid>
          <Grid item>
            <Typography
              fontWeight={600}
              fontSize={'14px'}
              component={'span'}
              variant={'caption'}
              className={classes.date}
            >
              {`${dayjs(startDate).format('D MMMM HH:mm')}`}
            </Typography>
          </Grid>
        </Grid>
        {isExistScheme && isSelectTicketsStep && (
          <FullScreenButton handleFullScreenClick={handleFullScreenClick} isFullScreen={isFullScreen} />
        )}
      </SectionWrapper>
    );
  }
);
