import { Grid, Skeleton, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';

export const SkeletonForm = () => (
  <Grid container flexDirection={'column'} rowGap={'13px'}>
    <Grid item>
      <ItemSkeleton />
    </Grid>
    <Grid item container xs wrap={'nowrap'} columnGap={'10px'}>
      <Grid item xs={6}>
        <ItemSkeleton titleBreakpoint={6} />
      </Grid>
      <Grid item xs={6}>
        <ItemSkeleton />
      </Grid>
    </Grid>
    <Grid item>
      <ItemSkeleton />
    </Grid>
    <Grid item>
      <LoadingButton variant={'contained'} disabled fullWidth>
        Pay now
      </LoadingButton>
    </Grid>
  </Grid>
);

const ItemSkeleton = ({ titleBreakpoint = 3 }: { titleBreakpoint?: number }) => {
  const { palette } = useTheme();
  return (
    <Grid container rowGap={'6px'}>
      <Grid item xs={titleBreakpoint}>
        <Skeleton variant={'text'} height={14} width={'100%'} sx={{ backgroundColor: palette.bgSecond.light }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant={'text'} height={44.4} width={'100%'} sx={{ backgroundColor: palette.bgSecond.light }} />
      </Grid>
    </Grid>
  );
};
