import React from 'react';
import { Grid, GridProps } from '@mui/material';
import { theme } from '@core/theme/theme';

type SectionWrapperPropsType = {
  backgroundColor?: string;
  borderRadius?: string;
};

export const SectionWrapper: React.FC<GridProps & SectionWrapperPropsType> = ({
  children,
  backgroundColor = theme.palette.bg.dark,
  borderRadius = '1.5rem',
  ...props
}) => {
  return (
    <Grid container item sx={{ background: backgroundColor }} padding={2.5} borderRadius={borderRadius} {...props}>
      {children}
    </Grid>
  );
};
