import { apiSlice } from '@core/api/apiSlice';
import { POST_METHOD } from '@core/constants/http-method-constants.ts';
import { RoutesApi } from '../constants/routes';
import { OrderType } from '../types/apiTypes.ts';

const OrderApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderById: builder.query<OrderType, string>({
      query: (orderId) => {
        return {
          url: `${RoutesApi.Order}/${orderId}`,
        };
      },
    }),
    emailOrder: builder.mutation<void, string>({
      query: (orderId) => {
        return {
          url: `${RoutesApi.Order}/${orderId}/sendTickets`,
          method: POST_METHOD,
        };
      },
    }),
  }),
});

export const { useGetOrderByIdQuery, useEmailOrderMutation, reducerPath: orderResultReducerPath } = OrderApi;
