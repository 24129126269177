import { styled } from '@mui/system';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';

export const StyledSwiper = styled(Swiper)({
  '& .swiper-slide': {
    width: 'fit-content',
    marginBottom: '50px',
  },
  '& .swiper-nav-btns': {
    position: 'absolute',
    left: '20px',
    bottom: '0',
    zIndex: 100,
  },
});
