import React, { SVGProps } from 'react';

type PropsType = {
  size?: string;
};

export const DangerTriangle: React.FC<PropsType & SVGProps<SVGSVGElement>> = ({ size = '15' }) => {
  return (
    <svg width={size} height={size} viewBox={'0 0 15 15'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M3.31982 6.72593C5.1438 3.49198 6.05578 1.875 7.5 1.875C8.94422 1.875 9.8562 3.49198 11.6802 6.72593L11.9075 7.12892C13.4232 9.81631 14.181 11.16 13.4961 12.1425C12.8111 13.125 11.1165 13.125 7.72729 13.125H7.27271C3.88347 13.125 2.18886 13.125 1.50391 12.1425C0.818964 11.16 1.57682 9.81632 3.09253 7.12892L3.31982 6.72593Z'
        }
        stroke={'#707893'}
        strokeWidth={'0.9375'}
      />
      <path d={'M7.5 5V8.125'} stroke={'#707893'} strokeWidth={'0.9375'} strokeLinecap={'round'} />
      <circle cx={'7.5'} cy={'10'} r={'0.625'} fill={'#707893'} />
    </svg>
  );
};
