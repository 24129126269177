import { FC } from 'react';
import { CommissionType } from '@pages/Order/types/apiTypes.ts';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Commissions: FC<CommissionsPropsType> = ({ commissions }) => {
  const { t } = useTranslation();
  return (
    <Grid item container flexDirection={'column'} wrap={'nowrap'}>
      {commissions.map((item, index) => (
        <Grid key={index} item container justifyContent={'space-between'} gap={'15px'}>
          <Typography variant={'body1'} color={'primary.contrastText'}>
            {`General.Fees.${t(item.name)}`}
          </Typography>
          <Typography variant={'body1'} color={'primary.contrastText'}>
            {`${item.value * 100}%`}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

type CommissionsPropsType = {
  commissions: CommissionType[];
};
