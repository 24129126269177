import { Link, LinkProps } from 'react-router-dom';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'StyledLink' })(() => ({
  root: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export const CustomLink: FC<LinkProps> = (props) => {
  const { classes } = useStyles();
  return <Link className={classes.root} {...props} />;
};
