import { FC } from 'react';

type PropsType = {
  size?: string;
};

export const ZoomIn: FC<PropsType> = ({ size = '24' }) => (
  <svg width={size} height={size} viewBox={'0 0 22 22'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M10.5 1.75C5.66751 1.75 1.75 5.66751 1.75 10.5C1.75 15.3325 5.66751 19.25 10.5 19.25C15.3325 19.25 19.25 15.3325 19.25 10.5C19.25 5.66751 15.3325 1.75 10.5 1.75ZM0.25 10.5C0.25 4.83908 4.83908 0.25 10.5 0.25C16.1609 0.25 20.75 4.83908 20.75 10.5C20.75 13.0605 19.8111 15.4017 18.2589 17.1982L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L17.1982 18.2589C15.4017 19.8111 13.0605 20.75 10.5 20.75C4.83908 20.75 0.25 16.1609 0.25 10.5ZM10.5 7.25C10.9142 7.25 11.25 7.58579 11.25 8V9.75H13C13.4142 9.75 13.75 10.0858 13.75 10.5C13.75 10.9142 13.4142 11.25 13 11.25H11.25V13C11.25 13.4142 10.9142 13.75 10.5 13.75C10.0858 13.75 9.75 13.4142 9.75 13V11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H9.75V8C9.75 7.58579 10.0858 7.25 10.5 7.25Z'
      }
      fill={'white'}
    />
  </svg>
);
