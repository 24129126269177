import { createRoutesFromElements, Route } from 'react-router';
import { Pages, PagesRoutes } from '@store/routes';
import { EventDetailsPageLazy as EventDetails } from 'src/pages/EventDetails';
import { DashboardPageLazy as Dashboard } from 'src/pages/Dashboard';
import { EventsPageLazy as Events } from 'src/pages/Events';
import { ServicePageLazy as Service } from 'src/pages/Errors/pages/Service';
import { ServerErrorPageLazy as ServerError } from 'src/pages/Errors/pages/ServerError';
import { OrderResultPageLazy as OrderResult } from 'src/pages/OrderResult';
import { NotFoundPageLazy as NotFound } from 'src/pages/Errors/pages/NotFound';
import { SignUpPageLazy as SignUp } from 'src/pages/SignUp';
import { SignInPageLazy as SignIn } from 'src/pages/SignIn';
import { ForgotPasswordPageLazy as ForgotPassword } from 'src/pages/ForgotPassword';
import { NewPasswordPageLazy as NewPassword } from 'src/pages/NewPassword';
import { ProfilePageLazy as Profile } from 'src/pages/Profile';
import { OrderLayoutLazy as OrderLayout } from 'src/pages/Order';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Order } from '@pages/Order/Order.tsx';
import { Stripe } from '@pages/PaymentGateway/Stripe/Stripe.tsx';
import {
  UserLayoutPageLazy as UserLayout,
  PageLayoutLazy as PageLayout,
  ProfilePageLazy as ProfilePage,
} from 'src/layouts';
import { TourPageLazy as Tour } from '@pages/Tour';
import { OrderDetails } from '@pages/Profile/components/History/components/OrderDetails/OrderDetails.tsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={PagesRoutes.Main} element={<PageLayout maxWidth={'xl'} />}>
        <Route index Component={Dashboard} />
        <Route path={PagesRoutes.EventTour} Component={Tour} />
        <Route path={PagesRoutes.Events} Component={Events} />
        <Route path={PagesRoutes.InformationError} Component={Service} />
        <Route path={PagesRoutes.ServerError} Component={ServerError} />
        <Route path={PagesRoutes.NotFound} Component={NotFound} />
        <Route path={PagesRoutes.Profile} Component={ProfilePage}>
          <Route index Component={Profile} />
          <Route path={PagesRoutes.OrderDetails} element={<OrderDetails />} />
        </Route>
        <Route path={PagesRoutes.Order} Component={OrderLayout}>
          <Route index Component={Order} />
          <Route path={Pages.OrderResult} Component={OrderResult} />
          <Route path={Pages.Stripe} Component={Stripe} />
        </Route>
      </Route>
      <Route path={PagesRoutes.Main} element={<PageLayout />}>
        <Route path={PagesRoutes.EventDetails} Component={EventDetails} />
      </Route>
      <Route Component={UserLayout}>
        <Route path={PagesRoutes.Login} Component={SignIn} />
        <Route path={PagesRoutes.ForgotPassword} Component={ForgotPassword} />
        <Route path={PagesRoutes.NewPassword} Component={NewPassword} />
        <Route path={PagesRoutes.Registration} Component={SignUp} />
      </Route>
    </Route>
  )
);

export const Router = () => <RouterProvider router={router} />;
