import { OrderTicketGroupType } from '../types/commonTypes';

export const getTicketCountInStoredOrderList = (storedOrderList: OrderTicketGroupType[], uId: string): number => {
  try {
    if (!storedOrderList.length) return 0;
    const existOrderItemIndex = storedOrderList.findIndex((orderItem) => orderItem.uId === uId);
    if (existOrderItemIndex > -1) return storedOrderList[existOrderItemIndex].count;
    return 0;
  } catch (e) {
    return 0;
  }
};
