import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';
import { Pages, PagesRoutes } from '@store/routes.ts';
import { useDispatch, useSelector } from 'src/configureStore.ts';
import { PaymentProvider } from '@pages/Order/constants/enums.ts';
import { isShowStepper } from '@pages/Order/features/orderSlice.ts';
import { BookingSkeleton } from '@pages/OrderResult/components/BookingSkeleton.tsx';
import { setAlertData } from '@store/app/actions.ts';
import { AlertType } from '@store/app/constants.ts';
import { setPaymentMethod } from '../feature/paymentMethod.ts';
import { StripePaymentParams, StripePaymentStatus } from '../constants/enums/enums.ts';

export const Stripe = () => {
  const dispatch = useDispatch();
  const { eventSlug } = useParams<{ eventSlug: string }>();
  const { order } = useSelector((state) => state.payment);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!order?.orderId) {
      navigate(PagesRoutes.Main, { replace: true });
      return;
    }

    if (params.get(StripePaymentParams.RedirectStatus) !== StripePaymentStatus.Succeeded) {
      dispatch(setAlertData({ message: 'Something went wrong', type: AlertType.Error }));
      navigate(`/${Pages.Event}/${eventSlug}/${Pages.Order}`, { replace: true });
      return;
    }
    dispatch(
      setPaymentMethod({
        paymentMethod: PaymentProvider.Stripe,
        orderId: order.orderId,
        date: new Date().toISOString(),
        status: StripePaymentStatus.Succeeded,
      })
    );
    dispatch(isShowStepper(false));
    navigate(`/${Pages.Event}/${eventSlug}/${Pages.Order}/${Pages.OrderResult}`, { replace: true });
  }, []);

  return <BookingSkeleton />;
};
