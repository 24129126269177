import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { PriceView } from '@components/common/PriceView';
import { CurrencyType } from '@core/api/EventsSlice/types/types.ts';
import { TicketGroupType } from '../../../../types/apiTypes';
import { CounterForTicketsGroup } from './components/CounterForTicketsGroup';

export const useStyles = makeStyles({ name: 'TicketGroupItem' })((theme) => ({
  nameWrapper: {
    padding: '5px 10px',
    borderRadius: '10px',
    backgroundColor: theme.palette.bg.dark,
  },
  discount: {
    background: theme.palette.bgGradient.mainGradient,
    padding: '5px',
    borderRadius: '5px',
  },
}));

type TicketGroupItemPropsType = {
  group: TicketGroupType;
  currency: CurrencyType | null | undefined;
  counterValue: number;
  handleChangeTicketCount: (behavior: string) => void;
  disabledIncrement: boolean;
};

export const TicketGroupItem = ({
  group,
  currency,
  counterValue,
  handleChangeTicketCount,
  disabledIncrement,
}: TicketGroupItemPropsType) => {
  const { name, price, count, discount = 1000 } = group;
  const disabled = disabledIncrement || counterValue >= count;
  const { classes } = useStyles();
  return (
    <Grid xs container direction={'row'} alignItems={'center'} flexWrap={'nowrap'} justifyContent={'space-between'}>
      <Grid item xs container direction={'row'} columnGap={1.2} alignItems={'center'} style={{ flexGrow: 0 }}>
        <Typography fontWeight={400} variant={'body2'} fontSize={'14px'} className={classes.nameWrapper}>
          {name?.toUpperCase() || 'NO NAME'}
        </Typography>
      </Grid>
      <Grid item container flexWrap={'nowrap'} xs justifyContent={'flex-end'} alignItems={'center'} gap={'30px'}>
        <Grid item alignItems={'center'} container direction={'row'} xs wrap={'nowrap'} justifyContent={'flex-end'}>
          <PriceView price={{ value: price, discount }} currency={currency} element={'ticketGroupItem'} />
        </Grid>
        <CounterForTicketsGroup
          counterValue={counterValue}
          disabledIncrement={disabled}
          handleChangeTicketCount={handleChangeTicketCount}
        />
      </Grid>
    </Grid>
  );
};
