import React from 'react';

type PropsType = {
  size?: string;
};

export const TicketsLimitIcon: React.FC<PropsType> = ({ size = '30' }) => {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} width={size} height={size} viewBox={'0 0 30 30'} fill={'none'}>
      <path
        d={
          'M22.0833 2.5H7.91667C7.52974 2.5 7.33627 2.5 7.17317 2.51497C5.36024 2.68138 3.92288 4.18943 3.76427 6.09152C3.75 6.26265 3.75 6.46563 3.75 6.87158V25.3247C3.75 26.4154 5.07338 26.8782 5.69712 26.0057C6.12981 25.4004 6.99519 25.4004 7.42788 26.0057L7.96875 26.7623C8.67188 27.7459 10.0781 27.7459 10.7812 26.7623C11.4844 25.7787 12.8906 25.7787 13.5938 26.7623C14.2969 27.7459 15.7031 27.7459 16.4062 26.7623C17.1094 25.7787 18.5156 25.7787 19.2188 26.7623C19.9219 27.7459 21.3281 27.7459 22.0312 26.7623L22.5721 26.0057C23.0048 25.4004 23.8702 25.4004 24.3029 26.0057C24.9266 26.8782 26.25 26.4154 26.25 25.3247V6.87158C26.25 6.46562 26.25 6.26265 26.2357 6.09152C26.0771 4.18943 24.6398 2.68138 22.8268 2.51497C22.6637 2.5 22.4703 2.5 22.0833 2.5Z'
        }
        stroke={'white'}
        strokeWidth={'1.875'}
      />
      <path d={'M9.375 19.375H20.625'} stroke={'white'} strokeWidth={'1.875'} strokeLinecap={'round'} />
      <path d={'M17.5 10L12.5 15M12.5 10L17.5 15'} stroke={'white'} strokeWidth={'1.875'} strokeLinecap={'round'} />
    </svg>
  );
};
