export enum TicketZoneType {
  None = 0,
  Children = 1,
  VIP = 2,
  Standard = 3,
}

export enum OrderStatus {
  None = 0,
  Created = 1,
  Initiated = 2,
  Processed = 3,
  Paid = 4,
  Annulment = 5,
  Cancelled = 6,
  Refunded = 7,
}
