import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import { MILLISECONDS_IN_SECOND } from '@core/constants/constants';
import { SwiperSlide } from 'swiper/react';
import { useBlocker, useOutletContext } from 'react-router';
import { Pages } from '@store/routes.ts';
import { useBeforeUnload } from 'react-router-dom';
import { AppSettings } from 'src/settings/appSettings';
import { useCreateOrderMutation } from '@pages/Order/api/orderApi';
import { createOrderForTicketsAndTicketsGroup } from '@pages/Order/helpers/createOrderForTicketsAndTicketsGroup';
import { CreateOrderResType, TicketGroupType, TicketItemType } from '@pages/Order/types/apiTypes';
import { createColorCollection } from '@pages/Order/helpers/createColorCollection';
import { getTicketCountInStoredOrderList } from '@pages/Order/helpers/getTicketCountInStoredOrderList';
import { useDispatch, useSelector } from 'src/configureStore';
import { KeyErrors } from '@store/enums.ts';
import { setIsTimerActive } from '@components/CountdownTimer/feature/timerSlice.ts';
import { TicketLimitModal } from '@pages/Order/components/TicketSelection/components/TicketLimitModal.tsx';
import { theme } from '@core/theme/theme.ts';
import { useCalculateTickets } from '@pages/Order/helpers/useCalculateTickets.ts';
import { TicketGroup, TicketGroupForSlide } from '../HOC/TicketWithCounter/ticketGroupWithCounter';
import { OrderDescription } from './components/OrderDescription/OrderDescription.tsx';
import { TicketItemSkeleton } from './components/TicketItem/TickeItemSkeleton';
import { OrderTicketGroupType, OrderTicketType, PaymentContextType } from '../../types/commonTypes';
import { createOrder, setConnectionId, setCountDownTimer } from '../../features/orderSlice';
import { PlaceSelection } from '../PlaceSelection/PlaceSelection';
import { StyledSwiper } from './components/StyledSwiper';
import { SwiperNavButtons } from './components/SwiperNavButtons';
import { SectionWrapper } from '../common/SectionWrapper';
import { useTicketHub } from './hooks/useTicketHub';

const colorsForTicketGroup = ['#7556F4', '#67C186', '#D88A42'];
const COUNT_TICKET_SKELETON = 3;
const SESSION_TIME = AppSettings.PURCHASE_SESSION_TIME * MILLISECONDS_IN_SECOND;
const SELECT_TICKET_SESSION_TIME = AppSettings.SELECT_TICKETS_SESSION_TIME * MILLISECONDS_IN_SECOND;

export const TicketSelection = memo(
  ({ handleNextStep, tickets, ticketGroups, slug, isLoading, scheme }: TicketSelectionPropsType) => {
    const { keyError } = useSelector((state) => state.app);
    const storedOrderList = useSelector((state) => state.payment.order.items);
    const { orderId } = useSelector((state) => state.payment.order);
    const { countDownTimer, isOpenPurchaseModal } = useSelector((state) => state.payment);
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const { connectionId, reservedTickets, handleRelease, handleReserve, setReservedTickets } = useTicketHub();

    const [isBeginning, setIsBeginning] = useState(true);
    const [groupColors, setGroupColors] = useState({});
    const [isEnd, setIsEnd] = useState(false);
    const [hoveredGroupTicket, setHoveredGroupTicket] = useState('');
    const [orderTickets, setOrderList] = useState<OrderTicketType[]>(storedOrderList.tickets || []);
    const [orderTicketGroups, setOrderTicketGroups] = useState<OrderTicketGroupType[]>(
      storedOrderList.ticketGroups || []
    );
    const orderTicketsCount =
      orderTickets.length + orderTicketGroups.reduce((acc, ticketGroup) => acc + ticketGroup.count, 0);

    const { isFullScreen, eventId, currency, maxPurchaseCount, isExistScheme } = useOutletContext<PaymentContextType>();

    const [createOrderApi, { isLoading: isLoadingOrder }] = useCreateOrderMutation();

    const dispatch = useDispatch();

    useLayoutEffect(() => {
      if (orderId || keyError === KeyErrors.ServerError) return;

      dispatch(setCountDownTimer(Date.now() + SELECT_TICKET_SESSION_TIME));
      dispatch(setIsTimerActive(true));
    }, [orderId, keyError]);

    const resetTickets = () => {
      orderTickets.forEach((ticket) => handleRelease(ticket.id));
      setOrderList([]);
      setOrderTicketGroups([]);
    };

    useLayoutEffect(() => {
      if (orderId || keyError === KeyErrors.ServerError) return;

      if (!countDownTimer && isOpenPurchaseModal) {
        orderTickets.forEach((ticket) => handleRelease(ticket.id));
        setOrderList([]);
        setOrderTicketGroups([]);
      }
    }, [isOpenPurchaseModal]);

    useBeforeUnload(
      useCallback(() => {
        if (orderId) {
          dispatch(setIsTimerActive(false));
          return;
        }

        dispatch(setCountDownTimer(0));
        dispatch(setIsTimerActive(false));
      }, [orderId])
    );

    useBlocker(({ nextLocation }) => {
      if (nextLocation.pathname.includes(Pages.Order)) return false;

      dispatch(setCountDownTimer(0));
      dispatch(setIsTimerActive(false));
      return false;
    });

    const handleHoveredTicketGroup = (id: string) => {
      setHoveredGroupTicket(id);
    };

    const handleNextClick = () => {
      if (!connectionId || !eventId || !currency) return;

      const { tickets, ticketGroups } = createOrderForTicketsAndTicketsGroup(orderTickets, orderTicketGroups);
      createOrderApi({
        connectionId,
        eventId,
        currency: currency.isoCode,
        tickets,
        ticketGroups,
        RetailWebSite: window.location.origin,
      })
        .unwrap()
        .then(({ orderId }: CreateOrderResType) => {
          dispatch(
            createOrder({
              orderId,
              email: null,
              slug: slug || '',
              eventId,
              isoCode: currency.isoCode,
              items: { tickets: orderTickets, ticketGroups: orderTicketGroups },
            })
          );
          dispatch(setCountDownTimer(Date.now() + SESSION_TIME));
          dispatch(setConnectionId(connectionId));
          handleNextStep();
        });
    };

    const getColor = createColorCollection(colorsForTicketGroup);

    const handleTicketClick = (
      id: string,
      uId: string,
      price: number,
      sector: string,
      discount: number | null,
      coins: number | null,
      title: string,
      row: string,
      seat: string
    ) => {
      const newOrderItem: OrderTicketType = {
        id,
        uId,
        price,
        sector,
        discount,
        coins,
        title,
        row,
        seat,
      };
      setOrderList((prevState) => {
        if (prevState.find((ticket) => ticket.id === id)) {
          return prevState.filter((ticket) => ticket.id !== id);
        }
        return [...prevState, newOrderItem];
      });
    };

    const handleTicketGroupClick = (
      id: string,
      uId: string,
      price: number,
      sector: string,
      discount: number | null,
      count: number,
      coins: number | null,
      title: string,
      ticketGroupId: string
    ) => {
      const newOrderItem: OrderTicketGroupType = {
        id,
        uId,
        price,
        sector,
        discount,
        count,
        coins,
        title,
        ticketGroupId,
      };
      setOrderTicketGroups((prevState) => {
        const ticketGroup = prevState.find((ticket) => ticket.ticketGroupId === ticketGroupId);
        if (!ticketGroup) return [...prevState, newOrderItem];

        return count >= 1
          ? prevState.map((item) => (item.ticketGroupId === ticketGroup.ticketGroupId ? { ...item, count } : item))
          : prevState.filter((item) => item.ticketGroupId !== ticketGroup.ticketGroupId);
      });
    };

    const deleteTicketFromOrder = (id: string, ticketGroup: boolean) => {
      if (ticketGroup) {
        setOrderTicketGroups((prevState) => prevState.filter((ticket) => ticket.ticketGroupId !== id));
      } else {
        setOrderList((prevState) => prevState.filter((ticket) => ticket.id !== id));
      }
      handleRelease(id);
    };

    const handleSwiperChange = (start: boolean, end: boolean) => {
      setIsBeginning(start);
      setIsEnd(end);
    };

    useEffect(() => {
      const colors = {};
      ticketGroups.forEach((group) => {
        colors[group.ticketGroupId] = getColor();
      });
      setGroupColors(colors);
    }, [ticketGroups]);

    const {
      tickets: selectedTickets,
      totalCostWithoutFee,
      totalDiscount,
    } = useCalculateTickets(orderTickets, orderTicketGroups);

    return (
      <Grid item container direction={'column'} rowGap={isFullScreen || isExistScheme ? 0.2 : 2.5}>
        {isLoading && <Skeleton width={'100%'} height={'195px'} sx={{ borderRadius: '1.5rem' }} />}
        {!isLoading && ticketGroups.length > 0 && scheme && (
          <Grid item container direction={'column'} width={'100%'}>
            <SectionWrapper borderRadius={isFullScreen || isExistScheme ? 'none' : '20px'} paddingX={0}>
              <StyledSwiper
                spaceBetween={10}
                slidesPerView={'auto'}
                slidesPerGroup={1}
                onSlideChange={(swiper) => {
                  handleSwiperChange(swiper.isBeginning, swiper.isEnd);
                }}
              >
                {ticketGroups &&
                  ticketGroups.map((group, index) => (
                    <SwiperSlide
                      onMouseEnter={() => handleHoveredTicketGroup(group.uId)}
                      onMouseLeave={() => handleHoveredTicketGroup('')}
                      key={group.ticketGroupId}
                      style={{
                        marginLeft: index === 0 ? '20px' : '0',
                      }}
                    >
                      <TicketGroupForSlide
                        startCounterValue={
                          orderTicketGroups.find((item) => item.ticketGroupId === group.ticketGroupId)?.count ||
                          getTicketCountInStoredOrderList(storedOrderList.ticketGroups, group.uId)
                        }
                        group={group}
                        handleTicketClick={handleTicketGroupClick}
                        orderTicketsCount={orderTicketsCount}
                      />
                    </SwiperSlide>
                  ))}
                {ticketGroups && ticketGroups?.length > 1 && (
                  <SwiperNavButtons isBeginning={isBeginning} isEnd={isEnd} />
                )}
              </StyledSwiper>
            </SectionWrapper>
          </Grid>
        )}
        <Grid
          container
          rowGap={isExistScheme ? 0.2 : 4}
          item
          direction={'row'}
          columnSpacing={isFullScreen || isExistScheme ? 0.2 : 2.5}
        >
          <Grid
            item
            container
            xs={12}
            lg={8}
            direction={'column'}
            rowGap={2.5}
            sx={{ borderRadius: isFullScreen || isExistScheme ? 'none' : '20px' }}
          >
            {isLoading &&
              Array.from(new Array(COUNT_TICKET_SKELETON)).map((_, index) => <TicketItemSkeleton key={index} />)}
            {!isLoading && scheme && (
              <PlaceSelection
                handleRelease={handleRelease}
                handleReserve={handleReserve}
                reservedTickets={reservedTickets}
                handleTicketClick={handleTicketClick}
                orderTickets={orderTickets}
                scheme={scheme}
                tickets={tickets}
                hoveredGroupTicket={hoveredGroupTicket}
                setReservedTickets={setReservedTickets}
                orderTicketsCount={orderTicketsCount}
                isExpiredTime={!countDownTimer && isOpenPurchaseModal && !orderId}
                isLargeScreen={isLargeScreen}
              />
            )}
            {!isLoading && !scheme && ticketGroups.length > 0 && (
              <SectionWrapper borderRadius={'20px'}>
                {ticketGroups.map((group) => (
                  <TicketGroup
                    startCounterValue={
                      orderTicketGroups.find((item) => item.ticketGroupId === group.ticketGroupId)?.count ||
                      getTicketCountInStoredOrderList(storedOrderList.ticketGroups, group.uId)
                    }
                    key={group.ticketGroupId}
                    group={group}
                    handleTicketClick={handleTicketGroupClick}
                    randomColor={groupColors[group.ticketGroupId]}
                    orderTicketsCount={orderTicketsCount}
                  />
                ))}
              </SectionWrapper>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <OrderDescription
              handleNextStep={handleNextClick}
              tickets={selectedTickets}
              amount={totalCostWithoutFee}
              isLoading={isLoadingOrder}
              deleteTicket={deleteTicketFromOrder}
              totalDiscount={totalDiscount}
            />
          </Grid>
        </Grid>
        <TicketLimitModal
          open={orderTicketsCount === maxPurchaseCount}
          limitTickets={maxPurchaseCount}
          resetTickets={resetTickets}
        />
      </Grid>
    );
  }
);

type TicketSelectionPropsType = {
  tickets: TicketItemType[];
  ticketGroups: TicketGroupType[];
  slug: string;
  scheme: string | undefined | null;
  isLoading: boolean;
  handleNextStep: () => void;
};
