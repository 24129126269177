import { FC, memo } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, object, ref, string } from 'yup';
import { Navigate, useNavigate } from 'react-router';
import { PagesRoutes } from '@store/routes';
import { useUpdateOrderMutation } from '@pages/Order/api/orderApi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/configureStore';
import {
  clearOrder,
  setConnectionId,
  setCountDownTimer,
  setIsOpenPurchaseModal,
  setPaymentStep,
} from '@pages/Order/features/orderSlice.ts';
import { setIsTimerActive } from '@components/CountdownTimer/feature/timerSlice.ts';
import { LoadingButton } from '@mui/lab';
import { useCalculateTickets } from '@pages/Order/helpers/useCalculateTickets.ts';
import { OrderDescription } from '../TicketSelection/components/OrderDescription/OrderDescription.tsx';
import { BuyerWrapper } from './components/BuyerWrapper';
import { ReactHookFormCustomTextField } from './components/ReactHookFormCustomTextField';
import { ReactHookFormCheckbox } from './components/ReactHookFormCheckbox';

interface IFormInput {
  email: string;
  repeatEmail: string;
  checked?: boolean;
}

const useStyles = makeStyles({ name: 'OrderDescription' })((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: 'min-content',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  item: {
    overflow: 'hidden',
  },
  short: {
    gridRow: 'span 1',
  },
  tell: {
    gridRow: 'span 2',
    [theme.breakpoints.up('lg')]: {
      gridRow: 'span 1',
    },
  },
  orderDescriptionWrapper: {
    borderRadius: '1.5rem 1.5rem 0 0',
    background: theme.palette.bg.dark,
  },
  paymentButton: {
    boxShadow: 'none',
    background: 'rgb(28 39 76 / 71%)',
    border: '2px solid transparent',
    ':hover': {
      boxShadow: 'none',
      boxSizing: 'border-box',
      background: 'rgb(28 39 76 / 71%)',
      border: '2px solid #E5427D',
    },
  },
  byuerInfoWrapper: {
    border: `1px dashed ${theme.palette.primary.contrastText}`,
    borderRadius: '30px',
    padding: '20px',
  },
}));

export const Buyer: FC<BuyerPropsType> = memo(({ handleNextStep, eventId }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { connectionId, order } = useSelector((state) => state.payment);

  const {
    orderId,
    items: { tickets, ticketGroups },
  } = order;

  const handleSignUpClick = () => navigate(PagesRoutes.Registration);

  const [updateOrderApi, { isLoading }] = useUpdateOrderMutation();

  const validationScheme = object({
    email: string()
      .required(`${t('SignUp.Form.Email')} ${t('SignUp.Form.Requared')}`)
      .email(`${t('SignUp.Form.Email')} ${t('SignUp.Form.Invalid')}`),
    repeatEmail: string()
      .required(`${t('SignUp.Form.Email')} ${t('SignUp.Form.Requared')}`)
      .trim()
      .oneOf([ref('email')], `${t('Payment.Buyer.EmailMustMatch')}`),
    checked: boolean(),
  });

  const methods = useForm({
    defaultValues: {
      email: '',
      repeatEmail: '',
      checked: false,
    },
    mode: 'all',
    resolver: yupResolver(validationScheme),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!connectionId || !orderId) return;

    updateOrderApi({ email: data.email, connectionId, orderId, eventId })
      .unwrap()
      .then(() => {
        handleNextStep();
      });
  };

  const { tickets: selectedTickets, totalCostWithoutFee, totalDiscount } = useCalculateTickets(tickets, ticketGroups);

  if (!orderId) {
    dispatch(setPaymentStep(0));
    dispatch(setCountDownTimer(0));
    dispatch(setIsTimerActive(false));
    dispatch(setConnectionId(null));
    dispatch(clearOrder());
    dispatch(setIsOpenPurchaseModal(false));
    return <Navigate to={PagesRoutes.Main} replace />;
  }

  return (
    <Grid container columnGap={'20px'} rowGap={'20px'} item direction={'row'} className={classes.container}>
      <Grid item className={cx([classes.item, classes.short])}>
        <BuyerWrapper
          title={`${t('Payment.Buyer.SignInOrSignUp')}`}
          subtitle={`${t('Payment.Buyer.SigninOrCreateNew')}`}
        >
          <Grid item container rowGap={2.5}>
            <Button component={Link} to={PagesRoutes.Login} fullWidth variant={'contained'}>
              <Typography variant={'body2'} fontWeight={600}>
                {t('Components.Header.Signin')}
              </Typography>
            </Button>
            <Button onClick={handleSignUpClick} fullWidth variant={'outlined'}>
              <Typography variant={'body2'} fontWeight={600}>
                {t('Components.Header.Signup')}
              </Typography>
            </Button>
          </Grid>
        </BuyerWrapper>
      </Grid>

      <Grid item className={cx([classes.item, classes.tell])}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <BuyerWrapper title={t('Payment.Buyer.BuyAsGuest')} subtitle={t('Payment.Buyer.TicketEmail')}>
              <Grid item container direction={'column'} rowGap={2.5}>
                <Grid item xs>
                  <ReactHookFormCustomTextField type={'email'} name={'email'} label={t('SignUp.Form.Email')} />
                </Grid>
                <Grid item xs>
                  <ReactHookFormCustomTextField
                    type={'email'}
                    name={'repeatEmail'}
                    label={t('Payment.Buyer.RepeatEmail')}
                  />
                </Grid>
                <ReactHookFormCheckbox name={'checked'} label={t('Payment.Buyer.Subscribe')} />
                <LoadingButton
                  type={'submit'}
                  fullWidth
                  variant={'contained'}
                  disabled={!methods.formState.isValid || isLoading}
                  loading={isLoading}
                >
                  <Typography variant={'body2'} fontWeight={600}>
                    {t('Payment.Buyer.Next')}
                  </Typography>
                </LoadingButton>

                <Button fullWidth variant={'contained'} component={Link} to={PagesRoutes.Registration}>
                  <Typography variant={'body2'} fontWeight={600}>
                    {t('SignUp.Form.CreateAccount')}
                  </Typography>
                </Button>
              </Grid>
            </BuyerWrapper>
          </form>
        </FormProvider>
      </Grid>

      <Grid item className={cx([classes.item, classes.short])}>
        <OrderDescription tickets={selectedTickets} amount={totalCostWithoutFee} totalDiscount={totalDiscount} />
      </Grid>
    </Grid>
  );
});

type BuyerPropsType = {
  handleNextStep: () => void;
  eventId: string;
};
