import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentProvider } from 'src/pages/Order/constants/enums.ts';
import { PaymentMethodType } from './types/types.ts';

const initialState: PaymentMethodType = {
  orderId: null,
  paymentMethod: PaymentProvider.None,
  status: null,
  date: null,
};

export const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    setPaymentMethod: (state, action: PayloadAction<PaymentMethodType>) => {
      return action.payload;
    },
    clearPaymentMethod: () => {
      return initialState;
    },
  },
});

export const { setPaymentMethod, clearPaymentMethod } = paymentMethodSlice.actions;
