import { Users } from '@core/constants/constants';
import { POST_METHOD } from '@core/constants/http-method-constants.ts';
import { apiSlice } from '../apiSlice';

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCookieCompliance: builder.query<boolean, string>({
      query: (userId) => ({ url: `${Users.Users}/${userId}/${Users.CookieCompliance}` }),
    }),
    cookieCompliance: builder.mutation<void, string>({
      query: (userId) => ({
        url: `${Users.Users}/${userId}/${Users.CookieCompliance}`,
        method: POST_METHOD,
      }),
    }),
  }),
});

export const { useCookieComplianceMutation, useLazyGetCookieComplianceQuery, reducerPath: usersReducerPath } = userApi;
