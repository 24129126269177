import { apiSlice } from '@core/api/apiSlice';
import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '@core/constants/http-method-constants';
import { Events } from '@core/constants/constants.ts';
import {
  CancelPaymentType,
  CommissionType,
  CreateOrderReqType,
  CreateOrderResType,
  CreatePaymentReqType,
  CreatePaymentResType,
  EventDetailsType,
  OrderResponseType,
  PaymentProvidersType,
  UpdateOrderReqType,
  UpdateOrderResType,
} from '../types/apiTypes';
import { Routes } from '../constants/routes';

const OrderApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    eventDetails: builder.query<EventDetailsType, string>({
      query: (eventSlug) => {
        return {
          url: `${Routes.Event}/${eventSlug}/${Routes.Details}`,
        };
      },
    }),
    eventOrder: builder.query<OrderResponseType, string>({
      query: (eventSlug) => {
        return {
          url: `${Routes.Event}/${eventSlug}/${Routes.Tickets}/${Routes.Available}`,
        };
      },
    }),
    createOrder: builder.mutation<CreateOrderResType, CreateOrderReqType>({
      query: (body) => {
        return {
          method: POST_METHOD,
          url: Routes.CreateOrder,
          body,
        };
      },
    }),
    updateOrder: builder.mutation<UpdateOrderResType, UpdateOrderReqType>({
      query: (body) => {
        return {
          method: PUT_METHOD,
          url: Routes.UpdateOrder,
          body,
        };
      },
    }),
    createPayment: builder.mutation<CreatePaymentResType, CreatePaymentReqType>({
      query: (body) => {
        return {
          method: POST_METHOD,
          url: Routes.PaymentCreate,
          body,
        };
      },
    }),
    cancelPayment: builder.mutation<void, CancelPaymentType>({
      query: (body) => {
        return {
          method: DELETE_METHOD,
          url: Routes.EventOrder,
          body,
        };
      },
    }),
    getPaymentProviders: builder.query<PaymentProvidersType, string>({
      query: (slug) => {
        return {
          url: `${Events.Events}/${slug}/${Routes.PaymentProviders}`,
        };
      },
    }),
    completeFreePayment: builder.mutation<void, string>({
      query: (orderId) => ({
        url: `${Routes.EventOrder}/${orderId}/${Routes.Complete}`,
        method: POST_METHOD,
      }),
    }),
    getCommissions: builder.query<CommissionType[], string>({
      query: (slug) => ({
        url: `${Events.Events}/${slug}/${Routes.Commissions}`,
      }),
    }),
  }),
});

export const {
  useEventOrderQuery,
  useEventDetailsQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useCreatePaymentMutation,
  useCancelPaymentMutation,
  useGetPaymentProvidersQuery,
  useCompleteFreePaymentMutation,
  reducerPath: orderReducerPath,
} = OrderApi;
