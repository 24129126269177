import { EventDescription } from '@pages/Order/components/EventDescription/EventDescription.tsx';
import { Grid } from '@mui/material';
import { TicketItem } from '@pages/OrderResult/components/TicketItem.tsx';
import { makeStyles } from 'tss-react/mui';
import { TicketType } from '@pages/OrderResult/types/apiTypes.ts';
import { useEffect, useState } from 'react';
import { Header } from '@pages/OrderResult/components/Header.tsx';
import { OrderStatus } from '@core/models/payment/enums/enums.ts';
import { Footer } from '@pages/OrderResult/components/Footer.tsx';
import { useParams } from 'react-router';
import { useGetOrderQuery } from '@pages/Profile/api/profileSlice.ts';
import { SkeletonOrderDetails } from '@pages/Profile/components/History/components/OrderDetails/components/SkeletonOrderDetails.tsx';
import { setLastEventName } from '@store/breadcrumbs/breadcrumbsSlice.tsx';
import { useDispatch, useSelector } from '../../../../../../configureStore.ts';

const useStyles = makeStyles({ name: 'OrderDetails' })((theme) => ({
  wrapper: {
    flexDirection: 'column',
    gap: '20px',
  },
  booking: {
    gap: '10px',
    alignItems: 'start',
  },
  ticketActive: {
    background: theme.palette.bgGradientSecond.mainGradient,
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    paddingLeft: '15px',
  },
}));

export const OrderDetails = () => {
  const { orderId = '' } = useParams<{ orderId: string }>();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const lastEventName = useSelector((store) => store.breadcrumbs.lastEventName);
  const [activeTicket, setActiveTicket] = useState<TicketType | null>(null);
  const { data: order, isLoading } = useGetOrderQuery(orderId);

  useEffect(() => {
    if (!order || (order && lastEventName === order.event.name)) return;
    dispatch(setLastEventName(order.event.name));
  }, [order]);

  const handleSelectClick = (ticket: TicketType) => {
    if (activeTicket?.id === ticket.id) {
      setActiveTicket(null);
      return;
    }
    setActiveTicket(ticket);
  };
  const isProcessed = order?.orderStatus === OrderStatus.Processed;

  return (
    <Grid item container flexDirection={'column'} rowGap={2.5} wrap={'nowrap'} overflow={'auto'}>
      {isLoading && <SkeletonOrderDetails />}
      {!isLoading && order && (
        <>
          <EventDescription
            title={order.event.name}
            posterUrl={order.event.posterUrl}
            venueAddress={order.event.location.venue.name}
            city={order.event.location.cityName}
            startDate={order.event.startDate}
          />
          <Grid container item className={classes.booking}>
            <Grid item container gap={'2px'} direction={'column'} xs md borderRadius={'20px'} overflow={'hidden'}>
              <Header count={order.tickets.length ?? 0} isProcessed={isProcessed} orderId={order.orderId} />
              <Grid item container direction={'column'} gap={'2px'}>
                {order.tickets.map((ticket) => (
                  <TicketItem
                    key={ticket.id}
                    ticket={ticket}
                    className={activeTicket?.id === ticket.id ? classes.ticketActive : ''}
                    isProcessed={isProcessed}
                  />
                ))}
              </Grid>
              {order.totalPrice > 0 && (
                <Footer
                  currency={order.event.currency.isoCode}
                  totalCoins={0}
                  totalPrice={order.totalPrice}
                  isProcessed={isProcessed}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
