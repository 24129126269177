import { FC } from 'react';

type PropsType = {
  size?: string;
};

export const FullscreenOffIcon: FC<PropsType> = ({ size = '20' }) => (
  <svg width={size} height={size} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={'M2 22L9 15M9 15H3.14286M9 15V20.8571'}
      stroke={'white'}
      strokeWidth={'1.5'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
    <path
      d={'M22 2L15 9M15 9H20.8571M15 9V3.14286'}
      stroke={'white'}
      strokeWidth={'1.5'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>
);
