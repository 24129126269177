import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography } from '@mui/material';
import { SkeletonForm } from '@pages/Order/components/StripePaymentForm/components/SkeletonForm.tsx';
import { SectionWrapper } from '../../common/SectionWrapper';

const useStyles = makeStyles({ name: 'BuyerWrapper' })((theme) => ({
  orderDescriptionWrapper: {
    borderRadius: '1.5rem 1.5rem 0 0',
    background: theme.palette.bg.dark,
  },
  title: {
    position: 'relative',
    '&: after': {
      position: 'absolute',
      bottom: '-5px',
      left: 0,
      content: "''",
      width: '70px',
      height: '2px',
      borderRadius: '10px',
      background: theme.palette.bgGradient.mainGradient,
    },
  },
  divider: {
    background: theme.palette.bgGradient.mainGradient,
    width: '100%',
    height: '2px',
    borderRadius: '10px',
  },
  triangleWrapper: {
    background: theme.palette.bgSecond.dark,
  },
}));

type BuyerWrapperPropsType = {
  title: string;
  children: React.ReactNode;
  subtitle?: string;
  isLoading?: boolean;
};

export const BuyerWrapper = ({ title, subtitle, children, isLoading }: BuyerWrapperPropsType) => {
  const { classes } = useStyles();

  return (
    <SectionWrapper item container direction={'column'} padding={0}>
      <Grid item container direction={'column'} rowGap={3} padding={2.5}>
        <Grid item container direction={'column'} rowGap={1} wrap={'nowrap'} xs>
          <Typography className={classes.title} variant={'body2'}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant={'caption'} fontSize={12} color={'primary.contrastText'}>
              {subtitle}
            </Typography>
          )}
        </Grid>
        {!isLoading && children}
        {isLoading && (
          <Grid container direction={'column'} rowGap={2} item xs>
            <SkeletonForm />
          </Grid>
        )}
      </Grid>
    </SectionWrapper>
  );
};
