export enum Auth {
  SignIn = '/Auth/signin',
  SignUp = 'Auth/signup',
  Token = '/Auth/token',
  RequestReset = '/Auth/RequestPasswordReset',
  ResetPassword = '/Auth/PasswordReset',
}

export enum Events {
  Events = `Events`,
  Groups = `Events/Groups`,
  Tours = 'Tours',
}

export enum Users {
  Users = '/Users',
  CookieCompliance = 'coockiecompliance',
}

export enum Price {
  Prices = 'prices',
  Currency = 'currency',
  Ranges = 'ranges',
}

export const MILLISECONDS_IN_SECOND = 1000;
