export enum PaymentMethod {
  BankCard = 'BankCard',
  StripeBankCard = 'StripeBankCard',
  Points = 'Points',
}

export enum KeyErrors {
  EventNotFound = 'event-not-found',
  QuotaNotFound = 'quota-not-found',
  NotEnoughTickets = 'not-enough-tickets',
  NoTickets = 'no-tickets',
  ServerError = 'server-error',
  EmailConfirmFailed = 'email-confirm-failed',
  ResetPasswordFailed = 'reset-password-failed',
  PaymentFailed = 'payment-failed',
  PaymentTimeout = 'payment-timeout',
  TicketNotFound = 'ticket-not-found',
  NotFoundPage = 'not-found-page',
}
