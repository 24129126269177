import { Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { theme } from '@core/theme/theme';
import { FullscreenOffIcon } from '@icons/FullscreenOffIcon';
import { FullscreenOnIcon } from '@icons/FullscreenOnIcon';
import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../../common/SectionWrapper';

const useStyles = makeStyles({ name: 'FullScreenButton' })((theme) => ({
  iconButton: {
    padding: 0,
    color: theme.palette.common.white,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

type FullScreenButtonPropsType = {
  handleFullScreenClick?: () => void;
  isFullScreen?: boolean;
};

export const FullScreenButton = ({ handleFullScreenClick, isFullScreen }: FullScreenButtonPropsType) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      xs
      className={isFullScreen ? classes.absolute : ''}
      item
      mb={0.2}
      container
      paddingRight={isFullScreen ? 2.5 : 0}
      justifyContent={'right'}
      alignSelf={'flex-start'}
      marginRight={'20px'}
    >
      <IconButton disableRipple onClick={handleFullScreenClick} className={classes.iconButton}>
        <SectionWrapper
          backgroundColor={isFullScreen ? theme.palette.bgSecond.dark : theme.palette.bgSecond.light}
          padding={'10px 20px'}
          borderRadius={'0 0 20px 20px'}
          columnGap={1}
          alignItems={'center'}
        >
          {isFullScreen ? <FullscreenOffIcon /> : <FullscreenOnIcon />}
          <Typography variant={'caption'} fontSize={12.4} fontWeight={500}>
            {isFullScreen
              ? t(`Payment.OrderDescription.CloseFullScreen`).toUpperCase()
              : t(`Payment.OrderDescription.OpenFullScreen`).toUpperCase()}
          </Typography>
        </SectionWrapper>
      </IconButton>
    </Grid>
  );
};
