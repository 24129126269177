import { TicketGroupItem, TicketItem } from '../types/apiTypes';
import { OrderTicketGroupType, OrderTicketType } from '../types/commonTypes';

export function createOrderForTicketsAndTicketsGroup(
  orderTickets: OrderTicketType[],
  orderTicketGroups: OrderTicketGroupType[]
) {
  const ticketGroups: TicketGroupItem[] = orderTicketGroups.map(({ count, price, sector, uId, ticketGroupId }) => ({
    count,
    price,
    sector,
    uId,
    ticketGroupId,
  }));
  const tickets: TicketItem[] = orderTickets.map(({ id, uId, price, row, seat, sector }) => ({
    id,
    uId,
    price,
    row,
    seat,
    sector,
  }));

  return { tickets, ticketGroups };
}
