import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from '../../../../../configureStore';

enum METHODS {
  TICKETS_RESERVED_ON = 'TicketsReserved',
  TICKETS_RELEASED_ON = 'TicketsReleased',
  RESERVE_TICKET_SEND = 'ReserveTicket',
  RELEASE_TICKET_SEND = 'ReleaseTicket',
}

export const useTicketHub = () => {
  const { accessToken } = useSelector((state) => state.auth);

  const [reservedTickets, setReservedTickets] = useState<string[]>([]);
  const [connectionId, setConnectionId] = useState<string | null>(null);

  const socket = useRef<HubConnection | null>(null);

  useEffect(() => {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => accessToken || '',
    };
    socket.current = new HubConnectionBuilder().withUrl(import.meta.env.VITE_API_SIGNALR_URL, options).build();

    if (socket.current) {
      socket.current.on(METHODS.TICKETS_RESERVED_ON, (res: { ticketIds: string[] }) => {
        const { ticketIds } = res;
        setReservedTickets((prev) => {
          return [...prev, ...ticketIds];
        });
      });

      socket.current.on(METHODS.TICKETS_RELEASED_ON, (res: { ticketIds: string[] }) => {
        const { ticketIds } = res;
        setReservedTickets((prev) => [...prev].filter((item) => !ticketIds.includes(item)));
      });

      socket.current
        .start()
        .then(() => {
          if (socket.current) {
            setConnectionId(socket.current.connectionId);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    }

    return () => {
      if (socket && socket.current) {
        setConnectionId(null);
        socket.current.stop();
      }
    };
  }, [accessToken]);

  const handleReserve = useCallback(
    (ticketId: string) => {
      if (!socket.current) return;
      socket.current.send(METHODS.RESERVE_TICKET_SEND, ticketId).catch((e) => {
        throw Error(e);
      });
    },
    [connectionId]
  );

  const handleRelease = useCallback(
    (ticketId: string) => {
      if (!socket.current) return;
      socket.current.send(METHODS.RELEASE_TICKET_SEND, ticketId).catch((e) => {
        throw Error(e);
      });
    },
    [connectionId]
  );

  return {
    connectionId,
    reservedTickets,
    handleReserve,
    handleRelease,
    setReservedTickets,
  };
};
