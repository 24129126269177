import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'BookingSkeleton' })((theme) => ({
  container: {
    borderRadius: '1.5rem',
    overflow: 'hidden',
    '& > div': {
      background: theme.palette.bg.dark,
    },
  },
  skeleton: {
    backgroundColor: theme.palette.bgSecond.light,
  },
}));

export const BookingSkeleton = () => {
  const { classes, theme } = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid className={classes.container} container flexDirection={'column'} rowGap={'2px'}>
      <Grid container item p={'15px 20px 15px 20px'} xs justifyContent={'space-between'}>
        <Skeleton className={classes.skeleton} variant={'rounded'} height={24} width={'20rem'} />
        <Skeleton
          className={classes.skeleton}
          sx={{ borderRadius: '40px' }}
          variant={'rounded'}
          height={44}
          width={'20rem'}
        />
      </Grid>
      <Grid container item p={'15px 20px'} xs justifyContent={'space-between'}>
        <Skeleton className={classes.skeleton} variant={'rounded'} height={24} width={'10rem'} />
        <Skeleton className={classes.skeleton} variant={'rounded'} height={24} width={matches ? '20rem' : '30rem'} />
      </Grid>
      {Array.from(new Array(3)).map((_, index) => (
        <Grid key={index} item container p={'15px 20px'} flexDirection={'column'} rowGap={'20px'} xs>
          <Skeleton className={classes.skeleton} variant={'rounded'} height={30} width={'10rem'} />
          <Skeleton className={classes.skeleton} variant={'rounded'} height={13} width={'20rem'} />
        </Grid>
      ))}
      <Grid container item p={'15px 20px'} xs justifyContent={'space-between'}>
        <Skeleton className={classes.skeleton} variant={'rounded'} height={24} width={'10rem'} />
        <Skeleton className={classes.skeleton} variant={'rounded'} height={24} width={'20rem'} />
      </Grid>
    </Grid>
  );
};
