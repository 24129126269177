import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrder } from '../types/commonTypes';

interface IOrderState {
  order: IOrder;
  countDownTimer: number;
  isOpenPurchaseModal: boolean;
  paymentStep: number;
  isShowStepper: boolean;
  connectionId: string | null;
}

const initialState: IOrderState = {
  order: {
    orderId: null,
    email: null,
    slug: null,
    eventId: null,
    isoCode: null,
    items: { ticketGroups: [], tickets: [] },
  },
  countDownTimer: 0,
  isOpenPurchaseModal: false,
  paymentStep: 0,
  isShowStepper: true,
  connectionId: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    createOrder(state, action: PayloadAction<IOrder>) {
      return { ...state, order: action.payload };
    },
    updateOrder(state, action: PayloadAction<string>) {
      return { ...state, order: { ...state.order, email: action.payload } };
    },
    clearOrder(state) {
      return { ...state, order: initialState.order };
    },
    setCountDownTimer(state, action: PayloadAction<number>) {
      return { ...state, countDownTimer: action.payload };
    },
    setIsOpenPurchaseModal(state, action: PayloadAction<boolean>) {
      return { ...state, isOpenPurchaseModal: action.payload };
    },
    setPaymentStep(state, action: PayloadAction<number>) {
      return { ...state, paymentStep: action.payload };
    },
    setConnectionId: (state, action: PayloadAction<string | null>) => {
      state.connectionId = action.payload;
    },
    isShowStepper: (state, action: PayloadAction<boolean>) => {
      state.isShowStepper = action.payload;
    },
  },
});

export const {
  createOrder,
  updateOrder,
  clearOrder,
  setCountDownTimer,
  setIsOpenPurchaseModal,
  setPaymentStep,
  setConnectionId,
  isShowStepper,
} = paymentSlice.actions;
