import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IBreadcrumbsState {
  lastEventName: string;
}

const initialState: IBreadcrumbsState = {
  lastEventName: '',
};

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setLastEventName(state, action: PayloadAction<string>) {
      return { ...state, lastEventName: action.payload };
    },
  },
});

export const { setLastEventName } = breadcrumbsSlice.actions;
