import * as React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'ReactHookFromCheckbox' })((theme) => ({
  checkbox: {
    color: '#E5427D',
    '&.Mui-checked': {
      color: '#E5427D',
    },
  },
  label: {
    color: theme.palette.primary.contrastText,
    fontSize: '12px',
    fontWeight: 300,
  },
}));

type ReactHookFormCheckboxPropsType = {
  name: string;
  label: string;
};

export const ReactHookFormCheckbox = ({ name, label }: ReactHookFormCheckboxPropsType) => {
  const { control } = useFormContext();
  const { classes } = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          control={
            <Checkbox
              className={classes.checkbox}
              checked={field.value}
              onChange={() => field.onChange(!field.value)}
            />
          }
          label={label}
        />
      )}
    />
  );
};
