import { Skeleton } from '@mui/material';
import { theme } from '@core/theme/theme.ts';

export const ChoicePaymentSkeleton = () => (
  <>
    {Array.from(new Array(2)).map((_item, index) => (
      <Skeleton
        key={index}
        sx={{
          borderRadius: '40px',
          minHeight: '44px',
          width: '100%',
          backgroundColor: theme.palette.bgSecond.light,
        }}
      />
    ))}
  </>
);
