import { Box, Grid, Typography } from '@mui/material';
import { PaymentTicket } from '@icons/PaymentTicket.tsx';
import { theme } from '@core/theme/theme.ts';
import { BitcoinIcon } from '@icons/BitcoinIcon.tsx';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { OrderLink } from './OrderLink.tsx';

const useStyles = makeStyles<{ isProcessed: boolean }>({ name: 'Footer' })((theme, { isProcessed }) => ({
  container: {
    backgroundColor: theme.palette.bg.dark,
    padding: '15px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      color: isProcessed ? theme.palette.primary.contrastText : 'inherit',
    },
  },
  line: {
    height: '100%',
    background: `linear-gradient(to bottom, transparent 40%, ${theme.palette.borders.main} 60%)`,
    width: '2px',
    backgroundSize: '1px 8px',
  },
}));

export const Footer: FC<FooterPropsType> = ({ currency, totalPrice, totalCoins, isProcessed }) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ isProcessed });

  const backgroundIcon = isProcessed
    ? theme.palette.bgIconGradient.disabled
    : theme.palette.bgIconGradient.mainGradient;

  return (
    <Grid item container className={classes.container} borderRadius={' 0 0 20px 20px'}>
      <Grid item xs>
        <OrderLink
          to={''}
          title={t('Booking.Receipt')}
          icon={<PaymentTicket gradient={backgroundIcon} />}
          disabled={isProcessed}
        />
      </Grid>
      <Grid item>
        <Grid container gap={'20px'}>
          <Grid item display={'flex'} gap={'20px'}>
            <Typography variant={'body2'} fontWeight={'600'}>
              {t('Booking.Total')}
            </Typography>
            <Typography variant={'body2'} fontWeight={'700'}>
              {`${totalPrice} ${t(`General.Currency.${currency}`)}`}
            </Typography>
          </Grid>
          {/* <Grid item> */}
          {/*  <Box className={classes.line} /> */}
          {/* </Grid> */}
          {/* <Grid item container gap={'10px'} xs> */}
          {/*  <Grid item container xs justifyContent={'space-between'} gap={'10px'}> */}
          {/*    <BitcoinIcon /> */}
          {/*    <Typography variant={'body1'}>{`+${t('Booking.CoinsCount', { */}
          {/*      coins: totalCoins, */}
          {/*    })}`}</Typography> */}
          {/*  </Grid> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

type FooterPropsType = {
  totalPrice: number;
  totalCoins: number;
  currency: string;
  isProcessed: boolean;
};
