import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeyErrors } from '@store/enums';
import { AlertNotificationType, IAppStoreState } from './types';

const initialState: IAppStoreState = {
  isAuthorized: false,
  isLoading: false,
  routeForReload: null,
  keyError: null,
  alert: null,
};

const appReducer = createSlice({
  name: 'appReducer',
  initialState,
  reducers: {
    AppAuthorize: (state) => {
      return { ...state, isAuthorized: true };
    },
    AppDeAuthorize: (state) => {
      return { ...state, isAuthorized: false };
    },
    setAlertData: (state, action: PayloadAction<AlertNotificationType | null>) => {
      state.alert = action.payload;
    },
    setRouteForReload: (state, action: PayloadAction<string | null>) => {
      return { ...state, routeForReload: action.payload };
    },
    setKeyError: (state, action: PayloadAction<KeyErrors | null>) => {
      return { ...state, keyError: action.payload };
    },
  },
});

export default appReducer;
