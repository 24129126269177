import { apiSlice } from '@core/api/apiSlice.ts';
import { GetOrdersParamsType, OrderType, ProfileType, SpecificOrderType } from '@pages/Profile/types/types.ts';
import { Profile } from '@pages/Profile/api/constants.ts';

export const profileApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<ProfileType, void>({
      query: () => ({ url: `${Profile.Profile}` }),
    }),
    getOrders: builder.query<OrderType[], GetOrdersParamsType>({
      query: (params) => {
        return {
          url: `${Profile.Profile}/${Profile.Orders}`,
          params,
        };
      },
    }),
    getOrder: builder.query<SpecificOrderType, string>({
      query: (orderId) => ({
        url: `${Profile.Profile}/${Profile.Orders}/${orderId}`,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetProfileQuery, useGetOrdersQuery, useGetOrderQuery, reducerPath: profileReducerPath } = profileApi;
